const BANNER_TYPE = {
  EMPTY_TALENT: 'empty_talent',
  EMPTY_OTHERS: 'empty_others',
  TALENT: 'talent',
  AGENCY: 'agency',
  PRODUCTION_HOUSE: 'production_house',
  FREELANCER: 'freelancer',
  CASTING: 'casting'
};

export { BANNER_TYPE };
