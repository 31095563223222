import { apiService } from '@/core/services';
import {
  ADVERTISEMENT_POSITION,
  ADVERTISEMENT_ACTION
} from '@/core/constants/enums';

export const ADVERTISEMENT = {
  getAdvertisements,
  createAdvertisement,
  getAdvertisement,
  updateAdvertisement,
  deleteAdvertisement
};

const URL = {
  ADMIN: 'admin',
  ADVERTISEMENT: 'advertisement'
};

function getAdvertisements(data) {
  let requestOptions = {
    params: {
      action: data.action,
      title: data.title,
      position: data.position,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.ADVERTISEMENT}`, requestOptions);
}

function createAdvertisement(data) {
  var formData = new FormData();

  formData.append('action', data.action);
  formData.append('title', data.title);
  formData.append('start_date', data.startDate);
  formData.append('end_date', data.endDate);
  formData.append('position', data.position);
  formData.append('thumbnail', data.thumbnail);

  if (data.action == ADVERTISEMENT_ACTION.WEBSITE) {
    formData.append('url', data.url);
  } else {
    formData.append('actionable_id', data.actionableId);
  }

  if (data.position == ADVERTISEMENT_POSITION.NEWSFEED) {
    formData.append('gap', data.gap);
  }

  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.ADVERTISEMENT}`,
    formData
  );
}

function getAdvertisement(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.ADVERTISEMENT}/${id}`);
}

function updateAdvertisement(data, id) {
  var formData = new FormData();

  formData.append('action', data.action);
  formData.append('title', data.title);
  formData.append('start_date', data.startDate);
  formData.append('end_date', data.endDate);
  formData.append('position', data.position);

  if (data.thumbnail) {
    formData.append('thumbnail', data.thumbnail);
  }

  if (data.action == ADVERTISEMENT_ACTION.WEBSITE) {
    formData.append('url', data.url);
  } else {
    formData.append('actionable_id', data.actionableId);
  }

  if (data.position == ADVERTISEMENT_POSITION.NEWSFEED) {
    formData.append('gap', data.gap);
  }

  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.ADVERTISEMENT}/${id}`,
    formData
  );
}

function deleteAdvertisement(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.ADVERTISEMENT}/${id}`);
}
