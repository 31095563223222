const GLOBAL = Object.freeze({
  LAYOUT: 'Layout',
  LAYOUT_BLANK: 'LayoutBlank',
  DEFAULT_SEARCH_DATE_START: new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    2
  )
    .toISOString()
    .substr(0, 10),
  DEFAULT_SEARCH_DATE_END: new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1
  )
    .toISOString()
    .substr(0, 10),
  DEFAULT_TABLE_PAGINATION: {
    currentPage: 1,
    total: 1,
    perPage: 10,
    lastPage: 1
  },
  DEFAULT_SEARCH_CRITERIA: {
    perPage: 10,
    page: 1
  },
  SUPER_ADMIN: 'superadmin',
  AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE: ['application/pdf'],
  ADVERTISEMENT_THUMBNAIL_UPLOAD_SIZE_BYTES: 2000000,
  FIRST_DATE_OF_CURRENT_YEAR: new Date(new Date().getFullYear(), 0, 1)
});

const LOCAL = Object.freeze({
  CONSTANTS: 'constants'
});

const CURRENCY = Object.freeze({
  MYR: 'myr'
});

const SMART_FORM_FIELD_TYPE = Object.freeze({
  TEXT: 'text',
  SELECT: 'select',
  MULTI_SELECT: 'multiSelect',
  DATE_RANGE: 'dateRange'
});

const APP_COMPONENT_NAME = Object.freeze({
  APP_CHECK_CIRCLE: 'AppCheckCircle',
  APP_ACTIVATION_STATUS_LABEL: 'AppActivationStatusLabel',
  APP_TABLE_ACTIONS: 'AppTableActions',
  APP_MEMBER_TYPE_LABEL: 'AppMemberTypeLabel',
  APP_BUSINESS_HOUR_LIST: 'AppBusinessHourList',
  APP_VISIBILITY_STATUS_BUTTON: 'AppVisibilityStatusButton',
  APP_TAGS: 'AppTags',
  APP_TEXT_TRUNCATE_CONTAINER: 'AppTextTruncateContainer',
  APP_FEATURE_DETAILS_RELATED_BUTTON: 'AppFeatureDetailsRelatedButton',
  APP_SWITCH: 'AppSwitch',
  APP_AVERAGE_RATING: 'AppAverageRating',
  APP_STATUS_LABEL: 'AppStatusLabel',
  APP_ENUM_LABEL: 'AppEnumLabel'
});

const ERROR_CODE = Object.freeze({
  FORBIDDEN: 403,
  UNAUTHORIZED: 401
});

const API = Object.freeze({
  BASE_API_URL: process.env.VUE_APP_BASE_API_URL,
  DATETIME_FROM_START_TIME: 'T00:00:00.000Z',
  DATETIME_TO_END_TIME: 'T23:59:59.999Z'
});

export {
  GLOBAL,
  CURRENCY,
  LOCAL,
  SMART_FORM_FIELD_TYPE,
  APP_COMPONENT_NAME,
  ERROR_CODE,
  API
};
