import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const options = {
  transition: 'Vue-Toastification__fade',
  maxToasts: 10,
  newestOnTop: true,
  transitionDuration: 500,
  timeout: 3000
};

Vue.use(Toast, options);

export default Toast;

/* Documentation */
/* https://github.com/Maronato/vue-toastification */

/* Toast Default Types */
/* 
this.$toast("Default toast");
this.$toast.info("Info toast");
this.$toast.success("Success toast");
this.$toast.error("Error toast");
this.$toast.warning("Warning toast"); 
*/
