import { apiService } from '@/core/services';

export const CHAT = {
  getChats,
  getChat,
  getChatDetails,
  getChatList,
  sendTextMessage,
  sendAttachment,
  updateChatFavourite,
  initiateChat
};

const URL = {
  ADMIN: 'admin',
  CHAT: 'chat',
  CHAT_LIVE: 'chat-live'
};

const ACTION = {
  INITIATE: 'initiate',
  FAVOURITE: 'favourite',
  SEND_ATTACHMENT: 'attachment'
};

function getChats(data) {
  let requestOptions = {
    params: {
      name: data.name,
      active: data.active,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.CHAT}`, requestOptions);
}

function getChat(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CHAT}/${id}`);
}

function getChatDetails(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CHAT_LIVE}/${id}`);
}

function getChatList(data) {
  let requestOptions = {
    params: {
      keyword: data.keyword,
      roles: data.roles,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.CHAT_LIVE}`, requestOptions);
}

function sendTextMessage(data) {
  let requestBody = {
    message: data.message
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.CHAT_LIVE}/${data.id}`,
    requestBody
  );
}

function sendAttachment(data) {
  let formData = new FormData();
  formData.append('media_file', data.mediaFile);
  formData.append('media_type', data.mediaType);
  return apiService().post(
    `/${URL.ADMIN}/${URL.CHAT_LIVE}/${data.id}/${ACTION.SEND_ATTACHMENT}`,
    formData
  );
}

function initiateChat(data) {
  let formData = new FormData();
  if (data.mediaFile) {
    formData.append('media_file', data.mediaFile);
  }
  if (data.message) {
    formData.append('message', data.message);
  }
  return apiService().post(
    `/${URL.ADMIN}/${URL.CHAT_LIVE}/${data.userId}/${ACTION.INITIATE}`,
    formData
  );
}

function updateChatFavourite(data) {
  let requestBody = {
    action: data.action
  };
  return apiService().post(
    `/${URL.ADMIN}/${URL.CHAT_LIVE}/${data.id}/${ACTION.FAVOURITE}`,
    requestBody
  );
}
