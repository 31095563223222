import { USER_PACKAGE } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import _ from 'lodash';

/* Define Module Name */
const MODULE_NAME = 'userPackage/';

/* Define Action Name */
const A_GET_TALENT_PACKAGE_LIST = 'getTalentPackageList';
const A_GET_PRODUCTION_PACKAGE_LIST = 'getProductionPackageList';
const A_GET_PRODUCTION_PACKAGE_MEMBERS_LIST = 'getProductionPackageMemberList';
const A_GET_USER_PACKAGE = 'getUserPackage';
const A_RESET_USER_PACKAGE = 'resetUserPackage';
const A_UPDATE_USER_PACKAGE = 'updateUserPackage';

/* Define Reset State Action Name */
const A_INITIAL_TALENT_PACKAGE_LIST_STATE = 'initialTalentPackageListState';
const A_INITIAL_PRODUCTION_PACKAGE_LIST_STATE =
  'initialProductionPackageListState';
const A_INITIAL_PRODUCTION_PACKAGE_MEMBER_LIST_STATE =
  'initialProductionPackageMemberListState';
const A_INITIAL_USER_PACKAGE_STATE = 'initialUserPackageState';
const A_INITIAL_RESET_USER_PACKAGE_STATE = 'initialUserPackageState';
const A_INITIAL_UPDATE_USER_PACKAGE_STATE = 'initialUpdateUserPackageState';

/* Define Getter Name */

/* Define Mutation Name */
const M_TALENT_PACKAGE_LIST = 'setTalentPackageList';
const M_PRODUCTION_PACKAGE_LIST = 'setProductPackageList';
const M_PRODUCTION_PACKAGE_MEMBERS_LIST = 'setProductPackageMembersList';
const M_USER_PACKAGE = 'setUserPackage';
const M_RESET_USER_PACKAGE = 'setResetUserPackage';
const M_UPDATE_USER_PACKAGE = 'setUpdateUserPackage';
// const M_UPDATE_PRODUCTION_PACKAGE = 'setUpdateProductionPackage';

// /* Define Reset State Mutation Name */
// const M_INITIAL_PACKAGE_LIST_STATE = 'setInitialAppInfoListState';
// const M_INITIAL_PACKAGE_STATE = 'setInitialAppInfoState';
const M_INITIAL_UPDATE_USER_PACKAGE_STATE = 'setInitialUpdateUserPackageState';

/* Define Export Name */
export const PACKAGE_GET_TALENT_PACKAGE_LIST =
  MODULE_NAME + A_GET_TALENT_PACKAGE_LIST;

export const PACKAGE_GET_PRODUCTION_PACKAGE_LIST =
  MODULE_NAME + A_GET_PRODUCTION_PACKAGE_LIST;

export const PACKAGE_GET_PRODUCTION_PACKAGE_MEMBERS_LIST =
  MODULE_NAME + A_GET_PRODUCTION_PACKAGE_MEMBERS_LIST;

export const PACKAGE_RESET_USER_PACKAGE_LIST =
  MODULE_NAME + A_RESET_USER_PACKAGE;

export const PACKAGE_GET_USER_PACKAGE = MODULE_NAME + A_GET_USER_PACKAGE;

export const PACKAGE_UPDATE_USER_PACKAGE = MODULE_NAME + A_UPDATE_USER_PACKAGE;

/* Define Reset State Name */
export const PACKAGE_INITIAL_TALENT_PACKAGE_LIST_STATE =
  MODULE_NAME + A_INITIAL_TALENT_PACKAGE_LIST_STATE;

export const PACKAGE_INITIAL_PRODUCTION_PACKAGE_LIST_STATE =
  MODULE_NAME + A_INITIAL_PRODUCTION_PACKAGE_LIST_STATE;

export const PACKAGE_INITIAL_PRODUCTION_PACKAGE_MEMBERS_LIST_STATE =
  MODULE_NAME + A_INITIAL_PRODUCTION_PACKAGE_MEMBER_LIST_STATE;

export const PACKAGE_INITIAL_USER_PACKAGE_STATE =
  MODULE_NAME + A_INITIAL_USER_PACKAGE_STATE;

export const PACKAGE_INITIAL_RESET_USER_PACKAGE_STATE =
  MODULE_NAME + A_INITIAL_RESET_USER_PACKAGE_STATE;

export const PACKAGE_INITIAL_UPDATE_USER_PACKAGE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_USER_PACKAGE_STATE;

const state = {
  talentPackageList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  productionPackageList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  productionPackageMembersList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  appInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAppInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_TALENT_PACKAGE_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    USER_PACKAGE.getTalentPackageList(data)
      .then((response) => {
        result = response;
        commit(M_TALENT_PACKAGE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_TALENT_PACKAGE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PRODUCTION_PACKAGE_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    USER_PACKAGE.getProductionPackageList(data)
      .then((response) => {
        result = response;
        commit(M_PRODUCTION_PACKAGE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PRODUCTION_PACKAGE_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PRODUCTION_PACKAGE_MEMBERS_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    USER_PACKAGE.getProductionPackageMembersList(data)
      .then((response) => {
        result = response;
        commit(M_PRODUCTION_PACKAGE_MEMBERS_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PRODUCTION_PACKAGE_MEMBERS_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER_PACKAGE]({ dispatch, commit }, { id, modalType }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    USER_PACKAGE.getUserPackageInfo(id, modalType)
      .then((response) => {
        result = response;
        commit(M_USER_PACKAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_USER_PACKAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_USER_PACKAGE]({ dispatch, commit }, { id, data, modalType }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    console.log(data);
    USER_PACKAGE.updateUserPackage(id, data, modalType)
      .then((response) => {
        result = response;
        commit(M_UPDATE_USER_PACKAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_USER_PACKAGE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_RESET_USER_PACKAGE]({ commit }) {
    commit(M_RESET_USER_PACKAGE);
  },
  [PACKAGE_INITIAL_PRODUCTION_PACKAGE_MEMBERS_LIST_STATE]({ commit }) {
    commit(M_PRODUCTION_PACKAGE_MEMBERS_LIST);
  }
  // [A_INITIAL_UPDATE_APP_INFO_STATE]({ commit }) {
  //   commit(M_INITIAL_UPDATE_APP_INFO_STATE);
  // }
};

// Mutations
const mutations = {
  [M_TALENT_PACKAGE_LIST](state, result) {
    if (result.code === 200) {
      let d = result.data.items;
      state.talentPackageList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            ...x
          };
        }),
        message: null,
      };
    } else {
      state.talentPackageList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_PRODUCTION_PACKAGE_LIST](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.productionPackageList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            ...x
          };
        }),
        message: null,
      };
    } else {
      state.productionPackageList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_PRODUCTION_PACKAGE_MEMBERS_LIST](state, result) {
    if (result.code === 200) {
      let d = result.data.items;
      console.log(d);

      state.productionPackageMembersList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          const mainAccountInfo = x?.main_account || {};
          console.log(mainAccountInfo.created_at);
          return {
            no: index + 1,
            fname: mainAccountInfo.fname,
            status: x.status,
            email: mainAccountInfo.email || '',
            description: mainAccountInfo.description || '',
            lastLogin: (mainAccountInfo.last_login || '').toString(),
            createdAt: (mainAccountInfo.created_at || '').toString(),
            role: mainAccountInfo.role,
            isActivated: mainAccountInfo.isActivated,
            contactNumber: mainAccountInfo.mobile
          };
        }),
        message: null,
      };
    } else {
      state.productionPackageMembersList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_UPDATE_USER_PACKAGE](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.updateAppInfo = {
        code: result.code,
        complete: true,
        data: {
          ...d
        },
        message: apiHelper.sortOutMessage(result)
      };
    } else {
      state.updateAppInfo = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_USER_PACKAGE](state, result) {
    if (result.code === 200) {
      let d = result.data;
      const packageDetails = d.package_details.map((i, idx) => {
        const valueArray = Object.entries(i).map(([key, value]) => {
          return { [`${key}_${idx}`]: value };
        });
        return [Object.assign({}, ...valueArray), valueArray];
      });
      state.appInfo = {
        code: result.code,
        complete: true,
        data: {
          ...d,
          price_per_annum: 0,
          no_of_users: 1,
          ...d.package_details[0],
          package_details: packageDetails.map((i) => i[0]),
          ...Object.assign({}, ..._.flatten(packageDetails.map((i) => i[1]))),
          package_details_key: _.flatten(
            packageDetails.map((i) => Object.keys(i[0]))
          )
        },
        message: null
      };
    } else {
      state.appInfo = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_RESET_USER_PACKAGE](state) {
    state.updateAppInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
    state.appInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  // [M_INITIAL_APP_INFO_LIST_STATE](state) {
  //   state.appInfoList = {
  //     code: 0,
  //     complete: false,
  //     data: null,
  //     message: null,
  //   };
  // },
  [M_INITIAL_UPDATE_USER_PACKAGE_STATE](state) {
    state.updateAppInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
  // [M_INITIAL_UPDATE_APP_INFO_STATE](state) {
  //   state.updateAppInfo = {
  //     code: 0,
  //     complete: false,
  //     data: null,
  //     message: null
  //   };
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
