import i18n from '@/core/plugins/i18n.js';

export const i18nHelper = {
  getMessage,
  getArrayMessage
};

function getMessage(key, value) {
  return i18n.t(key, value);
}

function getArrayMessage(key, array) {
  let messages = [];

  array.forEach((x) => {
    messages.push(i18n.t(`${key}.${array[x]}`));
  });

  return messages.join(', ');
}
