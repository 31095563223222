import { FLAG } from '@/core/api';
import { apiHelper, i18nHelper, uiHelper } from '@/core/utils';
import { FLAG_CONTENT, FLAG_TYPE } from '@/core/constants/enums';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import constantsDesc from '@/core/filters/constants-desc.filter';

/* Define Module Name */
const MODULE_NAME = 'flag/';

/* Define Action Name */
const A_GET_FLAGS = 'getFlags';
const A_GET_FLAG = 'getFlag';
const A_UPDATE_FLAG = 'updateFlag';
const A_EXPORT_FLAG_TO_EXCEL = 'exportFlagToExcel';

/* Define Reset State Action Name */
const A_INITIAL_GET_FLAGS_STATE = 'initialGetFlagsState';
const A_INITIAL_GET_FLAG_STATE = 'initialGetFlagState';
const A_INITIAL_UPDATE_FLAG_STATE = 'initialUpdateFlagState';
const A_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE =
  'initialExportFlagToExcelState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_FLAGS = 'setGetFlags';
const M_GET_FLAG = 'setGetFlag';
const M_UPDATE_FLAG = 'setUpdateFlag';
const M_EXPORT_FLAG_TO_EXCEL = 'setExportFlagToExcel';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_FLAGS_STATE = 'setInitialGetFlagsState';
const M_INITIAL_GET_FLAG_STATE = 'setInitialGetFlagState';
const M_INITIAL_UPDATE_FLAG_STATE = 'setInitialUpdateFlagState';
const M_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE = 'setInitialExportFlagToExcelState';

/* Define Export Name */
export const FLAG_GET_FLAGS = MODULE_NAME + A_GET_FLAGS;
export const FLAG_GET_FLAG = MODULE_NAME + A_GET_FLAG;
export const FLAG_UPDATE_FLAG = MODULE_NAME + A_UPDATE_FLAG;
export const FLAG_EXPORT_FLAG_TO_EXCEL = MODULE_NAME + A_EXPORT_FLAG_TO_EXCEL;

/* Define Reset State Name */
export const FLAG_INITIAL_GET_FLAGS_STATE =
  MODULE_NAME + A_INITIAL_GET_FLAGS_STATE;
export const FLAG_INITIAL_GET_FLAG_STATE =
  MODULE_NAME + A_INITIAL_GET_FLAG_STATE;
export const FLAG_INITIAL_UPDATE_FLAG_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_FLAG_STATE;
export const FLAG_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE =
  MODULE_NAME + A_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE;

const state = {
  flags: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  flag: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateFlag: {
    code: 0,
    complete: false,
    message: null
  },
  exportFlagToExcel: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_FLAGS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    FLAG.getFlags(data)
      .then((response) => {
        let result = response;
        commit(M_GET_FLAGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_FLAGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_FLAG]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    FLAG.getFlag(id)
      .then((response) => {
        let result = response;
        commit(M_GET_FLAG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_FLAG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_FLAG]({ dispatch, commit }, { data, id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    FLAG.updateFlag(data, id)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_FLAG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_FLAG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_EXPORT_FLAG_TO_EXCEL]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    FLAG.exportFlagToExcel(data)
      .then((response) => {
        let result = response;
        commit(M_EXPORT_FLAG_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_EXPORT_FLAG_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_FLAGS_STATE]({ commit }) {
    commit(M_INITIAL_GET_FLAGS_STATE);
  },
  [A_INITIAL_GET_FLAG_STATE]({ commit }) {
    commit(M_INITIAL_GET_FLAG_STATE);
  },
  [A_INITIAL_UPDATE_FLAG_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_FLAG_STATE);
  },
  [A_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE]({ commit }) {
    commit(M_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE);
  },
};

// Mutations
const mutations = {
  [M_GET_FLAGS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.flags = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          let extra = {};
          const content = x?.content
          if(content?.type == 'newsfeed') extra = { postOwner: content?.owner_name };
          else if (content?.type == 'review') extra = { 
              reviewer: content?.reviewer,
              reviewee: content?.reviewee,
              castingJobOwner: content?.casting_job_owner 
            }
          return {
            no: index + 1,
            id: x.id,
            type: getFlagType(x),
            typeOther: x.type_other,
            content: getFlagContent(content),
            comment: x.comment,
            status: x.status,
            proofs: x.proofs
              ? x.proofs.map((y) => {
                  return {
                    id: y.id,
                    media_path: y.media_path
                  };
                })
              : null,
            reportedBy: x.reported_by,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            updatedAt: dateTimeFormat(x.updated_at),
            updatedAtTimeStamp: new Date(x.updated_at).getTime(),
            ...extra
          };
        }),
      };
    } else {
      state.flags = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_FLAG](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.flag = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          type: getFlagType(d),
          typeOther: d.type_other,
          content: getFlagContent(d.content),
          comment: d.comment,
          status: d.status,
          proofs: d.proofs
            ? d.proofs.map((y) => {
                return {
                  id: y.id,
                  mediaPath: y.media_path
                };
              })
            : null,
          reportedBy: d.reported_by,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        }
      };
    } else {
      state.flag = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_FLAG](state, result) {
    state.updateFlag = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_EXPORT_FLAG_TO_EXCEL](state, result) {
    state.exportFlagToExcel = {
      // code: result.code,
      complete: true,
      data: result,
      // message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_FLAGS_STATE](state) {
    state.flags = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_FLAG_STATE](state) {
    state.flag = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_FLAG_STATE](state) {
    state.updateFlag = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_EXPORT_FLAG_TO_EXCEL_STATE](state) {
    state.exportFlagToExcel = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

function getFlagType(data) {
  var type = data.type;

  if (data.type == FLAG_TYPE.OTHER) {
    type = data.type_other;
  } else {
    type = constantsDesc(data.type, 'flag');
  }

  return type;
}

function getFlagContent(data) {
  var d = data;
  var type = data.type;
  var content = null;

  if (data) {
    if (type == FLAG_CONTENT.USER) {
      content = {
        type: d.type,
        id: d.id,
        role: i18nHelper.getMessage(`enumFlagMemberType.${d.role}`),
        name: d.name,
        firstName: d.first_name,
        lastName: d.last_name
      };
    } else if (type == FLAG_CONTENT.CASTING) {
      content = {
        type: d.type,
        id: d.id,
        title: d.title,
        deletedAt: dateTimeFormat(d.deleted_at)
      };
    } else if (type == FLAG_CONTENT.NEWSFEED) {
      content = {
        type: d.type,
        id: d.id,
        content: d.content,
        postOwner: d.owner_name,
        media: d.media.map((x) => {
          return {
            id: x.id,
            mediaPath: x.media_path,
            mimeType: x.mime_type,
            mediaType: uiHelper.getFileExtension(x.media_path)
          };
        }),
        deletedAt: dateTimeFormat(d.deleted_at)
      };
    } else if (type == FLAG_CONTENT.REVIEW) {
      content = {
        type: d.type,
        id: d.id,
        rating: d.rating,
        comment: d.comment,
        reviewer: d.reviewer,
        reviewee: d.reviewee,
        castingJobOwner: d.casting_job_owner,
        deletedAt: dateTimeFormat(d.deleted_at)
      };
    }
  }

  return content;
}
