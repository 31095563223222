export const I18N_CONSTANTS = Object.freeze({
  DEFAULT_LANGUAGE: process.env.VUE_APP_I18N_LOCALE,
  LOCALE_STORAGE_KEY_NAME: 'language',
  AVAILABLE_LANGUAGES: [
    {
      lang: 'en',
      flag: process.env.BASE_URL + 'media/svg/flags/226-united-states.svg'
    }
  ]
});
