const CRUDType = {
  CREATE: 'create',
  READ: 'read',
  UPDATE: 'update',
  DELETE: 'delete'
};

const CRUDOrder = {
  CREATE: 1,
  READ: 2,
  UPDATE: 3,
  DELETE: 4
};

export { CRUDType, CRUDOrder };
