const ASIDE_MENU_CATEGORY = {
  MAIN: 'main',
  ADMIN_USER: 'adminUser',
  MEMBER: 'member',
  CONTENT_MANAGEMENT: 'contentManagement',
  FLAG: 'flag',
  CASTING: 'casting',
  SETTINGS: 'settings',
  COMMUNITY: 'community',
  FINANCE: 'finance'
};

export { ASIDE_MENU_CATEGORY };
