import { apiService } from '@/core/services';

export const LOOKUP = {
  getUserPermissions,
  getNotification,
  getProjectTypes
};

const URL = {
  ADMIN: 'admin',
  LOOKUP: 'lookup',
  PERMISSIONS: 'permissions',
  NOTIFICATION: 'notification',
  PROJECT_TYPES: 'project-types'
};

function getUserPermissions() {
  return apiService().get(`/${URL.ADMIN}/${URL.LOOKUP}/${URL.PERMISSIONS}`);
}

function getNotification() {
  return apiService().get(`/${URL.ADMIN}/${URL.LOOKUP}/${URL.NOTIFICATION}`);
}

function getProjectTypes() {
  return apiService().get(`/${URL.ADMIN}/${URL.LOOKUP}/${URL.PROJECT_TYPES}`);
}
