<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="Logo" :src="logo" width="200" />
    <div class="spinner" :class="spinnerClass || 'spinner-primary'"></div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
  export default {
    name: 'AppLoader',
    props: {
      logo: String,
      spinnerClass: String
    }
  };
</script>
