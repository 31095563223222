import { CONSTANTS } from '@/core/api';
import { LOCAL } from '@/core/constants';
import { permissionHelper, uiHelper, apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'constants/';

/* Define Action Name */
const A_CONSTANTS = 'getConstants';

/* Define Reset State Action Name */
const A_INITIAL_CONSTANTS_STATE = 'initialConstantsState';

/* Define Getter Name */
const G_AVAILABLE_PERMISSIONS = 'availablePermissions';
const G_CONSTANTS = 'constants';

/* Define Mutation Name */
const M_CONSTANTS = 'setConstants';

/* Define Reset State Mutation Name */
const M_INITIAL_CONSTANTS_STATE = 'setInitialConstantState';

/* Define Export Name */
export const CONSTANTS_GET_CONSTANTS = MODULE_NAME + A_CONSTANTS;
export const CONSTANTS_AVAILABLE_PERMISSIONS =
  MODULE_NAME + G_AVAILABLE_PERMISSIONS;
export const CONSTANTS_CONSTANTS = MODULE_NAME + G_CONSTANTS;

/* Define Reset State Name */
export const CONSTANTS_INITIAL_CONSTANTS_STATE =
  MODULE_NAME + A_INITIAL_CONSTANTS_STATE;

const state = {
  constants: {
    code: 0,
    complete: false,
    data: {
      adminRole: [],
      permission: [],
      userRole: [],
      country: [],
      pronouns: [],
      productionHouseDescription: [],
      hairColor: [],
      hairLength: [],
      hairType: [],
      eyeColor: [],
      skinColor: [],
      race: [],
      languageSpoken: [],
      projectTypes: [],
      projectRoles: [],
      verificationStatus: [],
      allCountry: [],
      flag: [],
      flagContent: [],
      rate: [],
      castingJobStatus: [],
      loadingPlatform: [],
      loadingPeriod: [],
      height: [],
      age: [],
      projectTypeDataSet: [],
      chatMessageType: [],
      mediaType: [],
      advertisementAction: [],
      advertisementPosition: [],
      auditLogTableName: [],
      auditLogColumnName: [],
      paymentType: [],
      reviewStatus: []
    },
    message: null
  }
};

//Getters
const getters = {
  [G_AVAILABLE_PERMISSIONS]: (state) => {
    let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS)
      ? JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS))
      : state.constants.data;
    let permissions = permissionHelper.sortPermissionsByModule(
      constants.permission
    );

    return permissions;
  },
  [G_CONSTANTS]: (state) => {
    let constants = uiHelper.getLocalStorage(LOCAL.CONSTANTS);

    return constants ? JSON.parse(constants) : state.constants.data;
  }
};

// Actions
const actions = {
  async [A_CONSTANTS]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await CONSTANTS.getConstants()
      .then((response) => {
        let result = response;
        commit(M_CONSTANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CONSTANTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_CONSTANTS_STATE]({ commit }) {
    commit(M_INITIAL_CONSTANTS_STATE);
  }
};

// Mutations
const mutations = {
  [M_CONSTANTS](state, result) {
    if (result.code == 200) {
      let d = result.data;

      state.constants = {
        code: result.code,
        complete: true,
        data: {
          adminRole: d.admin_role,
          permission: d.permission,
          userRole: d.user_role,
          country: d.country,
          pronouns: d.pronouns,
          productionHouseDescription: d.production_house_description,
          hairColor: d.hair_color,
          hairLength: d.hair_length,
          hairType: d.hair_type,
          eyeColor: d.eye_color,
          skinColor: d.skin_color,
          race: d.race,
          languageSpoken: d.language_spoken,
          projectTypes: d.project_types,
          projectRoles: d.project_roles,
          verificationStatus: d.verification_status,
          allCountry: d.all_country,
          flag: d.flag,
          flagContent: d.flag_content,
          rate: d.rate,
          castingJobStatus: d.casting_job_status,
          loadingPlatform: d.loading_platform,
          loadingPeriod: d.loading_period,
          height: d.height,
          age: d.age,
          projectTypeDataSet: d.project_type_data_set,
          chatMessageType: d.chat_message_type,
          mediaType: d.media_type,
          advertisementAction: d.advertisement_action,
          advertisementPosition: d.advertisement_position,
          auditLogTableName: d.audit_log_table_name,
          auditLogColumnName: d.audit_log_column_name,
          paymentType: d.payment_type,
          reviewStatus: d.review_status
        },
        message: null
      };

      uiHelper.setLocalStorage(
        LOCAL.CONSTANTS,
        JSON.stringify(state.constants.data)
      );
    } else {
      state.user = {
        code: result.code,
        complete: false,
        data: {
          adminRole: [],
          permission: [],
          userRole: [],
          country: [],
          pronouns: [],
          productionHouseDescription: [],
          hairColor: [],
          hairLength: [],
          hairType: [],
          eyeColor: [],
          skinColor: [],
          race: [],
          languageSpoken: [],
          projectTypes: [],
          projectRoles: [],
          verificationStatus: [],
          allCountry: [],
          flag: [],
          flagContent: [],
          rate: [],
          castingJobStatus: [],
          loadingPlatform: [],
          loadingPeriod: [],
          height: [],
          age: [],
          projectTypeDataSet: [],
          chatMessageType: [],
          mediaType: [],
          advertisementAction: [],
          advertisementPosition: [],
          auditLogTableName: [],
          auditLogColumnName: [],
          paymentType: []
        },
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_INITIAL_CONSTANTS_STATE](state) {
    state.constants = {
      code: 0,
      complete: false,
      data: {
        adminRole: [],
        permission: [],
        userRole: [],
        country: [],
        pronouns: [],
        productionHouseDescription: [],
        hairColor: [],
        hairLength: [],
        hairType: [],
        eyeColor: [],
        skinColor: [],
        race: [],
        languageSpoken: [],
        projectTypes: [],
        projectRoles: [],
        verificationStatus: [],
        allCountry: [],
        flag: [],
        flagContent: [],
        rate: [],
        castingJobStatus: [],
        loadingPlatform: [],
        loadingPeriod: [],
        height: [],
        age: [],
        projectTypeDataSet: [],
        chatMessageType: [],
        mediaType: [],
        advertisementAction: [],
        advertisementPosition: [],
        auditLogTableName: [],
        auditLogColumnName: [],
        paymentType: []
      },
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
