import { i18nHelper } from '@/core/utils';
import {
  YesNo,
  ActiveStatus,
  HideShow,
  FLAG_STATUS,
  POST_STATUS,
  ADVERTISEMENT_ACTION,
  SUBSCRIPTION_TYPE
} from '@/core/constants/enums';

export const listService = {
  getDynamicList,
  getYesNoList,
  getStatusList,
  getVerificationStatusList,
  getHideShowList,
  getFlagStatusList,
  getPostStatusList,
  getAdvertisementActionList,
  getAdvertisementNumberOfNewsfeedGapList,
  getSubscriptionTypeList,
  getMemberStatusList
};

function getDynamicList(originList, hasOptionAll = false, valueKey, textKey) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  originList.forEach((x) => {
    list.push({
      text: textKey ? x[textKey] : x.description,
      value: valueKey ? x[valueKey] : x.value
    });
  });

  return list;
}

function getYesNoList(hasOptionAll = false) {
  let list = [];

  if (hasOptionAll) {
    list.push({
      text: i18nHelper.getMessage(`label.all`),
      value: ''
    });
  }

  list.push({
    text: i18nHelper.getMessage(`enumYesNo.${YesNo.YES}`),
    value: YesNo.YES
  });

  list.push({
    text: i18nHelper.getMessage(`enumYesNo.${YesNo.NO}`),
    value: YesNo.NO
  });

  return list;
}

function getStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumActivationStatus.${Number(ActiveStatus.ACTIVE)}`
    ),
    value: Number(ActiveStatus.ACTIVE)
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumActivationStatus.${Number(ActiveStatus.INACTIVE)}`
    ),
    value: Number(ActiveStatus.INACTIVE)
  });

  return list;
}

function getVerificationStatusList(originList) {
  let list = [];

  originList.forEach((x) => {
    if (x.value != 'pending') {
      list.push({
        text: i18nHelper.getMessage(`enumProfileVerificationStatus.${x.value}`),
        value: x.value
      });
    }
  });

  return list;
}

function getHideShowList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumHideShow.${HideShow.HIDE}`),
    value: HideShow.HIDE
  });

  list.push({
    text: i18nHelper.getMessage(`enumHideShow.${HideShow.SHOW}`),
    value: HideShow.SHOW
  });

  return list;
}

function getFlagStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumFlagStatus.${FLAG_STATUS.PENDING}`),
    value: FLAG_STATUS.PENDING
  });

  list.push({
    text: i18nHelper.getMessage(`enumFlagStatus.${FLAG_STATUS.APPROVED}`),
    value: FLAG_STATUS.APPROVED
  });

  list.push({
    text: i18nHelper.getMessage(`enumFlagStatus.${FLAG_STATUS.REJECTED}`),
    value: FLAG_STATUS.REJECTED
  });

  return list;
}

function getPostStatusList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(`enumPostStatus.${POST_STATUS.PENDING}`),
    value: POST_STATUS.PENDING
  });

  list.push({
    text: i18nHelper.getMessage(`enumPostStatus.${POST_STATUS.LIVE}`),
    value: POST_STATUS.LIVE
  });

  list.push({
    text: i18nHelper.getMessage(`enumPostStatus.${POST_STATUS.ENDED}`),
    value: POST_STATUS.ENDED
  });

  return list;
}

function getAdvertisementActionList() {
  let list = [];

  list.push({
    text: i18nHelper.getMessage(
      `enumAdvertisementAction.${ADVERTISEMENT_ACTION.WEBSITE}`
    ),
    value: ADVERTISEMENT_ACTION.WEBSITE
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumAdvertisementAction.${ADVERTISEMENT_ACTION.CASTING}`
    ),
    value: ADVERTISEMENT_ACTION.CASTING
  });

  list.push({
    text: i18nHelper.getMessage(
      `enumAdvertisementAction.${ADVERTISEMENT_ACTION.USER}`
    ),
    value: ADVERTISEMENT_ACTION.USER
  });

  return list;
}

function getAdvertisementNumberOfNewsfeedGapList() {
  var list = [];
  const gaps = [2, 3, 4, 5];

  gaps.forEach((x) => {
    list.push({
      text: x,
      value: x
    });
  });

  return list;
}

function getSubscriptionTypeList() {
  let list = [
    {
      text: i18nHelper.getMessage(
        `enumSubscriptionType.${SUBSCRIPTION_TYPE.MEMBERSHIP_SUBSCRIPTION}`
      ),
      value: SUBSCRIPTION_TYPE.MEMBERSHIP_SUBSCRIPTION
    },
    {
      text: i18nHelper.getMessage(
        `enumSubscriptionType.${SUBSCRIPTION_TYPE.UPLOAD_TOKEN}`
      ),
      value: SUBSCRIPTION_TYPE.UPLOAD_TOKEN
    },
    {
      text: i18nHelper.getMessage(
        `enumSubscriptionType.${SUBSCRIPTION_TYPE.ADMIN_ACCOUNT}`
      ),
      value: SUBSCRIPTION_TYPE.ADMIN_ACCOUNT
    }
  ];

  return list;
}
function getMemberStatusList() {
  return [
    {
      text: 'Expired',
      value: 'expired'
    },
    {
      text: 'Pending',
      value: 'pending'
    }
  ];
}
