import { apiService } from '@/core/services';

export const AUDIT_LOGS = {
  getAuditLogs,
  getAuditLog
};

const URL = {
  ADMIN: 'admin',
  AUDIT_LOGS: 'audit-logs'
};

function getAuditLogs(data) {
  let requestOptions = {
    params: {
      created_by: data.createdBy,
      table_id: data.tableId,
      table_name: data.tableName,
      column_name: data.columnName,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.AUDIT_LOGS}`, requestOptions);
}

function getAuditLog(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.AUDIT_LOGS}/${id}`);
}
