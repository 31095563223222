import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import constantsDesc from '@/core/filters/constants-desc.filter';
import { USER } from '@/core/api';
import { apiHelper, uiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'user/';

/* Define Action Name */
const A_GET_USERS = 'getUsers';
const A_GET_USERS_PAGINATION = 'getUsersPagination';
const A_GET_USER = 'getUser';
const A_UPDATE_USER_LOGIN_STATUS = 'updateUserLoginStatus';
const A_GET_ACCOUNT_VERIFICATION_LIST = 'getAccountVerificationList';
const A_GET_ACCOUNT_VERIFICATION_DETAILS = 'getAccountVerificationDetails';
const A_UPDATE_ACCOUNT_VERIFICATION = 'updateAccountVerification';
const A_DEACTIVATE_USER_ACCOUNT = 'deactivateUserAccount';
const A_REACTIVATE_USER_ACCOUNT = 'reactivateUserAccount';

/* Define Reset State Action Name */
const A_INITIAL_GET_USERS_STATE = 'initialGetUsersState';
const A_INITIAL_GET_USERS_PAGINATION_STATE = 'initialGetUsersPaginationState';
const A_INITIAL_GET_USER_STATE = 'initialGetUserState';
const A_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE =
  'initialUpdateUserLoginStatusState';
const A_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE =
  'initialGetAccountVerificationListState';
const A_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE =
  'initialGetAccountVerificationDetailsState';
const A_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE =
  'initialUpdateAccountVerificationState';
const A_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE =
  'initialDeactivateUserAccountState';
const A_INITIAL_REACTIVATE_USER_ACCOUNT_STATE =
  'initialReactivateUserAccountState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USERS = 'setGetUsers';
const M_GET_USERS_PAGINATION = 'setGetUsersPagination';
const M_GET_USER = 'setGetUser';
const M_UPDATE_USER_LOGIN_STATUS = 'setUpdateUserLoginStatus';
const M_GET_ACCOUNT_VERIFICATION_LIST = 'setGetAccountVerificationList';
const M_GET_ACCOUNT_VERIFICATION_DETAILS = 'setGetAccountVerificationDetails';
const M_UPDATE_ACCOUNT_VERIFICATION = 'setUpdateAccountVerification';
const M_DEACTIVATE_USER_ACCOUNT = 'setDeactivateUserAccount';
const M_REACTIVATE_USER_ACCOUNT = 'setReactivateUserAccount';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_USERS_STATE = 'setInitialGetUsersState';
const M_INITIAL_GET_USERS_PAGINATION_STATE = 'setInitialGetUsersPaginationState';
const M_INITIAL_GET_USER_STATE = 'setInitialGetUserState';
const M_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE =
  'setInitialUpdateUserLoginStatusState';
const M_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE =
  'setInitialGetAccountVerificationListState';
const M_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE =
  'setInitialGetAccountVerificationDetailsState';
const M_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE =
  'setInitialUpdateAccountVerificationState';
const M_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE =
  'setInitialDeactivateUserAccountState';
const M_INITIAL_REACTIVATE_USER_ACCOUNT_STATE =
  'setInitialReactivateUserAccountState';

/* Define Export Name */
export const USER_GET_USERS = MODULE_NAME + A_GET_USERS;
export const USER_GET_USERS_PAGINATION = MODULE_NAME + A_GET_USERS_PAGINATION;
export const USER_GET_USER = MODULE_NAME + A_GET_USER;
export const USER_UPDATE_USER_LOGIN_STATUS =
  MODULE_NAME + A_UPDATE_USER_LOGIN_STATUS;
export const USER_GET_ACCOUNT_VERIFICATION_LIST =
  MODULE_NAME + A_GET_ACCOUNT_VERIFICATION_LIST;
export const USER_GET_ACCOUNT_VERIFICATION_DETAILS =
  MODULE_NAME + A_GET_ACCOUNT_VERIFICATION_DETAILS;
export const USER_UPDATE_ACCOUNT_VERIFICATION =
  MODULE_NAME + A_UPDATE_ACCOUNT_VERIFICATION;
export const USER_DEACTIVATE_USER_ACCOUNT =
  MODULE_NAME + A_DEACTIVATE_USER_ACCOUNT;
export const USER_REACTIVATE_USER_ACCOUNT =
  MODULE_NAME + A_REACTIVATE_USER_ACCOUNT;

/* Define Reset State Name */
export const USER_INITIAL_GET_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_USERS_STATE;
export const USER_INITIAL_GET_USERS_PAGINATION_STATE =
  MODULE_NAME + A_INITIAL_GET_USERS_PAGINATION_STATE;
export const USER_INITIAL_GET_USER_STATE =
  MODULE_NAME + A_INITIAL_GET_USER_STATE;
export const USER_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE;
export const USER_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE;
export const USER_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE =
  MODULE_NAME + A_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE;
export const USER_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE;
export const USER_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE =
  MODULE_NAME + A_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE;
export const USER_INITIAL_REACTIVATE_USER_ACCOUNT_STATE =
  MODULE_NAME + A_INITIAL_REACTIVATE_USER_ACCOUNT_STATE;

const state = {
  users: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  usersPagination: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  user: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateUserLoginStatus: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  accountVerificationList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  accountVerificationDetails: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAccountVerification: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deactivateUserAccount: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  reactivateUserAccount: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_USERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUsers(data)
      .then((response) => {
        let result = response;
        commit(M_GET_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USERS_PAGINATION]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUsersPagination(data)
      .then((response) => {
        let result = response;
        commit(M_GET_USERS_PAGINATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USERS_PAGINATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_USER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getUser(id)
      .then((response) => {
        let result = response;
        commit(M_GET_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_USER_LOGIN_STATUS]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.updateUserLoginStatus(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_USER_LOGIN_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_USER_LOGIN_STATUS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ACCOUNT_VERIFICATION_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getAccountVerifications(data)
      .then((response) => {
        let result = response;
        commit(M_GET_ACCOUNT_VERIFICATION_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ACCOUNT_VERIFICATION_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ACCOUNT_VERIFICATION_DETAILS]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.getAccountVerificationDetails(id)
      .then((response) => {
        let result = response;
        commit(M_GET_ACCOUNT_VERIFICATION_DETAILS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ACCOUNT_VERIFICATION_DETAILS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ACCOUNT_VERIFICATION]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.updateAccountVerification(id, data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_ACCOUNT_VERIFICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_ACCOUNT_VERIFICATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DEACTIVATE_USER_ACCOUNT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.deactivateUserAccount(id, data)
      .then((response) => {
        let result = response;
        commit(M_DEACTIVATE_USER_ACCOUNT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DEACTIVATE_USER_ACCOUNT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_REACTIVATE_USER_ACCOUNT]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    USER.reactivateUserAccount(id, data)
      .then((response) => {
        let result = response;
        commit(M_REACTIVATE_USER_ACCOUNT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_REACTIVATE_USER_ACCOUNT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_USERS_STATE);
  },
  [A_INITIAL_GET_USERS_PAGINATION_STATE]({ commit }) {
    commit(M_INITIAL_GET_USERS_PAGINATION_STATE);
  },
  [A_INITIAL_GET_USER_STATE]({ commit }) {
    commit(M_INITIAL_GET_USER_STATE);
  },
  [A_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE);
  },
  [A_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE);
  },
  [A_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE]({ commit }) {
    commit(M_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE);
  },
  [A_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE);
  },
  [A_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE]({ commit }) {
    commit(M_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE);
  },
  [A_INITIAL_REACTIVATE_USER_ACCOUNT_STATE]({ commit }) {
    commit(M_INITIAL_REACTIVATE_USER_ACCOUNT_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_USERS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.users = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            role: x.role,
            fname: x.fname,
            displayName: x.display_name,
            mobileDialCode: x.mobile_dial_code,
            mobile: x.mobile,
            email: x.email,
            isActivated: x.is_activated,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            lastLogin: dateTimeFormat(x.last_login),
            lastLoginTimeStamp: new Date(x.last_login).getTime(),
            contactNumber: `${x.mobile_dial_code}-${x.mobile}`,
            isAccountDeactivated: Number(x.is_account_deactivated)
          };
        }),
        message: null,
      };
    } else {
      state.users = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_USERS_PAGINATION](state, result) {
    if (result.code === 200) {
      let i = result.data.items;
      let p = result.data.pagination;

      state.usersPagination = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            role: x.role,
            fname: x.fname,
            displayName: x.display_name,
            mobileDialCode: x.mobile_dial_code,
            mobile: x.mobile,
            email: x.email,
            isActivated: x.is_activated,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            lastLogin: dateTimeFormat(x.last_login),
            lastLoginTimeStamp: new Date(x.last_login).getTime(),
            contactNumber: `${x.mobile_dial_code}-${x.mobile}`,
            isAccountDeactivated: Number(x.is_account_deactivated)
          };
        }),
        pagination: {
          currentPage: p.current_page,
          lastPage: p.last_page,
          perPage: p.per_page,
          total: p.total
        },
        message: null,
      };
    } else {
      state.usersPagination = {
        code: result.code,
        complete: true,
        pagination: null,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_USER](state, result) {
    if (result.code == 200) {
      let d = result.data;

      state.user = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          role: d.role,
          fname: d.fname,
          firstName: d.first_name,
          lastName: d.last_name,
          displayName: d.display_name,
          description: d.description,
          descriptionOther: d.description_other,
          gender: getGender(d.gender, d.gender_other),
          genderOther: d.gender_other,
          pronouns: getPronouns(d.pronouns, d.pronouns_other),
          pronounsOther: d.pronouns_other,
          dob: d.dob,
          countryId: constantsDesc(d.country_id, 'allCountry', 'id', 'name'),
          mobileDialCode: d.mobile_dial_code,
          mobile: d.mobile,
          contactNumber: `${d.mobile_dial_code}-${d.mobile}`,
          email: d.email,
          talentsNo: d.talents_no,
          guardianRelationship: d.guardian_relationship,
          guardianRelationshipOther: d.guardian_relationship_other,
          guardianFname: d.guardian_fname,
          guardianFirstName: d.guardian_first_name,
          guardianLastName: d.guardian_last_name,
          guardianGender: getGender(d.guardian_gender, d.guardian_gender_other),
          guardianGenderOther: d.guardian_gender_other,
          guardianPronouns: getPronouns(
            d.guardian_pronouns,
            d.guardian_pronouns_other
          ),
          guardianPronounsOther: d.guardian_pronouns_other,
          guardianMobileDialCode: d.guardian_mobile_dial_code,
          guardianMobile: d.guardian_mobile,
          guardianEmail: d.guardian_email,
          projectTypeIds: constantsDesc(
            d.project_type_ids,
            'projectTypes',
            'id',
            'name'
          ),
          projectRoleIds: constantsDesc(
            d.project_role_ids,
            'projectRoles',
            'id',
            'name'
          ),
          companyCode: d.company_code,
          isActivated: d.is_activated,
          isLoginBlocked: d.is_login_blocked,
          createdAt: dateTimeFormat(d.created_at),
          lastLogin: dateTimeFormat(d.last_login),
          isAccountDeactivated: d.is_account_deactivated,
          isDeleted: d.is_deleted,
          profile: d.profile ? profile(d) : null
        },
        message: null
      };
    } else {
      state.user = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_USER_LOGIN_STATUS](state, result) {
    state.updateUserLoginStatus = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_GET_ACCOUNT_VERIFICATION_LIST](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.accountVerificationList = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            last_name: x.last_name,
            first_name: x.first_name,
            fname: `${x.first_name} ${x.last_name}`,
            role: x.role,
            verificationStatus: x.verification_status,
            verificationSubmittedAt: dateTimeFormat(x.verification_submitted_at),
            verificationSubmittedAtTimeStamp: new Date(x.verification_submitted_at).getTime(),
          };
        }),
        message: null,
      };
    } else {
      state.accountVerificationList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_ACCOUNT_VERIFICATION_DETAILS](state, result) {
    if (result.code == 200) {
      let d = result.data;

      state.accountVerificationDetails = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          role: d.role,
          fname: d.fname,
          firstName: d.first_name,
          lastName: d.last_name,
          displayName: d.display_name,
          description: d.description,
          descriptionOther: d.description_other,
          gender: getGender(d.gender, d.gender_other),
          genderOther: d.gender_other,
          pronouns: getPronouns(d.pronouns, d.pronouns_other),
          pronounsOther: d.pronouns_other,
          dob: d.dob,
          countryId: constantsDesc(d.country_id, 'allCountry', 'id', 'name'),
          mobileDialCode: d.mobile_dial_code,
          mobile: d.mobile,
          contactNumber: `${d.mobile_dial_code}-${d.mobile}`,
          email: d.email,
          talentsNo: d.talents_no,
          guardianRelationship: d.guardian_relationship,
          guardianRelationshipOther: d.guardian_relationship_other,
          guardianFname: d.guardian_fname,
          guardianFirstName: d.guardian_first_name,
          guardianLastName: d.guardian_last_name,
          guardianGender: getGender(d.guardian_gender, d.guardian_gender_other),
          guardianGenderOther: d.guardian_gender_other,
          guardianPronouns: getPronouns(
            d.guardian_pronouns,
            d.guardian_pronouns_other
          ),
          guardianPronounsOther: d.guardian_pronouns_other,
          guardianMobileDialCode: d.guardian_mobile_dial_code,
          guardianMobile: d.guardian_mobile,
          guardianEmail: d.guardian_email,
          projectTypeIds: constantsDesc(
            d.project_type_ids,
            'projectTypes',
            'id',
            'name'
          ),
          projectRoleIds: constantsDesc(
            d.project_role_ids,
            'projectRoles',
            'id',
            'name'
          ),
          companyCode: d.company_code,
          isActivated: d.is_activated,
          isLoginBlocked: d.is_login_blocked,
          createdAt: dateTimeFormat(d.created_at),
          lastLogin: dateTimeFormat(d.last_login),
          profile: d.profile ? profile(d) : null
        },
        message: null
      };
    } else {
      state.accountVerificationDetails = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_ACCOUNT_VERIFICATION](state, result) {
    state.updateAccountVerification = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DEACTIVATE_USER_ACCOUNT](state, result) {
    state.deactivateUserAccount = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_REACTIVATE_USER_ACCOUNT](state, result) {
    state.reactivateUserAccount = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_USERS_STATE](state) {
    state.users = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_USERS_PAGINATION_STATE](state) {
    state.usersPagination = {
      code: 0,
      complete: false,
      data: null,
      pagination: null,
      message: null,
    };
  },
  [M_INITIAL_GET_USER_STATE](state) {
    state.user = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_USER_LOGIN_STATUS_STATE](state) {
    state.updateUserLoginStatus = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_ACCOUNT_VERIFICATION_LIST_STATE](state) {
    state.accountVerificationList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_ACCOUNT_VERIFICATION_DETAILS_STATE](state) {
    state.accountVerificationDetails = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ACCOUNT_VERIFICATION_STATE](state) {
    state.updateAccountVerification = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DEACTIVATE_USER_ACCOUNT_STATE](state) {
    state.deactivateUserAccount = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_REACTIVATE_USER_ACCOUNT_STATE](state) {
    state.reactivateUserAccount = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

function getPronouns(pronouns, other) {
  let newPronouns = constantsDesc(pronouns, 'pronouns');
  let newPronounsList = newPronouns.split(', ');

  if (newPronounsList.includes('Others')) {
    newPronounsList = newPronounsList.filter((x) => x != 'Others');

    newPronounsList.push(`Others - (${other})`);

    newPronouns = newPronounsList.join(', ');
  }

  return newPronouns;
}

function getGender(gender, other) {
  return gender == 'Other' ? `${gender} - (${other})` : gender;
}

function profile(data) {
  let role = data.role
    .toLowerCase()
    .replace(' ', '_')
    .replace('/', '_')
    .replace('/', '_');

  let types = {
    talent: (data) => {
      return talentProfile(data);
    },
    freelancer: (data) => {
      return freelancerProfile(data);
    },
    talent_agency: (data) => {
      return talentAgencyProfile(data);
    },
    production_corporate_agency: (data) => {
      return productionHouseProfile(data);
    }
  };

  function talentProfile(data) {
    let p = data;

    return {
      profileId: p.profile_id,
      headshotFile: p.headshot_file
        ? {
            id: p.headshot_file.id,
            mediaPath: p.headshot_file.media_path
          }
        : null,
      displayName: p.display_name,
      introSummary: p.intro_summary,
      bio: p.bio,
      cv: null,
      cvFile: p.cv_file
        ? {
            id: p.cv_file.id,
            mediaPath: p.cv_file.media_path
          }
        : null,
      ageToPlay: p.age_to_play,
      height: p.height,
      weight: p.weight,
      hairColor: constantsDesc(p.hair_color, 'hairColor'),
      hairLength: constantsDesc(p.hair_length, 'hairLength'),
      hairType: constantsDesc(p.hair_type, 'hairType'),
      eyeColor: constantsDesc(p.eye_color, 'eyeColor'),
      skinColor: constantsDesc(p.skin_color, 'skinColor'),
      ethnicity: p.ethnicity,
      race: constantsDesc(p.race, 'race'),
      languagesSpoken: constantsDesc(p.languages_spoken, 'languageSpoken'),
      introVideoFile: p.intro_video_file
        ? {
            id: p.intro_video_file.id,
            mediaPath: p.intro_video_file.media_path
          }
        : null,
      galleryFile: p.gallery_file
        ? p.gallery_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      location: p.location,
      skills: p.skills,
      tvOnlineRate: constantsDesc(p.tv_online_rate, 'rate'),
      featureFilmRate: constantsDesc(p.feature_film_rate, 'rate'),
      commercialRate: constantsDesc(p.commercial_rate, 'rate'),
      varietyRate: constantsDesc(p.variety_rate, 'rate'),
      studentProjectRate: constantsDesc(p.student_project_rate, 'rate'),
      corporateRate: constantsDesc(p.corporate_rate, 'rate'),
      publicServiceAnnouncementRate: constantsDesc(
        p.public_service_announcement_rate,
        'rate'
      ),
      voiceoverRate: constantsDesc(p.voiceover_rate, 'rate'),
      rates: p.rates,
      facebook: p.facebook,
      instagram: p.instagram,
      twitter: p.twitter,
      linkedin: p.linkedin,
      youtube: p.youtube,
      tiktok: p.tiktok,
      gestureImage: p.gesture_image
        ? {
            mediaPath: p.gesture_image,
            mediaType: uiHelper.getFileExtension(p.gesture_image)
          }
        : null,
      verificationSubmittedAt: dateTimeFormat(p.verification_submitted_at),
      verificationFile: p.verification_file
        ? p.verification_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      verificationStatus: p.verification_status,
      verificationRemark: p.verification_remark
    };
  }

  function freelancerProfile(data) {
    let p = data;

    return {
      profileId: p.profile_id,
      headshotFile: p.headshot_file
        ? {
            id: p.headshot_file.id,
            mediaPath: p.headshot_file.media_path
          }
        : null,
      displayName: p.display_name,
      introSummary: p.intro_summary,
      bio: p.bio,
      cv: null,
      cvFile: p.cv_file
        ? {
            id: p.cv_file.id,
            mediaPath: p.cv_file.media_path
          }
        : null,
      introVideoFile: p.intro_video_file
        ? {
            id: p.intro_video_file.id,
            mediaPath: p.intro_video_file.media_path
          }
        : null,
      galleryFile: p.gallery_file
        ? p.gallery_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      listProjects: p.list_projects,
      websiteLink: p.website_link,
      facebook: p.facebook,
      instagram: p.instagram,
      twitter: p.twitter,
      linkedin: p.linkedin,
      youtube: p.youtube,
      tiktok: p.tiktok,
      gestureImage: p.gesture_image
        ? {
            mediaPath: p.gesture_image,
            mediaType: uiHelper.getFileExtension(p.gesture_image)
          }
        : null,
      verificationSubmittedAt: dateTimeFormat(p.verification_submitted_at),
      verificationFile: p.verification_file
        ? p.verification_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      verificationStatus: p.verification_status,
      verificationRemark: p.verification_remark
    };
  }

  function talentAgencyProfile(data) {
    let p = data;

    return {
      profileId: p.profile_id,
      companyLogoFile: p.company_logo_file
        ? {
            id: p.company_logo_file.id,
            mediaPath: p.company_logo_file.media_path,
            mediaType: uiHelper.getFileExtension(p.company_logo_file.media_path)
          }
        : null,
      companyWriteUp: p.company_write_up,
      companyShowreelVideoFile: p.company_showreel_video_file
        ? {
            id: p.company_showreel_video_file.id,
            mediaPath: p.company_showreel_video_file.media_path,
            mediaType: uiHelper.getFileExtension(
              p.company_showreel_video_file.media_path
            )
          }
        : null,
      companyShowreelImageFile: p.company_showreel_image_file
        ? p.company_showreel_image_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      listProjects: p.list_projects,
      websiteLink: p.website_link,
      talentsNo: p.talents_no,
      facebook: p.facebook,
      instagram: p.instagram,
      twitter: p.twitter,
      linkedin: p.linkedin,
      youtube: p.youtube,
      tiktok: p.tiktok,
      gestureImage: p.gesture_image
        ? {
            mediaPath: p.gesture_image,
            mediaType: uiHelper.getFileExtension(p.gesture_image)
          }
        : null,
      verificationSubmittedAt: dateTimeFormat(p.verification_submitted_at),
      verificationFile: p.verification_file
        ? p.verification_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      verificationStatus: p.verification_status,
      verificationRemark: p.verification_remark
    };
  }

  function productionHouseProfile(data) {
    let p = data;

    return {
      profileId: p.profile_id,
      companyLogoFile: p.company_logo_file
        ? {
            id: p.company_logo_file.id,
            mediaPath: p.company_logo_file.media_path,
            mediaType: uiHelper.getFileExtension(p.company_logo_file.media_path)
          }
        : null,
      companyWriteUp: p.company_write_up,
      companyShowreelVideoFile: p.company_showreel_video_file
        ? {
            id: p.company_showreel_video_file.id,
            mediaPath: p.company_showreel_video_file.media_path,
            mediaType: uiHelper.getFileExtension(
              p.company_showreel_video_file.media_path
            )
          }
        : null,
      companyShowreelImageFile: p.company_showreel_image_file
        ? p.company_showreel_image_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      listProjects: p.list_projects,
      websiteLink: p.website_link,
      talentsNo: p.talents_no,
      facebook: p.facebook,
      instagram: p.instagram,
      twitter: p.twitter,
      linkedin: p.linkedin,
      youtube: p.youtube,
      tiktok: p.tiktok,
      gestureImage: p.gesture_image
        ? {
            mediaPath: p.gesture_image,
            mediaType: uiHelper.getFileExtension(p.gesture_image)
          }
        : null,
      verificationSubmittedAt: dateTimeFormat(p.verification_submitted_at),
      verificationFile: p.verification_file
        ? p.verification_file.map((x) => {
            return {
              id: x.id,
              mediaPath: x.media_path,
              mediaType: uiHelper.getFileExtension(x.media_path)
            };
          })
        : null,
      verificationStatus: p.verification_status,
      verificationRemark: p.verification_remark
    };
  }

  return types[role](data.profile);
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
