import { APP_INFO } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'appInfo/';

/* Define Action Name */
const A_GET_APP_INFO_LIST = 'getAppInfoList';
const A_GET_APP_INFO = 'getAppInfo';
const A_UPDATE_APP_INFO = 'updateAppInfo';

/* Define Reset State Action Name */
const A_INITIAL_APP_INFO_LIST_STATE = 'initialAppInfoListState';
const A_INITIAL_APP_INFO_STATE = 'initialAppInfoState';
const A_INITIAL_UPDATE_APP_INFO_STATE = 'initialUpdateAppInfoState';

/* Define Getter Name */

/* Define Mutation Name */
const M_APP_INFO_LIST = 'setAppInfoList';
const M_APP_INFO = 'setAppInfo';
const M_UPDATE_APP_INFO = 'setUpdateAppInfo';

/* Define Reset State Mutation Name */
const M_INITIAL_APP_INFO_LIST_STATE = 'setInitialAppInfoListState';
const M_INITIAL_APP_INFO_STATE = 'setInitialAppInfoState';
const M_INITIAL_UPDATE_APP_INFO_STATE = 'setInitialUpdateAppInfoState';

/* Define Export Name */
export const APP_INFO_GET_APP_INFO_LIST = MODULE_NAME + A_GET_APP_INFO_LIST;
export const APP_INFO_GET_APP_INFO = MODULE_NAME + A_GET_APP_INFO;
export const APP_INFO_UPDATE_APP_INFO = MODULE_NAME + A_UPDATE_APP_INFO;

/* Define Reset State Name */
export const APP_INFO_INITIAL_APP_INFO_LIST_STATE =
  MODULE_NAME + A_INITIAL_APP_INFO_LIST_STATE;
export const APP_INFO_INITIAL_APP_INFO_STATE =
  MODULE_NAME + A_INITIAL_APP_INFO_STATE;
export const APP_INFO_INITIAL_UPDATE_APP_INFO_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_APP_INFO_STATE;

const state = {
  appInfoList: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  appInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAppInfo: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_APP_INFO_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    APP_INFO.getAppInfoList(data)
      .then((response) => {
        result = response;
        commit(M_APP_INFO_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_APP_INFO_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_APP_INFO]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    APP_INFO.getAppInfo(id)
      .then((response) => {
        result = response;
        commit(M_APP_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_APP_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_APP_INFO]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    APP_INFO.updateAppInfo(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_APP_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_APP_INFO, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_APP_INFO_LIST_STATE]({ commit }) {
    commit(M_INITIAL_APP_INFO_LIST_STATE);
  },
  [A_INITIAL_APP_INFO_STATE]({ commit }) {
    commit(M_INITIAL_APP_INFO_STATE);
  },
  [A_INITIAL_UPDATE_APP_INFO_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_APP_INFO_STATE);
  }
};

// Mutations
const mutations = {
  [M_APP_INFO_LIST](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.appInfoList = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            platform: x.platform,
            version: x.version,
            forceUpdate: x.force_update,
            url: x.url,
            message: x.message
          };
        }),
        message: null,

      };
    } else {
      state.appInfoList = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_APP_INFO](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.appInfo = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          platform: d.platform,
          version: d.version,
          forceUpdate: d.force_update,
          url: d.url,
          message: d.message
        },
        message: null
      };
    } else {
      state.appInfo = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_APP_INFO](state, result) {
    state.updateAppInfo = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_APP_INFO_LIST_STATE](state) {
    state.appInfoList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_APP_INFO_STATE](state) {
    state.appInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_APP_INFO_STATE](state) {
    state.updateAppInfo = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
