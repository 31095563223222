import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import routes from './routes.js';
import store from '@/core/store';
import { ROUTE_NAME } from '@/core/constants';
import { permissionHelper } from '@/core/utils';
import { accessRightsService } from '@/core/services';
import { RESET_LAYOUT_CONFIG } from '@/core/store/config.module';
import { AUTH_IS_AUTHENTICATED } from '@/core/store/auth.module';

Vue.use(VueMeta);
Vue.use(VueRouter);

const scrollBehavior = () => {
  return { x: 0, y: 0 };
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior
});

router.beforeEach((to, from, next) => {
  // Redirect to login page if not logged in and trying to access a restricted page
  const isAuthenticated = store.getters[AUTH_IS_AUTHENTICATED];
  const userPermissions = permissionHelper.getUserPermissions();

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      // Not logged in so redirect to login page
      next({ name: ROUTE_NAME.LOGIN });
    } else {
      // Verify user permissions
      if (to.meta.authorizeModules.length > 0) {
        to.meta.authorizeModules.forEach((module) => {
          if (userPermissions.find((y) => y.name == module)) {
            //Verify user permission actions
            let arrayOfAccessRights = [];

            arrayOfAccessRights.push(
              accessRightsService.checkUserAccessRights(
                module,
                to.meta.authorizeActions
              )
            );

            if (arrayOfAccessRights.findIndex((x) => x == true) != -1) {
              next();
            } else next({ name: ROUTE_NAME.ERROR_403 });
            // next();
          } else next({ name: ROUTE_NAME.ERROR_403 });
        });
      } else next();
    }
  } else if (isAuthenticated) {
    // If user logged in and try to access login will redirect to home page
    if (to.name == ROUTE_NAME.LOGIN) {
      next({ name: ROUTE_NAME.HOME });
    } else next();
  } else next();
});

export default router;
