import { GLOBAL, MODULE, ACTION, ROUTE_NAME } from '@/core/constants';

const routes = [
  {
    path: '/login',
    name: ROUTE_NAME.LOGIN,
    component: () => import('@/views/pages/auth/Login'),
    meta: {
      layout: GLOBAL.LAYOUT_BLANK,
      requiresAuth: false
    },
    children: []
  },
  {
    path: '/home',
    name: ROUTE_NAME.HOME,
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      name: 'home',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: []
    },
    children: []
  },
  {
    path: '/account-management',
    name: ROUTE_NAME.ACCOUNT_MANAGEMENT,
    redirect: { name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST },
    component: () =>
      import('@/views/pages/account-management/AccountManagement.vue'),
    meta: {
      name: 'accountManagement',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ADMIN_USER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_LIST,
        component: () =>
          import('@/views/pages/account-management/AccountManagementList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [ACTION.ADMIN_USER_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_NEW,
        component: () =>
          import('@/views/pages/account-management/AccountManagementNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [ACTION.ADMIN_USER_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ACCOUNT_MANAGEMENT_DETAILS,
        component: () =>
          import(
            '@/views/pages/account-management/AccountManagementDetails.vue'
          ),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_USER],
          authorizeActions: [
            ACTION.ADMIN_USER_READ,
            ACTION.ADMIN_USER_UPDATE,
            ACTION.ADMIN_USER_DELETE
          ]
        },
        children: []
      }
    ]
  },
  {
    path: '/role-permission',
    name: ROUTE_NAME.ROLE_PERMISSION,
    redirect: { name: ROUTE_NAME.ROLE_PERMISSION_LIST },
    component: () => import('@/views/pages/role-permission/RolePermission.vue'),
    meta: {
      name: 'role',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ROLE]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ROLE_PERMISSION_LIST,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [ACTION.ROLE_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.ROLE_PERMISSION_NEW,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [ACTION.ROLE_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ROLE_PERMISSION_DETAILS,
        component: () =>
          import('@/views/pages/role-permission/RolePermissionDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ROLE],
          authorizeActions: [
            ACTION.ROLE_READ,
            ACTION.ROLE_UPDATE,
            ACTION.ROLE_DELETE
          ]
        },
        children: []
      }
    ]
  },
  {
    path: '/profile-details',
    name: ROUTE_NAME.PROFILE_DETAILS,
    redirect: { name: ROUTE_NAME.PROFILE_DETAILS_LIST },
    component: () => import('@/views/pages/profile-details/ProfileDetails.vue'),
    meta: {
      name: 'profileDetails',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.USER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.PROFILE_DETAILS_LIST,
        component: () =>
          import('@/views/pages/profile-details/ProfileDetailsList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.PROFILE_DETAILS_DETAILS,
        component: () =>
          import('@/views/pages/profile-details/ProfileDetailsDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ, ACTION.USER_UPDATE]
        },
        children: []
      }
    ]
  },
  {
    path: '/account-verification',
    name: ROUTE_NAME.ACCOUNT_VERIFICATION,
    redirect: { name: ROUTE_NAME.ACCOUNT_VERIFICATION_LIST },
    component: () =>
      import('@/views/pages/account-verification/AccountVerification.vue'),
    meta: {
      name: 'accountVerification',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.USER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ACCOUNT_VERIFICATION_LIST,
        component: () =>
          import(
            '@/views/pages/account-verification/AccountVerificationList.vue'
          ),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ACCOUNT_VERIFICATION_DETAILS,
        component: () =>
          import(
            '@/views/pages/account-verification/AccountVerificationDetails.vue'
          ),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.USER],
          authorizeActions: [ACTION.USER_READ, ACTION.USER_UPDATE]
        },
        children: []
      }
    ]
  },
  {
    path: '/platform-info',
    name: ROUTE_NAME.PLATFORM_INFO,
    component: () => import('@/views/pages/platform-info/PlatformInfo.vue'),
    meta: {
      name: 'platformInfo',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PLATFORM_INFO],
      authorizeActions: [ACTION.PLATFORM_INFO_READ, ACTION.PLATFORM_INFO_UPDATE]
    },
    children: []
  },
  {
    path: '/banner',
    name: ROUTE_NAME.BANNER,
    redirect: { name: ROUTE_NAME.BANNER_LIST },
    component: () => import('@/views/pages/banner/Banner.vue'),
    meta: {
      name: 'banner',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.BANNER]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.BANNER_LIST,
        component: () => import('@/views/pages/banner/BannerList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.BANNER_NEW,
        component: () => import('@/views/pages/banner/BannerNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [ACTION.BANNER_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.BANNER_DETAILS,
        component: () => import('@/views/pages/banner/BannerDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.BANNER],
          authorizeActions: [
            ACTION.BANNER_READ,
            ACTION.BANNER_UPDATE,
            ACTION.BANNER_DELETE
          ]
        },
        children: []
      }
    ]
  },
  {
    path: '/post',
    name: ROUTE_NAME.POST,
    redirect: { name: ROUTE_NAME.POST_LIST },
    component: () => import('@/views/pages/post/Post.vue'),
    meta: {
      name: 'post',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CASTING_JOB]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.POST_LIST,
        component: () => import('@/views/pages/post/PostList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CASTING_JOB],
          authorizeActions: [ACTION.CASTING_JOB_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.POST_DETAILS,
        component: () => import('@/views/pages/post/PostDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CASTING_JOB],
          authorizeActions: [ACTION.CASTING_JOB_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/package',
    name: ROUTE_NAME.PACKAGE,
    redirect: { name: ROUTE_NAME.PACKAGE_LIST },
    component: () => import('@/views/pages/package/PackageList.vue'),
    meta: {
      name: 'package',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.APP_INFO]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.PACKAGE_LIST,
        component: () => import('@/views/pages/package/PackageList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.APP_INFO],
          authorizeActions: [ACTION.APP_INFO_READ, ACTION.APP_INFO_UPDATE]
        },
        children: []
      }
    ]
  },
  {
    path: '/expired-package-user',
    name: ROUTE_NAME.EXPIRED_PACKAGE_USERS,
    redirect: { name: ROUTE_NAME.EXPIRED_PACKAGE_USERS },
    component: () =>
      import('@/views/pages/expired-package-user/ExpiredPackageListUser.vue'),
    meta: {
      name: 'expired-package-user',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.EXPIRED_USER_PACKAGE]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.EXPIRED_PACKAGE_USERS,
        component: () =>
          import(
            '@/views/pages/expired-package-user/ExpiredPackageListUser.vue'
          ),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.EXPIRED_USER_PACKAGE],
          authorizeActions: [ACTION.EXPIRED_USER_PACKAGE_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/app-update',
    name: ROUTE_NAME.APP_UPDATE,
    redirect: { name: ROUTE_NAME.APP_UPDATE_LIST },
    component: () => import('@/views/pages/app-update/AppUpdate.vue'),
    meta: {
      name: 'appUpdate',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.APP_INFO]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.APP_UPDATE_LIST,
        component: () => import('@/views/pages/app-update/AppUpdateList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.APP_INFO],
          authorizeActions: [ACTION.APP_INFO_READ, ACTION.APP_INFO_UPDATE]
        },
        children: []
      }
    ]
  },
  {
    path: '/talent-category',
    name: ROUTE_NAME.TALENT_CATEGORY,
    redirect: { name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE_LIST },
    component: () => import('@/views/pages/talent-category/TalentCategory.vue'),
    meta: {
      name: 'talentCategory',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PROJECT_ROLE, MODULE.PROJECT_TYPE]
    },
    children: [
      {
        path: 'project-role',
        name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE,
        redirect: { name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE_LIST },
        component: () =>
          import('@/views/pages/talent-category/project-role/ProjectRole.vue'),
        meta: {
          name: 'projectRole',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PROJECT_ROLE],
          authorizeActions: [ACTION.PROJECT_ROLE_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE_LIST,
            component: () =>
              import(
                '@/views/pages/talent-category/project-role/ProjectRoleList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.PROJECT_ROLE],
              authorizeActions: [ACTION.PROJECT_ROLE_READ]
            },
            children: []
          }
        ]
      },
      {
        path: 'project-type',
        name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_TYPE,
        redirect: { name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_TYPE_LIST },
        component: () =>
          import('@/views/pages/talent-category/project-type/ProjectType.vue'),
        meta: {
          name: 'projectType',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PROJECT_TYPE],
          authorizeActions: [ACTION.PROJECT_TYPE_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.TALENT_CATEGORY_PROJECT_TYPE_LIST,
            component: () =>
              import(
                '@/views/pages/talent-category/project-type/ProjectTypeList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.PROJECT_TYPE],
              authorizeActions: [ACTION.PROJECT_TYPE_READ]
            },
            children: []
          }
        ]
      },
      {
        path: 'skill',
        name: ROUTE_NAME.TALENT_CATEGORY_SKILL,
        redirect: { name: ROUTE_NAME.TALENT_CATEGORY_SKILL_LIST },
        component: () =>
          import('@/views/pages/talent-category/skill/Skill.vue'),
        meta: {
          name: 'skill',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.SKILL],
          authorizeActions: [ACTION.SKILL_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.TALENT_CATEGORY_SKILL_LIST,
            component: () =>
              import(
                '@/views/pages/talent-category/skill/SkillList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.SKILL],
              authorizeActions: [ACTION.SKILL_READ]
            },
            children: []
          }
        ]
      },
    ]
  },
  {
    path: '/chat-history',
    name: ROUTE_NAME.CHAT_HISTORY,
    redirect: { name: ROUTE_NAME.CHAT_HISTORY_LIST },
    component: () => import('@/views/pages/chat-history/ChatHistory.vue'),
    meta: {
      name: 'chat',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.CHAT]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.CHAT_HISTORY_LIST,
        component: () =>
          import('@/views/pages/chat-history/ChatHistoryList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CHAT],
          authorizeActions: [ACTION.CHAT_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.CHAT_HISTORY_DETAILS,
        component: () =>
          import('@/views/pages/chat-history/ChatHistoryDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.CHAT],
          authorizeActions: [ACTION.CHAT_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/maintenance',
    name: ROUTE_NAME.MAINTENANCE,
    redirect: { name: ROUTE_NAME.MAINTENANCE_LIST },
    component: () => import('@/views/pages/maintenance/Maintenance.vue'),
    meta: {
      name: 'maintenance',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.MAINTENANCE_SCHEDULE]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.MAINTENANCE_LIST,
        component: () =>
          import('@/views/pages/maintenance/MaintenanceList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.MAINTENANCE_SCHEDULE],
          authorizeActions: [ACTION.MAINTENANCE_SCHEDULE_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/flag',
    name: ROUTE_NAME.FLAG,
    redirect: { name: ROUTE_NAME.FLAG_MEMBER_LIST },
    component: () => import('@/views/pages/flag/Flag.vue'),
    meta: {
      name: 'flag',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.FLAG]
    },
    children: [
      {
        path: 'member',
        name: ROUTE_NAME.FLAG_MEMBER,
        redirect: { name: ROUTE_NAME.FLAG_MEMBER_LIST },
        component: () =>
          import('@/views/pages/flag/flag-member/FlagMember.vue'),
        meta: {
          name: 'memberFlag',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.FLAG],
          authorizeActions: [ACTION.FLAG_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.FLAG_MEMBER_LIST,
            component: () =>
              import('@/views/pages/flag/flag-member/FlagMemberList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.FLAG_MEMBER_DETAILS,
            component: () =>
              import('@/views/pages/flag/flag-member/FlagMemberDetails.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ, ACTION.FLAG_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'casting-post',
        name: ROUTE_NAME.FLAG_CASTING_POST,
        redirect: { name: ROUTE_NAME.FLAG_CASTING_POST_LIST },
        component: () =>
          import('@/views/pages/flag/flag-casting-post/FlagCastingPost.vue'),
        meta: {
          name: 'castingPostFlag',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.FLAG],
          authorizeActions: [ACTION.FLAG_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.FLAG_CASTING_POST_LIST,
            component: () =>
              import(
                '@/views/pages/flag/flag-casting-post/FlagCastingPostList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.FLAG_CASTING_POST_DETAILS,
            component: () =>
              import(
                '@/views/pages/flag/flag-casting-post/FlagCastingPostDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ, ACTION.FLAG_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'community-post',
        name: ROUTE_NAME.FLAG_COMMUNITY_POST,
        redirect: { name: ROUTE_NAME.FLAG_COMMUNITY_POST_LIST },
        component: () =>
          import(
            '@/views/pages/flag/flag-community-post/FlagCommunityPost.vue'
          ),
        meta: {
          name: 'communityPostFlag',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.FLAG],
          authorizeActions: [ACTION.FLAG_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.FLAG_COMMUNITY_POST_LIST,
            component: () =>
              import(
                '@/views/pages/flag/flag-community-post/FlagCommunityPostList.vue'
              ),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.FLAG_COMMUNITY_POST_DETAILS,
            component: () =>
              import(
                '@/views/pages/flag/flag-community-post/FlagCommunityPostDetails.vue'
              ),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ, ACTION.FLAG_UPDATE]
            },
            children: []
          }
        ]
      },
      {
        path: 'review',
        name: ROUTE_NAME.FLAG_REVIEW,
        redirect: { name: ROUTE_NAME.FLAG_REVIEW_LIST },
        component: () =>
          import('@/views/pages/flag/flag-review/FlagReview.vue'),
        meta: {
          name: 'reviewFlag',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.FLAG],
          authorizeActions: [ACTION.FLAG_READ]
        },
        children: [
          {
            path: 'list',
            name: ROUTE_NAME.FLAG_REVIEW_LIST,
            component: () =>
              import('@/views/pages/flag/flag-review/FlagReviewList.vue'),
            meta: {
              name: 'list',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ]
            },
            children: []
          },
          {
            path: 'details/:id',
            name: ROUTE_NAME.FLAG_REVIEW_DETAILS,
            component: () =>
              import('@/views/pages/flag/flag-review/FlagReviewDetails.vue'),
            meta: {
              name: 'details',
              layout: GLOBAL.LAYOUT,
              requiresAuth: true,
              authorizeModules: [MODULE.FLAG],
              authorizeActions: [ACTION.FLAG_READ, ACTION.FLAG_UPDATE]
            },
            children: []
          }
        ]
      }
    ]
  },
  {
    path: '/advertisement',
    name: ROUTE_NAME.ADVERTISEMENT,
    redirect: { name: ROUTE_NAME.ADVERTISEMENT_LIST },
    component: () => import('@/views/pages/advertisement/Advertisement.vue'),
    meta: {
      name: 'advertisement',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ADVERTISEMENT]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.ADVERTISEMENT_LIST,
        component: () =>
          import('@/views/pages/advertisement/AdvertisementList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADVERTISEMENT],
          authorizeActions: [ACTION.ADVERTISEMENT_READ]
        },
        children: []
      },
      {
        path: 'new',
        name: ROUTE_NAME.ADVERTISEMENT_NEW,
        component: () =>
          import('@/views/pages/advertisement/AdvertisementNew.vue'),
        meta: {
          name: 'new',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADVERTISEMENT],
          authorizeActions: [ACTION.ADVERTISEMENT_CREATE]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.ADVERTISEMENT_DETAILS,
        component: () =>
          import('@/views/pages/advertisement/AdvertisementDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADVERTISEMENT],
          authorizeActions: [
            ACTION.ADVERTISEMENT_READ,
            ACTION.ADVERTISEMENT_UPDATE,
            ACTION.ADVERTISEMENT_DELETE
          ]
        },
        children: []
      }
    ]
  },
  {
    path: '/community-post',
    name: ROUTE_NAME.COMMUNITY_POST,
    redirect: { name: ROUTE_NAME.COMMUNITY_POST_LIST },
    component: () => import('@/views/pages/community-post/CommunityPost.vue'),
    meta: {
      name: 'post',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.NEWS_FEED]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.COMMUNITY_POST_LIST,
        component: () =>
          import('@/views/pages/community-post/CommunityPostList.vue'),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.NEWS_FEED],
          authorizeActions: [ACTION.NEWS_FEED_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.COMMUNITY_POST_DETAILS,
        component: () =>
          import('@/views/pages/community-post/CommunityPostDetails.vue'),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.NEWS_FEED],
          authorizeActions: [ACTION.NEWS_FEED_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/payment',
    name: ROUTE_NAME.PAYMENT,
    redirect: { name: ROUTE_NAME.PAYMENT_DASHBOARD },
    component: () => import('@/views/pages/payment/Payment.vue'),
    meta: {
      name: 'payment',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.PAYMENT]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.PAYMENT_DASHBOARD,
        component: () => import('@/views/pages/payment/PaymentDashboard.vue'),
        meta: {
          name: 'dashboard',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.PAYMENT],
          authorizeActions: [ACTION.PAYMENT_READ]
        },
        children: []
      }
    ]
  },
  {
    path: '/chat',
    name: ROUTE_NAME.CHAT,
    component: () => import('@/views/pages/chat/Chat.vue'),
    meta: {
      name: 'chat',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.ADMIN_CHAT]
    },
    children: [
      {
        path: '',
        name: ROUTE_NAME.CHAT_LIVE,
        component: () => import('@/views/pages/chat/ChatLive.vue'),
        meta: {
          name: 'liveChat',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.ADMIN_CHAT],
          authorizeActions: [
            ACTION.ADMIN_CHAT_READ,
            ACTION.ADMIN_CHAT_UPDATE,
          ]
        },
        children: []
      }
    ]
  },
  {
    path: '/review',
    name: ROUTE_NAME.REVIEW,
    redirect: { name: ROUTE_NAME.REVIEW_LIST },
    component: () => import('@/views/pages/review/Review.vue'),
    meta: {
      name: 'review',
      layout: GLOBAL.LAYOUT,
      requiresAuth: true,
      authorizeModules: [MODULE.REVIEW]
    },
    children: [
      {
        path: 'list',
        name: ROUTE_NAME.REVIEW_LIST,
        component: () =>
          import(
            '@/views/pages/review/ReviewList.vue'
          ),
        meta: {
          name: 'list',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.REVIEW],
          authorizeActions: [ACTION.REVIEW_READ]
        },
        children: []
      },
      {
        path: 'details/:id',
        name: ROUTE_NAME.REVIEW_DETAILS,
        component: () =>
          import(
            '@/views/pages/review/ReviewDetails.vue'
          ),
        meta: {
          name: 'details',
          layout: GLOBAL.LAYOUT,
          requiresAuth: true,
          authorizeModules: [MODULE.REVIEW],
          authorizeActions: [ACTION.REVIEW_READ, ACTION.REVIEW_UPDATE]
        },
        children: []
      }
    ]
  },
  {
    path: '/error',
    component: () => import('@/views/pages/error/Error.vue'),
    children: [
      {
        path: '403',
        name: ROUTE_NAME.ERROR_403,
        component: () => import('@/views/pages/error/403.vue'),
        meta: {
          name: '403',
          layout: GLOBAL.LAYOUT_BLANK,
          requiresAuth: false,
          authorizeModules: []
        }
      },
      {
        path: '404',
        name: ROUTE_NAME.ERROR_404,
        component: () => import('@/views/pages/error/404.vue'),
        meta: {
          name: '404',
          layout: GLOBAL.LAYOUT_BLANK,
          requiresAuth: false,
          authorizeModules: []
        }
      }
    ]
  },
  {
    path: '/',
    redirect: { name: ROUTE_NAME.LOGIN }
  },
  {
    path: '*',
    redirect: { name: ROUTE_NAME.ERROR_404 }
  }
];

export default routes;
