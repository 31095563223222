import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const ADMIN_USER = {
  getAdminUsers,
  getAdminUser,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  setActiveInactive
};

const URL = {
  ADMIN: 'admin',
  ADMIN_USERS: 'admin-users',
  SET_ACTIVE: 'set-active'
};

function getAdminUsers(data) {
  let requestOptions = {
    params: {
      name: data.name,
      email: data.email,
      username: data.username,
      is_active: data.isActive,
      created_at_from: apiHelper.dateTimeStart(data.createdDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.createdDateRange),
      updated_at_from: apiHelper.dateTimeStart(data.updatedDateRange),
      updated_at_to: apiHelper.dateTimeEnd(data.updatedDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.ADMIN_USERS}`, requestOptions);
}

function getAdminUser(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.ADMIN_USERS}/${id}`);
}

function createAdminUser(data) {
  let requestBody = {
    username: data.username,
    name: data.name,
    email: data.email,
    password: data.password,
    role_id: data.roleId
  };
  return apiService().post(`/${URL.ADMIN}/${URL.ADMIN_USERS}`, requestBody);
}

function updateAdminUser(data, isUpdatePassword) {
  let id = data.id;
  let requestBody = {
    name: data.name,
    username: data.username,
    email: data.email,
    role_id: data.roleId
  };

  if (isUpdatePassword) {
    requestBody.password = data.password;
  }

  return apiService().put(
    `/${URL.ADMIN}/${URL.ADMIN_USERS}/${id}`,
    requestBody
  );
}

function deleteAdminUser(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.ADMIN_USERS}/${id}`);
}

function setActiveInactive(id, data) {
  let requestBody = {
    is_active: data.isActive
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.ADMIN_USERS}/${id}/${URL.SET_ACTIVE}`,
    requestBody
  );
}
