import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const USER = {
  getUsers,
  getUsersPagination,
  getUser,
  updateUserLoginStatus,
  getAccountVerifications,
  getAccountVerificationDetails,
  updateAccountVerification,
  deactivateUserAccount,
  reactivateUserAccount
};

const URL = {
  ADMIN: 'admin',
  USERS: 'users',
  SET_LOGIN_BLOCKED: 'set-login-blocked',
  VERIFICATION: 'verification',
  DEACTIVATE: 'deactivate',
  REACTIVATE: 'reactivate',
  PAGINAT: 'paginat',
};

function getUsers(data) {
  let requestOptions = {
    params: {
      first_name: data.firstName,
      last_name: data.lastName,
      mobile_dial_code: data.mobileDialCode,
      mobile: data.mobile,
      email: data.email,
      role: data.role,
      activated: data.isActivated,
      name: data.displayName,
      deactivated: data.deactivated,
      created_at_from: apiHelper.dateTimeStart(data.registerDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.registerDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}`, requestOptions);
}

function getUsersPagination(data) {
  let requestOptions = {
    params: {
      first_name: data.firstName,
      last_name: data.lastName,
      mobile_dial_code: data.mobileDialCode,
      mobile: data.mobile,
      email: data.email,
      role: data.role,
      activated: data.isActivated,
      name: data.displayName || data.name,
      deactivated: data.deactivated,
      created_at_from: apiHelper.dateTimeStart(data.registerDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.registerDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}/${URL.PAGINAT}`, requestOptions);
}

function getUser(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.USERS}/${id}`);
}

function updateUserLoginStatus(id, data) {
  let requestBody = {
    is_login_blocked: data.isLoginBlocked
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.SET_LOGIN_BLOCKED}`,
    requestBody
  );
}

function getAccountVerifications(data) {
  let requestOptions = {
    params: {
      first_name: data.firstName,
      last_name: data.lastName,
      verification_status: data.verificationStatus,
      role: data.role,
      submitted_at_from: apiHelper.dateTimeStart(
        data.verificationSubmittedDateRange
      ),
      submitted_at_to: apiHelper.dateTimeEnd(
        data.verificationSubmittedDateRange
      ),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${URL.VERIFICATION}`,
    requestOptions
  );
}

function getAccountVerificationDetails(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.VERIFICATION}`
  );
}

function updateAccountVerification(id, data) {
  let requestBody = {
    verification_status: data.verificationStatus,
    verification_remark: data.verificationRemark
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.USERS}/${id}/${URL.VERIFICATION}`,
    requestBody
  );
}

function deactivateUserAccount(id) {
  return apiService().put(`/${URL.ADMIN}/${URL.USERS}/${id}/${URL.DEACTIVATE}`);
}

function reactivateUserAccount(id) {
  return apiService().put(`/${URL.ADMIN}/${URL.USERS}/${id}/${URL.REACTIVATE}`);
}
