import { apiService } from '@/core/services';

export const APP_INFO = {
  getAppInfoList,
  getAppInfo,
  updateAppInfo
};

const URL = {
  ADMIN: 'admin',
  APP_INFO: 'app-info'
};

function getAppInfoList(data) {
  let requestOptions = {
    params: {
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.APP_INFO}`, requestOptions);
}

function getAppInfo(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.APP_INFO}/${id}`);
}

function updateAppInfo(id, data) {
  let requestBody = {
    version: data.version,
    force_update: data.forceUpdate,
    url: data.url,
    message: data.message
  };
  return apiService().put(`/${URL.ADMIN}/${URL.APP_INFO}/${id}`, requestBody);
}
