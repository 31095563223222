import { apiService } from '@/core/services';

export const PLATFORM_INFO = {
  getPlatformInfo,
  updatePlatformInfo,
  uploadFile
};

const URL = {
  ADMIN: 'admin',
  PLATFORM: 'platform',
  UPLOAD: 'upload'
};

function getPlatformInfo() {
  return apiService().get(`/${URL.ADMIN}/${URL.PLATFORM}`);
}

function updatePlatformInfo(data) {
  let requestBody = {
    name: data.name,
    email: data.email,
    phone: data.phone,
    address: data.address,
    instagram: data.instagram,
    facebook: data.facebook,
    whatsapp: data.whatsapp,
    youtube: data.youtube,
    linkedin: data.linkedin,
    tiktok: data.tiktok
  };
  return apiService().put(`/${URL.ADMIN}/${URL.PLATFORM}`, requestBody);
}

function uploadFile(formData) {
  return apiService(null, true, true).post(
    `/${URL.ADMIN}/${URL.PLATFORM}/${URL.UPLOAD}`,
    formData
  );
}
