import { LOOKUP } from '@/core/api';
import { apiHelper, permissionHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'lookup/';

/* Define Action Name */
const A_GET_USER_PERMISSIONS = 'getUserPermissions';
const A_GET_NOTIFICATION = 'getNotification';
const A_GET_PROJECT_TYPES = 'getProjectTypes';

/* Define Reset State Action Name */
const A_INITIAL_USER_PERMISSIONS_STATE = 'initialUserPermissionsState';
const A_INITIAL_NOTIFICATION_STATE = 'initialNotification';
const A_INITIAL_PROJECT_TYPES_STATE = 'initialProjectTypes';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_USER_PERMISSIONS = 'setUserPermissions';
const M_NOTIFICATION = 'setNotification';
const M_PROJECT_TYPES = 'setProjectTypes';

/* Define Reset State Mutation Name */
const M_INITIAL_USER_PERMISSIONS_STATE = 'setInitialUserPermissionsState';
const M_INITIAL_NOTIFICATION = 'setInitialNotificationState';
const M_INITIAL_PROJECT_TYPES = 'setInitialProjectTypesState';

/* Define Export Name */
export const LOOKUP_GET_USER_PERMISSIONS = MODULE_NAME + A_GET_USER_PERMISSIONS;
export const LOOKUP_GET_NOTIFICATION = MODULE_NAME + A_GET_NOTIFICATION;
export const LOOKUP_GET_PROJECT_TYPES = MODULE_NAME + A_GET_PROJECT_TYPES;

/* Define Export Reset State Name */
export const LOOKUP_INITIAL_USER_PERMISSIONS_STATE =
  MODULE_NAME + A_INITIAL_USER_PERMISSIONS_STATE;
export const LOOKUP_INITIAL_NOTIFICATION_STATE =
  MODULE_NAME + A_INITIAL_NOTIFICATION_STATE;
export const LOOKUP_INITIAL_PROJECT_TYPES_STATE =
  MODULE_NAME + A_INITIAL_PROJECT_TYPES_STATE;

/* Define Export Getter Name */

const state = {
  userPermissions: {
    code: 0,
    complete: false,
    data: [],
    message: null
  },
  notification: {
    code: 0,
    complete: false,
    data: {
      accountVerification: 0,
      chat: 0
    },
    message: null
  },
  projectTypes: {
    code: 0,
    complete: false,
    data: [],
    message: null,
  },
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_USER_PERMISSIONS]({ dispatch, commit }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    LOOKUP.getUserPermissions()
      .then((response) => {
        let result = response;
        commit(M_GET_USER_PERMISSIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_USER_PERMISSIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_NOTIFICATION]({ commit }) {
    let result;
    LOOKUP.getNotification()
      .then((response) => {
        result = response;
        commit(M_NOTIFICATION, result);
      })
      .catch((error) => {
        result = error;
        commit(M_NOTIFICATION, result);
      });
  },
  [A_GET_PROJECT_TYPES]({ commit }) {
    let result;
    LOOKUP.getProjectTypes()
      .then((response) => {
        result = response;
        commit(M_PROJECT_TYPES, result);
      })
      .catch((error) => {
        result = error;
        commit(M_PROJECT_TYPES, result);
      });
  },
  [A_INITIAL_USER_PERMISSIONS_STATE]({ commit }) {
    commit(M_INITIAL_USER_PERMISSIONS_STATE);
  },
  [A_INITIAL_NOTIFICATION_STATE]({ commit }) {
    commit(M_INITIAL_NOTIFICATION);
  },
  [A_INITIAL_PROJECT_TYPES_STATE]({ commit }) {
    commit(M_INITIAL_PROJECT_TYPES);
  },
};

// Mutations
const mutations = {
  [M_GET_USER_PERMISSIONS](state, result) {
    if (result.code == 200) {
      state.userPermissions = {
        code: result.code,
        complete: true,
        data: result.data
      };

      permissionHelper.setUserPermissions(result.data);
    } else {
      state.userPermissions = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_NOTIFICATION](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.notification = {
        code: result.code,
        complete: true,
        data: {
          accountVerification: d.account_verification,
          chat: d.chat
        },
        message: null
      };
    } else {
      state.notification = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_PROJECT_TYPES](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.projectTypes = {
        code: result.code,
        complete: true,
        data: d,
        message: null
      };
    } else {
      state.projectTypes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_INITIAL_USER_PERMISSIONS_STATE](state) {
    state.userPermissions = {
      code: 0,
      complete: false,
      data: [],
      message: null
    };
  },
  [M_INITIAL_NOTIFICATION](state) {
    state.notification = {
      code: 0,
      complete: false,
      data: {
        ...state.notification.data
      },
      message: null
    };
  },
  [M_INITIAL_PROJECT_TYPES](state) {
    state.projectTypes = {
      code: 0,
      complete: false,
      data: {
        ...state.projectTypes.data
      },
      message: null
    };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
