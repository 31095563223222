import { apiService } from '@/core/services';

export const BANNER = {
  getBanners,
  createBanner,
  getTargetList,
  getBanner,
  updateBanner,
  deleteBanner
};

const URL = {
  ADMIN: 'admin',
  BANNER: 'banner',
  TARGET: 'target'
};

function getBanners(data) {
  let requestOptions = {
    params: {
      title: data.title,
      show: data.show,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.BANNER}`, requestOptions);
}

function createBanner(data) {
  let formData = new FormData();

  formData.append('type', data.type);
  formData.append('show', data.show);
  formData.append('title', data.title);
  formData.append('body', data.body);
  formData.append('target_id', data.targetId);
  formData.append('text_bg_color', data.textBackgroundColor);
  formData.append('text_color', data.textColor);
  formData.append('image', data.image);

  return apiService().post(`/${URL.ADMIN}/${URL.BANNER}`, formData);
}

function getTargetList(data) {
  let requestOptions = {
    params: {
      type: data.type,
      first_name: data.firstName,
      last_name: data.lastName,
      name: data.name,
      title: data.title,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.BANNER}/${URL.TARGET}`,
    requestOptions
  );
}

function getBanner(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.BANNER}/${id}`);
}

function updateBanner(data, id) {
  let formData = new FormData();

  formData.append('type', data.type);
  formData.append('show', data.show);
  formData.append('title', data.title);
  formData.append('body', data.body);
  formData.append('target_id', data.targetId);
  formData.append('text_bg_color', data.textBackgroundColor);
  formData.append('text_color', data.textColor);
  formData.append('seq', data.sequence);

  if (data.image) {
    formData.append('image', data.image);
  }

  return apiService().post(`/${URL.ADMIN}/${URL.BANNER}/${id}`, formData);
}

function deleteBanner(id) {
  return apiService().delete(`/${URL.ADMIN}/${URL.BANNER}/${id}`);
}
