<template>
  <div class="d-flex flex-column flex-root full-screen">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <!-- end:: Header Mobile -->

    <AppLoader v-if="loaderEnabled" v-bind:logo="loaderLogo"></AppLoader>

    <!-- begin:: App Loading -->
    <AppLoading></AppLoading>
    <!-- end:: App Loading -->

    <!-- begin::Body -->
    <div class="d-flex flex-row flex-column-fluid page">
      <!-- begin:: Aside Left -->
      <KTAside v-if="asideEnabled"></KTAside>
      <!-- end:: Aside Left -->

      <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
        <!-- begin:: Header -->
        <KTHeader :subHeader="subHeader"></KTHeader>
        <!-- end:: Header -->

        <!-- begin:: Content -->
        <div
          id="kt_content"
          class="pb-4 pb-lg-0 py-lg-4 d-flex flex-column flex-column-fluid"
        >
          <!-- begin:: Content Head -->

          <!-- begin:: Content Head -->
          <KTSubheader v-if="subheaderDisplay" :subHeader="subHeader" />
          <!-- end:: Content Head -->

          <!-- begin:: Content Body -->
          <div class="d-flex flex-column-fluid">
            <div class="container-fluid">
              <transition name="fade-in-up">
                <router-view />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KTScrollTop></KTScrollTop>

    <!-- begin:: App Modal Info -->
    <AppModalInfo
      :show="infoModal.show"
      :title="infoModal.title"
      :messages="infoModal.messages"
      :buttons="infoModal.buttons"
    ></AppModalInfo>
    <!-- end:: App Modal Info -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import commonMixin from '@/core/mixins/common.mixin';
  import KTAside from '@/views/layout/aside/Aside.vue';
  import KTHeader from '@/views/layout/header/Header.vue';
  import KTHeaderMobile from '@/views/layout/header/HeaderMobile.vue';
  import HtmlClass from '@/core/services/htmlclass.service';
  import KTSubheader from '@/views/layout/subheader/Subheader.vue';
  import KTScrollTop from '@/views/layout/extras/ScrollTop';
  import AppLoader from '@/views/components/bases/loading/AppLoader.vue';
  import AppLoading from '@/views/components/bases/loading/AppLoading.vue';
  import AppModalInfo from '@/views/components/bases/modal/AppModalInfo.vue';
  import { ERROR_CODE } from '@/core/constants';
  import { jwtHelper, i18nHelper, uiHelper } from '@/core/utils';
  import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME
  } from '@/core/store/htmlclass.module.js';
  import {
    AUTH_ME,
    AUTH_LOGOUT,
    AUTH_REFRESH,
    AUTH_INITIAL_ME_STATE
  } from '@/core/store/auth.module';
  import {
    CONSTANTS_GET_CONSTANTS,
    CONSTANTS_INITIAL_CONSTANTS_STATE
  } from '@/core/store/constants.module';
  import {
    LOOKUP_GET_USER_PERMISSIONS,
    LOOKUP_GET_NOTIFICATION,
    LOOKUP_INITIAL_USER_PERMISSIONS_STATE,
    LOOKUP_INITIAL_NOTIFICATION_STATE
  } from '@/core/store/lookup.module';

  export default {
    name: 'Layout',
    mixins: [commonMixin],
    components: {
      KTAside,
      KTHeader,
      KTHeaderMobile,
      KTSubheader,
      KTScrollTop,
      AppLoader,
      AppLoading,
      AppModalInfo
    },
    data: () => ({
      infoModal: {
        show: false,
        title: '',
        messages: [],
        buttons: []
      },
      checkActivityInterval: null,
      notificationInterval: null
    }),
    computed: {
      ...mapGetters(['subHeader', 'layoutConfig']),

      /**
       * Check if the page loader is enabled
       * @returns {boolean}
       */
      loaderEnabled() {
        return !/false/.test(this.layoutConfig('loader.type'));
      },

      /**
       * Check if container width is fluid
       * @returns {boolean}
       */
      contentFluid() {
        return this.layoutConfig('content.width') === 'fluid';
      },

      /**
       * Page loader logo image using require() function
       * @returns {string}
       */
      loaderLogo() {
        return process.env.BASE_URL + this.layoutConfig('loader.logo');
      },

      /**
       * Check if the left aside menu is enabled
       * @returns {boolean}
       */
      asideEnabled() {
        return !!this.layoutConfig('aside.self.display');
      },

      /**
       * Set the right toolbar display
       * @returns {boolean}
       */
      toolbarDisplay() {
        // return !!this.layoutConfig("toolbar.display");
        return true;
      },

      /**
       * Set the subheader display
       * @returns {boolean}
       */
      subheaderDisplay() {
        return !!this.layoutConfig('subheader.display');
      },
      constantsComplete() {
        return this.$store.state.constants.constants.complete;
      },
      userPermissionsComplete() {
        return this.$store.state.lookup.userPermissions.complete;
      },
      meComplete() {
        return this.$store.state.auth.me.complete;
      },
      notificationComplete() {
        return this.$store.state.lookup.notification.complete;
      }
    },
    watch: {
      constantsComplete() {
        let response = this.$store.state.constants.constants;
        let title = i18nHelper.getMessage('label.getConstants');
        let initialStateAction = CONSTANTS_INITIAL_CONSTANTS_STATE;
        let successAction = () => {};

        if (this.constantsComplete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      userPermissionsComplete() {
        let response = this.$store.state.lookup.userPermissions;
        let title = i18nHelper.getMessage('label.getUserPermissions');
        let initialStateAction = LOOKUP_INITIAL_USER_PERMISSIONS_STATE;
        let successAction = () => {
          uiHelper.openPage('/', '_self');
        };

        if (this.userPermissionsComplete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      meComplete() {
        let response = this.$store.state.auth.me;
        let title = i18nHelper.getMessage('label.getAccountInfo');
        let initialStateAction = AUTH_INITIAL_ME_STATE;
        let successAction = () => {};

        if (this.meComplete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      },
      notificationComplete() {
        let response = this.$store.state.lookup.notification;
        let title = i18nHelper.getMessage('label.getNotification');
        let initialStateAction = LOOKUP_INITIAL_NOTIFICATION_STATE;
        let successAction = () => {};

        if (this.notificationComplete) {
          this.actionReadRecordComplete(
            response,
            title,
            initialStateAction,
            successAction
          );
        }
      }
    },
    created() {
      this.initLayout();
    },
    beforeMount() {
      // show page loading
      this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

      // initialize html element classes
      HtmlClass.init(this.layoutConfig());
    },
    mounted() {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
      }, 1000);
    },
    beforeDestroy() {
      this.clearAllInterval();
    },
    methods: {
      clearAllInterval() {
        clearInterval(this.checkActivityInterval);
        clearInterval(this.notificationInterval);
      },
      setGetNotificationInterval() {
        // Every 30 sec will fire API to get lookup notification data
        this.notificationInterval = setInterval(() => {
          this.getNotification();
        }, 30000);
      },
      setActivityTimeChecker() {
        this.checkActivityInterval = setInterval(() => {
          this.checkUserActivityTime();
        }, 10000);
      },
      checkUserActivityTime() {
        let minute = 60 * 1000;
        let lastActivityTime = jwtHelper.getLastActivityTime();
        let expirationTime = jwtHelper.getSessionExpirationTime();
        let currentTime = new Date().getTime();

        if (expirationTime != 0) {
          if (currentTime > expirationTime - 10 * minute) {
            this.closeInfoModal();
            this.openModalInfoSessionExpired();
          } else {
            if (lastActivityTime > expirationTime - 30 * minute) {
              this.$store.dispatch(AUTH_REFRESH);
            }
          }
        }
      },
      openModalInfoSessionExpired() {
        let isSessionExpired = true;
        let title = i18nHelper.getMessage('label.sessionExpired');
        let messages = [];
        let buttons = [];

        messages.push(i18nHelper.getMessage('message.sessionExpiredMessage'));

        buttons.push({
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            this.closeInfoModal();
            this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired });
          }
        });
        this.openInfoModal(title, messages, buttons);
      },
      forceLogout() {
        let isSessionExpired = true;
        this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired });
      },
      getNotification() {
        this.$store.dispatch(LOOKUP_GET_NOTIFICATION);
      },
      getMe() {
        this.$store.dispatch(AUTH_ME);
      },
      async getConstants() {
        await this.$store.dispatch(CONSTANTS_GET_CONSTANTS);
      },
      getUserPermissions() {
        this.$store.dispatch(LOOKUP_GET_USER_PERMISSIONS);
      },
      openInfoModal(title, messages, buttons, code) {
        this.infoModal = {
          title: title,
          messages: messages,
          buttons: buttons
        };

        //Error Handle by error code
        if ([ERROR_CODE.FORBIDDEN, ERROR_CODE.UNAUTHORIZED].includes(code)) {
          let newButtons = [];
          this.infoModal.title = i18nHelper.getMessage(`errorTitle.${code}`);

          newButtons.push({
            text: i18nHelper.getMessage('label.ok'),
            variant: 'primary',
            action: () => {
              this.closeInfoModal();
              this.forceLogout();
            }
          });

          this.infoModal.buttons = newButtons;
        }

        this.infoModal.show = true;
      },
      closeInfoModal() {
        this.infoModal.show = false;
        this.infoModal.title = '';
        this.infoModal.messages = [];
        this.infoModal.buttons = [];
      },
      initLayout() {
        this.getConstants();
        this.getMe();
        this.getNotification();
        this.setGetNotificationInterval();
        //this.setActivityTimeChecker();
      }
    }
  };
</script>
