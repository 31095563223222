import { apiService } from '@/core/services';

export const PROJECT_TYPES = {
  getProjectTypes,
  getProjectType,
  createProjectType,
  updateProjectType,
  deleteProjectType
};

const URL = {
  ADMIN: 'admin',
  PROJECT_TYPES: 'project-types',
  DELETE: 'delete'
};

function getProjectTypes(data) {
  let requestOptions = {
    params: {
      name: data.name,
      dataset_id: data.datasetId,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.PROJECT_TYPES}`, requestOptions);
}

function getProjectType(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.PROJECT_TYPES}/${id}`);
}

function createProjectType(data) {
  let requestBody = {
    name: data.name,
    dataset_id: data.datasetId
  };

  return apiService().post(`/${URL.ADMIN}/${URL.PROJECT_TYPES}`, requestBody);
}

function updateProjectType(id, data) {
  let requestBody = {
    name: data.name,
    dataset_id: data.datasetId
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.PROJECT_TYPES}/${id}`,
    requestBody
  );
}

function deleteProjectType(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.PROJECT_TYPES}/${id}/${URL.DELETE}`
  );
}
