<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        {{ $t('label.hi') }},
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ firstName }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{ shortName }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5 mt-12 mt-sm-16 mt-lg-0"
      >
        <h3 class="font-weight-bold m-0">{{ $t('label.userProfile') }}</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <b-avatar
              square
              badge
              badge-top
              badge-variant="success"
              badge-offset="-0.5em"
              variant="info"
              size="8em"
              :text="shortName"
              class="quick-user-avatar"
            ></b-avatar>
          </div>
          <div class="d-flex flex-column">
            <div
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ me.name }}
            </div>
            <div class="navi mt-2">
              <div class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="/media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span
                    class="navi-text text-muted text-hover-primary text-truncate max-w-180px"
                  >
                    {{ me.email }}
                  </span>
                </span>
              </div>
            </div>
            <b-button
              block
              variant="light-primary"
              class="btn btn-bold w-150px w-sm-auto"
              @click="onLogout"
            >
              {{ $t('label.signOut') }}
            </b-button>
          </div>
        </div>
        <!--end::Header-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<script>
  import { AUTH_LOGOUT } from '@/core/store/auth.module';
  import KTLayoutQuickUser from '@/assets/js/layout/extended/quick-user.js';
  import KTOffcanvas from '@/assets/js/components/offcanvas.js';

  export default {
    name: 'KTQuickUser',
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      },
      shortName() {
        let name = this.$store.state.auth.me.data.name;
        let nameArray = name ? name.split(' ') : '';
        let shortName = '';

        if (name) {
          if (nameArray.length > 1) {
            shortName = nameArray[0].charAt(0) + nameArray[1].charAt(0);
          } else {
            shortName = nameArray[0].charAt(0);
          }
        }

        return shortName;
      },
      firstName() {
        let name = this.$store.state.auth.me.data.name;
        let firstName = name ? name.split(' ')[0] : '';

        return firstName;
      }
    },
    mounted() {
      // Init Quick User Panel
      KTLayoutQuickUser.init(this.$refs['kt_quick_user']);
    },
    methods: {
      onLogout() {
        let isSessionExpired = false;
        this.logout(isSessionExpired);
      },
      closeOffcanvas() {
        new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
      },
      logout(isSessionExpired) {
        this.$store.dispatch(AUTH_LOGOUT, { isSessionExpired });
      }
    }
  };
</script>

<style lang="scss">
  #kt_quick_user {
    overflow: hidden;
  }

  .quick-user-avatar {
    .b-avatar-badge {
      font-size: calc(0.8em) !important;
    }
  }
</style>
