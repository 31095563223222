const LOADING_PLATFORM = {
  FTA: 1,
  ONLINE: 2,
  TV: 3,
  OOH: 4,
  ATL: 5,
  OTHER: 6
};

export { LOADING_PLATFORM };
