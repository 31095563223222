import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import constantsDesc from '@/core/filters/constants-desc.filter';
import { LOADING_PLATFORM } from '@/core/constants/enums';
import { CONSTANTS_CONSTANTS } from '@/core/store/constants.module';
import { CASTING_JOBS } from '@/core/api';
import { apiHelper, uiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'castingJobs/';

/* Define Action Name */
const A_GET_CASTING_JOBS = 'getCastingJobs';
const A_GET_CASTING_JOBS_PAGINATION = 'getCastingJobsPagination';
const A_GET_CASTING_JOB = 'getCastingJob';
const A_GET_CASTING_JOB_APPLICATIONS = 'getCastingJobApplications';
const A_EXPORT_CASTING_JOBS_TO_EXCEL = 'exportCastingJobsToExcel';

/* Define Reset State Action Name */
const A_INITIAL_GET_CASTING_JOBS_STATE = 'initialGetCastingJobsState';
const A_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE =
  'initialGetCastingJobsPaginationState';
const A_INITIAL_GET_CASTING_JOB_STATE = 'initialGetCastingJobState';
const A_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE =
  'initialGetCastingJobApplicationsState';
const A_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE =
  'initialExportCastingJobsToExcelState';
const A_UPDATE_CASTING_JOBS_VISIBILITY = 'updateCastingJobsVisibility';
const A_UPDATE_CASTING_JOBS_APPLICATION_FEE = 'updateCastingJobsApplicationFee';
const A_UPDATE_CASTING_JOBS_UNION_NO = 'updateCastingJobsUnionNo';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CASTING_JOBS = 'setGetCastingJobs';
const M_GET_CASTING_JOBS_PAGINATION = 'setGetCastingJobsPagination';
const M_GET_CASTING_JOB = 'setGetCastingJob';
const M_GET_CASTING_JOB_APPLICATIONS = 'setGetCastingJobApplications';
const M_EXPORT_CASTING_JOBS_TO_EXCEL = 'setExportCastingJobsToExcel';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CASTING_JOBS_STATE = 'setInitialGetCastingJobsState';
const M_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE =
  'setInitialGetCastingJobsPaginationState';
const M_INITIAL_GET_CASTING_JOB_STATE = 'setInitialGetCastingJobState';
const M_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE =
  'setInitialGetCastingJobApplicationsState';
const M_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE =
  'setInitialExportCastingJobsToExcelState';

/* Define Export Name */
export const CASTING_JOBS_GET_CASTING_JOBS = MODULE_NAME + A_GET_CASTING_JOBS;
export const CASTING_JOBS_GET_CASTING_JOBS_PAGINATION =
  MODULE_NAME + A_GET_CASTING_JOBS_PAGINATION;
export const CASTING_JOBS_GET_CASTING_JOB = MODULE_NAME + A_GET_CASTING_JOB;
export const CASTING_JOBS_GET_CASTING_JOB_APPLICATIONS =
  MODULE_NAME + A_GET_CASTING_JOB_APPLICATIONS;
export const CASTING_JOBS_EXPORT_CASTING_JOBS_TO_EXCEL =
  MODULE_NAME + A_EXPORT_CASTING_JOBS_TO_EXCEL;
export const CASTING_JOBS_UPDATE_CASTING_JOBS_VISIBILITY =
  MODULE_NAME + A_UPDATE_CASTING_JOBS_VISIBILITY;
export const CASTING_JOBS_UPDATE_CASTING_JOBS_APPLICATION_FEE =
  MODULE_NAME + A_UPDATE_CASTING_JOBS_APPLICATION_FEE;
export const CASTING_JOBS_UPDATE_CASTING_JOBS_UNION_NO =
  MODULE_NAME + A_UPDATE_CASTING_JOBS_UNION_NO;

/* Define Reset State Name */
export const CASTING_JOBS_INITIAL_GET_CASTING_JOBS_STATE =
  MODULE_NAME + A_INITIAL_GET_CASTING_JOBS_STATE;
export const CASTING_JOBS_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE =
  MODULE_NAME + A_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE;
export const CASTING_JOBS_INITIAL_GET_CASTING_JOB_STATE =
  MODULE_NAME + A_INITIAL_GET_CASTING_JOB_STATE;
export const CASTING_JOBS_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE =
  MODULE_NAME + A_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE;
export const CASTING_JOBS_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE =
  MODULE_NAME + A_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE;

const state = {
  castingJobs: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  castingJobsPagination: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  castingJob: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  castingJobApplications: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  exportCastingJobsToExcel: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_CASTING_JOBS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });

    CASTING_JOBS.getCastingJobs(data)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOBS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CASTING_JOBS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CASTING_JOBS_PAGINATION]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.getCastingJobsPagination(data)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOBS_PAGINATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CASTING_JOBS_PAGINATION, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CASTING_JOB]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.getCastingJob(id)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CASTING_JOB_APPLICATIONS]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.getCastingJobApplications(id)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB_APPLICATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CASTING_JOB_APPLICATIONS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_EXPORT_CASTING_JOBS_TO_EXCEL]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.exportCastingJobsToExcel(data)
      .then((response) => {
        let result = response;
        commit(M_EXPORT_CASTING_JOBS_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_EXPORT_CASTING_JOBS_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_CASTING_JOBS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CASTING_JOBS_STATE);
  },
  [A_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE]({ commit }) {
    commit(M_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE);
  },
  [A_INITIAL_GET_CASTING_JOB_STATE]({ commit }) {
    commit(M_INITIAL_GET_CASTING_JOB_STATE);
  },
  [A_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE);
  },
  [A_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE]({ commit }) {
    commit(M_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE);
  },
  [A_UPDATE_CASTING_JOBS_VISIBILITY]({ dispatch, commit }, payload) {
    let { id, data } = payload;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.updateCastingJobVisibility(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_CASTING_JOBS_APPLICATION_FEE]({ dispatch, commit }, payload) {
    let { id, data } = payload;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.updateCastingJobApplicationFee(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_CASTING_JOBS_UNION_NO]({ dispatch, commit }, payload) {
    let { id, data } = payload;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CASTING_JOBS.updateCastingJobUnionNo(id, data)
      .then((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((response) => {
        let result = response;
        commit(M_GET_CASTING_JOB, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  }
};

// Mutations
const mutations = {
  [M_GET_CASTING_JOBS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.castingJobs = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            title: x.title,
            projectTypeId: constantsDesc(
              x.project_type_id,
              'projectTypes',
              'id',
              'name'
            ),
            postOwner: x.post_owner,
            status: x.status,
            visibility: x.visibility !== 'disabled' ? 1 : 0,
            unionNo: x?.union_no == 0 ? false : true,
            createdAt: dateTimeFormat(x.created_at),
            createdATimeStampt: new Date(x.created_at).getTime(),
            closeDate: dateTimeFormat(x.close_date),
            closeDateTimeStamp: new Date(x.close_date).getTime(),
            liveDate: dateTimeFormat(x.live_date),
            liveDateTimeStamp: new Date(x.live_date).getTime(),
            totalApplicants: x.total_applicants,
            totalAcceptedApplicants: x.total_accepted_applicants
          };
        }),
        message: null
      };
    } else {
      state.castingJobs = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_CASTING_JOBS_PAGINATION](state, result) {
    if (result.code === 200) {
      let i = result.data.items;
      let p = result.data.pagination;

      state.castingJobsPagination = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            title: x.title,
            projectTypeId: constantsDesc(
              x.project_type_id,
              'projectTypes',
              'id',
              'name'
            ),
            postOwner: x.post_owner,
            status: x.status,
            createdAt: dateTimeFormat(x.created_at),
            createdATimeStampt: new Date(x.created_at).getTime(),
            closeDate: dateTimeFormat(x.close_date),
            closeDateTimeStamp: new Date(x.close_date).getTime(),
            liveDate: dateTimeFormat(x.live_date),
            liveDateTimeStamp: new Date(x.live_date).getTime(),
            totalApplicants: x.total_applicants,
            totalAcceptedApplicants: x.total_accepted_applicants
          };
        }),
        pagination: {
          currentPage: p.current_page,
          lastPage: p.last_page,
          perPage: p.per_page,
          total: p.total
        },
        message: null
      };
    } else {
      state.castingJobsPagination = {
        code: result.code,
        complete: true,
        data: null,
        pagination: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_CASTING_JOB](state, result) {
    let vm = this;

    if (result.code === 200) {
      let d = result.data;

      state.castingJob = {
        code: result.code,
        complete: true,
        data: {
          applicationFees: d?.application_fees,
          id: d.job_id,
          jobId: d.job_id,
          producerUid: d.producer_uid,
          producer: d.producer,
          title: d.title,
          jobType: d.job_type,
          jobCoverFile: d.job_cover_file
            ? {
                id: d.job_cover_file.id,
                mediaPath: d.job_cover_file.media_path,
                mediaType: uiHelper.getFileExtension(
                  d.job_cover_file.media_path
                )
              }
            : null,
          jobCountryId: constantsDesc(
            d.job_country_id,
            'allCountry',
            'id',
            'name'
          ),
          applicantCountryId: constantsDesc(
            d.applicant_country_id,
            'allCountry',
            'id',
            'name'
          ),
          projectType: constantsDesc(
            d.project_type_id,
            'projectTypes',
            'id',
            'name'
          ),
          projectTypeId: d.project_type_id,
          closeDate: dateTimeFormat(d.close_date),
          liveDate: dateTimeFormat(d.live_date),
          isWfh: d.is_wfh,
          isPaid: d.is_paid,
          visibility: d.visibility == 'disabled' ? false : true,
          unionNo: d?.union_no == 0 ? false : true,
          ...castingExtraInformation(d, vm),
          castingRoles: d.casting_roles ? castingRoles(d.casting_roles) : null
        },
        message: null
      };
    } else {
      state.castingJob = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_CASTING_JOB_APPLICATIONS](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.castingJobApplications = {
        code: result.code,
        complete: true,
        data: {
          pending: application(d.pending),
          maybe: application(d.maybe),
          accepted: application(d.accepted),
          declined: application(d.declined),
          cancelled: application(d.cancelled)
        },
        message: null
      };
    } else {
      state.castingJobApplications = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_EXPORT_CASTING_JOBS_TO_EXCEL](state, result) {
    state.exportCastingJobsToExcel = {
      complete: true,
      data: result
    };
  },
  [M_INITIAL_GET_CASTING_JOBS_STATE](state) {
    state.castingJobs = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CASTING_JOBS_PAGINATION_STATE](state) {
    state.castingJobsPagination = {
      code: 0,
      complete: false,
      data: null,
      pagination: null,
      message: null
    };
  },
  [M_INITIAL_GET_CASTING_JOB_STATE](state) {
    state.castingJob = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CASTING_JOB_APPLICATIONS_STATE](state) {
    state.castingJobApplications = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_EXPORT_CASTING_JOBS_TO_EXCEL_STATE](state) {
    state.castingJobApplications = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

var castingExtraInformation = (data, vm) => {
  let constants = vm.getters[CONSTANTS_CONSTANTS];
  let d = data;
  let information;
  let dataSetId = constants.projectTypes.find(
    (x) => x.id == d.project_type_id
  ).dataset_id;

  if (dataSetId == 1) {
    information = {
      synopsis: d.synopsis,
      productionPeriodFrom: dateTimeFormat(d.production_period_from),
      productionPeriodTo: dateTimeFormat(d.production_period_to),
      shootingDays: d.shooting_days,
      hoursPerDay: [null, undefined, '0', ''].includes(d.hours_per_day)
        ? '-'
        : d.hours_per_day,
      videoDue: dateTimeFormat(d.video_due)
    };
  } else if (dataSetId == 2) {
    information = {
      synopsis: d.synopsis,
      productionPeriodFrom: dateTimeFormat(d.production_period_from),
      productionPeriodTo: dateTimeFormat(d.production_period_to),
      loadingPeriod: constantsDesc(d.loading_period, 'loadingPeriod'),
      loadingPlatformId: d.loading_platform,
      loadingPlatform: d.loading_platform
        ? d.loading_platform.map((y) => {
            return constantsDesc(y, 'loadingPlatform');
          })
        : null,
      loadingPlatformOthers: d.loading_platform_others
        ? d.loading_platform_others
        : null,
      loadingPlatformAll: d.loading_platform
        ? d.loading_platform
            .map((y) => {
              if (y == LOADING_PLATFORM.OTHER) {
                return (
                  constantsDesc(y, 'loadingPlatform') +
                  `(${d.loading_platform_others})`
                );
              } else {
                return constantsDesc(y, 'loadingPlatform');
              }
            })
            .join(', ')
        : null,
      territory: constantsDesc(d.territory, 'allCountry', 'id', 'name'),
      shootingDays: d.shooting_days,
      hoursPerDay: [null, undefined, '0', ''].includes(d.hours_per_day)
        ? '-'
        : d.hours_per_day,
      videoDue: dateTimeFormat(d.video_due)
    };
  } else if (dataSetId == 3) {
    information = {
      synopsis: d.synopsis,
      objective: d.objective,
      targetAudience: d.target_audience,
      castingScriptFile: d.casting_script_file
        ? {
            id: d.casting_script_file.id,
            mediaPath: d.casting_script_file.media_path
          }
        : null,
      productionPeriodFrom: dateTimeFormat(d.production_period_from),
      productionPeriodTo: dateTimeFormat(d.production_period_to),
      shootingDays: d.shooting_days,
      campaignDuration: d.campaign_duration,
      hoursPerDay: [null, undefined, '0', ''].includes(d.hours_per_day)
        ? '-'
        : d.hours_per_day,
      videoDue: dateTimeFormat(d.video_due)
    };
  } else if (dataSetId == 4) {
    information = {
      productionPeriodFrom: dateTimeFormat(d.production_period_from),
      productionPeriodTo: dateTimeFormat(d.production_period_to),
      shootingDays: d.shooting_days,
      hoursPerDay: [null, undefined, '0', ''].includes(d.hours_per_day)
        ? '-'
        : d.hours_per_day,
      loadingPeriod: d.loading_period
        ? constantsDesc(d.loading_period, 'loadingPeriod')
        : null,
      loadingPlatform: d.loading_platform
        ? d.loading_platform.map((y) => {
            return constantsDesc(y, 'loadingPlatform');
          })
        : null,
      loadingPlatformOthers: d.loading_platform_others
        ? d.loading_platform_others
        : null,
      loadingPlatformAll: d.loading_platform
        ? d.loading_platform
            .map((y) => {
              if (y == LOADING_PLATFORM.OTHER) {
                return (
                  constantsDesc(y, 'loadingPlatform') +
                  `(${d.loading_platform_others})`
                );
              } else {
                return constantsDesc(y, 'loadingPlatform');
              }
            })
            .join(', ')
        : null,
      territory: d.territory
        ? constantsDesc(d.territory, 'allCountry', 'id', 'name')
        : null,
      gender: d.gender,
      genderOther: d.gender_other,
      fees: d.fees,
      castingScriptFile: d.casting_script_file
        ? {
            id: d.casting_script_file.id,
            mediaPath: d.casting_script_file.media_path
          }
        : null,
      videoDue: dateTimeFormat(d.video_due),
      openingNumber: d.opening_number
    };
  }

  return information;
};

var castingRoles = (data) => {
  return data.map((x, index) => {
    return {
      no: index + 1,
      id: x.id,
      character: x.character,
      characterWriteUp: x.character_write_up,
      castingScriptFile: x.casting_script_file
        ? {
            id: x.casting_script_file.id,
            mediaPath: x.casting_script_file.media_path
          }
        : null,
      age: constantsDesc(x.age, 'age'),
      ageRange: x.age_range,
      race: constantsDesc(x.race, 'race'),
      languagesSpoken: x.languages_spoken
        ? x.languages_spoken
            .map((y) => {
              return constantsDesc(y, 'languageSpoken');
            })
            .join(', ')
        : null,
      shootingDays: x.shooting_days,
      height: constantsDesc(x.height, 'height'),
      skills: x.skills,
      location: x.location
        ? x.location
            .map((y) => {
              return constantsDesc(y, 'allCountry', 'id', 'name');
            })
            .join(', ')
        : null,
      gender: x.gender,
      genderOther: x.gender_other,
      fees: x.fees,
      openingNumber: x.opening_number,
      totalApplicants: x.total_applicants,
      totalPendingApplicants: x.total_pending_applicants,
      totalMaybeApplicants: x.total_maybe_applicants,
      totalAcceptedApplicants: x.total_accepted_applicants,
      totalDeclinedApplicants: x.total_declined_applicants,
      totalCancelledApplicants: x.total_cancelled_applicants
    };
  });
};

var application = (data) => {
  return data.map((x, index) => {
    return {
      no: index + 1,
      castingRoleId: x.casting_role_id,
      castingRoleCharacter: x.casting_role_character,
      castingRoleCharacterWrite_up: x.casting_role_character_write_up,
      talentUid: x.talent_uid,
      talentFirstName: x.talent_first_name,
      talentLastName: x.talent_last_name,
      talentEmail: x.talent_email,
      talentMobileNum: x.talent_mobile_dial_code + x.talent_mobile,
      video: x.video,
      videoStatus: x.video_status,
      videoStatusRemark: x.video_status_remark,
      statusRemark: x.status_remark,
      talentFullName: {
        id: x.talent_uid,
        name: `${x.talent_first_name} ${x.talent_last_name}`
      }
    };
  });
};
