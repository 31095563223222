<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-center justify-content-between"
    >
      <div
        class="text-uppercase font-weight-bold font-size-base text-dark d-none d-lg-block"
      >
        {{
          subHeader.breadcrumbs[subHeader.breadcrumbs.length - 1]
            ? subHeader.breadcrumbs[subHeader.breadcrumbs.length - 1].title
            : 'Page Title'
        }}
      </div>
      <KTTopbar></KTTopbar>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
  import { mapGetters } from 'vuex';
  import KTTopbar from '@/views/layout/header/Topbar.vue';
  import KTLayoutHeader from '@/assets/js/layout/base/header.js';
  import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';

  export default {
    name: 'KTHeader',
    components: {
      KTTopbar
    },
    props: {
      subHeader: Object
    },
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init('kt_header', 'kt_header_mobile');

      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs['kt_header_menu'],
        this.$refs['kt_header_menu_wrapper']
      );

      const headerRef = this.$refs['kt_header'];

      headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
        item.addEventListener('click', () => {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
    },
    computed: {
      ...mapGetters(['layoutConfig', 'getClasses']),

      /**
       * Check if the header menu is enabled
       * @returns {boolean}
       */

      /**
       * Get extra classes for header based on the options
       * @returns {null|*}
       */
      headerClasses() {
        const classes = this.getClasses('header');
        if (typeof classes !== 'undefined') {
          return classes.join(' ');
        }
        return null;
      },

      /**
       * Get extra classes for header menu based on the options
       * @returns {null|*}
       */
      headerMenuClasses() {
        const classes = this.getClasses('header_menu');
        if (typeof classes !== 'undefined') {
          return classes.join(' ');
        }
        return null;
      }
    }
  };
</script>
