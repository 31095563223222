const MediaExtensionType = {
    JPG: 1,
    JPEG: 2,
    PNG: 3,
    PDF: 4,
    DOC: 5,
    DOCX: 6,
    PPTX: 7 
  };
  
  export { MediaExtensionType };
  