import { BANNER } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'banner/';

/* Define Action Name */
const A_GET_BANNERS = 'getBanners';
const A_CREATE_BANNER = 'createBanner';
const A_GET_TARGET_LIST = 'getTargetList';
const A_GET_BANNER = 'getBanner';
const A_UPDATE_BANNER = 'updateBanner';
const A_DELETE_BANNER = 'deleteBanner';

/* Define Reset State Action Name */
const A_INITIAL_GET_BANNERS_STATE = 'initialGetBannersState';
const A_INITIAL_CREATE_BANNER_STATE = 'initialCreateBannerState';
const A_INITIAL_GET_TARGET_LIST_STATE = 'initialGetTargetListState';
const A_INITIAL_GET_BANNER_STATE = 'initialGetBannerState';
const A_INITIAL_UPDATE_BANNER_STATE = 'initialUpdateBannerState';
const A_INITIAL_DELETE_BANNER_STATE = 'initialDeleteBannerState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_BANNERS = 'setGetBanners';
const M_CREATE_BANNER = 'setCreateBanner';
const M_GET_TARGET_LIST = 'setGetTargetList';
const M_GET_BANNER = 'setGetBanner';
const M_UPDATE_BANNER = 'setUpdateBanner';
const M_DELETE_BANNER = 'setDeleteBanner';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_BANNERS_STATE = 'setInitialGetBannersState';
const M_INITIAL_CREATE_BANNER_STATE = 'setInitialCreateBannerState';
const M_INITIAL_GET_TARGET_LIST_STATE = 'setInitialGetTargetListState';
const M_INITIAL_GET_BANNER_STATE = 'setInitialGetBannerState';
const M_INITIAL_UPDATE_BANNER_STATE = 'setInitialUpdateBannerState';
const M_INITIAL_DELETE_BANNER_STATE = 'setInitialDeleteBannerState';

/* Define Export Name */
export const BANNER_GET_BANNERS = MODULE_NAME + A_GET_BANNERS;
export const BANNER_CREATE_BANNER = MODULE_NAME + A_CREATE_BANNER;
export const BANNER_GET_TARGET_LIST = MODULE_NAME + A_GET_TARGET_LIST;
export const BANNER_GET_BANNER = MODULE_NAME + A_GET_BANNER;
export const BANNER_UPDATE_BANNER = MODULE_NAME + A_UPDATE_BANNER;
export const BANNER_DELETE_BANNER = MODULE_NAME + A_DELETE_BANNER;

/* Define Reset State Name */
export const BANNER_INITIAL_GET_BANNERS_STATE =
  MODULE_NAME + A_INITIAL_GET_BANNERS_STATE;
export const BANNER_INITIAL_CREATE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_CREATE_BANNER_STATE;
export const BANNER_INITIAL_GET_TARGET_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_TARGET_LIST_STATE;
export const BANNER_INITIAL_GET_BANNER_STATE =
  MODULE_NAME + A_INITIAL_GET_BANNER_STATE;
export const BANNER_INITIAL_UPDATE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_BANNER_STATE;
export const BANNER_INITIAL_DELETE_BANNER_STATE =
  MODULE_NAME + A_INITIAL_DELETE_BANNER_STATE;

const state = {
  banners: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  createBanner: {
    code: 0,
    complete: false,
    message: null
  },
  targetList: {
    code: 0,
    complete: false,
    data: null,
    message: null,

  },
  banner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateBanner: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteBanner: {
    code: 0,
    complete: false,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_BANNERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.getBanners(data)
      .then((response) => {
        let result = response;
        commit(M_GET_BANNERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_BANNERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_BANNER]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.createBanner(data)
      .then((response) => {
        let result = response;
        commit(M_CREATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_TARGET_LIST]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.getTargetList(data)
      .then((response) => {
        let result = response;
        commit(M_GET_TARGET_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_TARGET_LIST, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_BANNER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.getBanner(id)
      .then((response) => {
        let result = response;
        commit(M_GET_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_BANNER]({ dispatch, commit }, { data, id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.updateBanner(data, id)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_BANNER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    BANNER.deleteBanner(id)
      .then((response) => {
        let result = response;
        commit(M_DELETE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_BANNER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_BANNERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_BANNERS_STATE);
  },
  [A_INITIAL_GET_TARGET_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_TARGET_LIST_STATE);
  },
  [A_INITIAL_CREATE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_BANNER_STATE);
  },
  [A_INITIAL_GET_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_GET_BANNER_STATE);
  },
  [A_INITIAL_UPDATE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_BANNER_STATE);
  },
  [A_INITIAL_DELETE_BANNER_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_BANNER_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_BANNERS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.banners = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            viewFor: x.view_for,
            type: x.type,
            target: x.target
              ? {
                  id: x.target.id,
                  role: x.target.role,
                  name: x.target.name,
                  firstName: x.target.first_name,
                  lastName: x.target.last_name
                }
              : null,
            title: x.title,
            body: x.body,
            seq: x.seq,
            textBgColor: x.text_bg_color,
            textColor: x.text_color,
            image: x.image
              ? {
                  id: x.image.id,
                  mediaPath: x.image.media_path
                }
              : null,
            show: Number(x.show),
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            updatedAt: dateTimeFormat(x.updated_at),
            updatedAtTimeStamp: new Date(x.updated_at).getTime()
          };
        }),
      };
    } else {
      state.banners = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_BANNER](state, result) {
    state.createBanner = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_GET_TARGET_LIST](state, result) {
    if (result.code === 200) {
      let i = result.data.items;
      let p = result.data.pagination;

      state.targetList = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            firstName: x.first_name,
            lastName: x.last_name,
            fullName: x.fname,
            displayName: x.display_name,
            title: x.title ? x.title : null
          };
        }),
        pagination: {
          currentPage: p.current_page,
          lastPage: p.last_page,
          perPage: p.per_page,
          total: p.total
        }
      };
    } else {
      state.targetList = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_BANNER](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.banner = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          viewFor: d.view_for,
          type: d.type,
          target: d.target
            ? {
                id: d.target.id,
                role: d.target.role,
                name: d.target.name,
                firstName: d.target.first_name,
                lastName: d.target.last_name,
                title: d.target.title
              }
            : null,
          title: d.title,
          body: d.body,
          seq: d.seq,
          textBgColor: d.text_bg_color,
          textColor: d.text_color,
          image: d.image
            ? {
                id: d.image.id,
                mediaPath: d.image.media_path
              }
            : null,
          show: d.show,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        }
      };
    } else {
      state.banner = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_BANNER](state, result) {
    state.updateBanner = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_BANNER](state, result) {
    state.deleteBanner = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_BANNERS_STATE](state) {
    state.banners = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_CREATE_BANNER_STATE](state) {
    state.createBanner = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_GET_TARGET_LIST_STATE](state) {
    state.targetList = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_BANNER_STATE](state) {
    state.banner = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_BANNER_STATE](state) {
    state.updateBanner = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_DELETE_BANNER_STATE](state) {
    state.deleteBanner = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
