import 'viewerjs/dist/viewer.css';
import VueViewer from 'v-viewer';
import Vue from 'vue';

Vue.use(VueViewer);

/*
Plugin Reference
https://mirari.cc/v-viewer/
https://fengyuanchen.github.io/viewerjs/
*/
