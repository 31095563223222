import constantsDesc from '@/core/filters/constants-desc.filter';
import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import { ADVERTISEMENT } from '@/core/api';
import { apiHelper, i18nHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'advertisement/';

/* Define Action Name */
const A_GET_ADVERTISEMENTS = 'getAdvertisements';
const A_CREATE_ADVERTISEMENT = 'createAdvertisement';
const A_GET_ADVERTISEMENT = 'getAdvertisement';
const A_UPDATE_ADVERTISEMENT = 'updateAdvertisement';
const A_DELETE_ADVERTISEMENT = 'deleteAdvertisement';

/* Define Reset State Action Name */
const A_INITIAL_GET_ADVERTISEMENTS_STATE = 'initialGetAdvertisementsState';
const A_INITIAL_CREATE_ADVERTISEMENT_STATE = 'initialCreateAdvertisementState';
const A_INITIAL_GET_ADVERTISEMENT_STATE = 'initialGetAdvertisementState';
const A_INITIAL_UPDATE_ADVERTISEMENT_STATE = 'initialUpdateAdvertisementState';
const A_INITIAL_DELETE_ADVERTISEMENT_STATE = 'initialDeleteAdvertisementState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_ADVERTISEMENTS = 'setGetAdvertisements';
const M_CREATE_ADVERTISEMENT = 'setCreateAdvertisement';
const M_GET_ADVERTISEMENT = 'setGetAdvertisement';
const M_UPDATE_ADVERTISEMENT = 'setUpdateAdvertisement';
const M_DELETE_ADVERTISEMENT = 'setDeleteAdvertisement';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_ADVERTISEMENTS_STATE = 'setInitialGetAdvertisementsState';
const M_INITIAL_CREATE_ADVERTISEMENT_STATE =
  'setInitialCreateAdvertisementState';
const M_INITIAL_GET_ADVERTISEMENT_STATE = 'setInitialGetAdvertisementState';
const M_INITIAL_UPDATE_ADVERTISEMENT_STATE =
  'setInitialUpdateAdvertisementState';
const M_INITIAL_DELETE_ADVERTISEMENT_STATE =
  'setInitialDeleteAdvertisementState';

/* Define Export Name */
export const ADVERTISEMENT_GET_ADVERTISEMENTS =
  MODULE_NAME + A_GET_ADVERTISEMENTS;
export const ADVERTISEMENT_CREATE_ADVERTISEMENT =
  MODULE_NAME + A_CREATE_ADVERTISEMENT;
export const ADVERTISEMENT_GET_ADVERTISEMENT =
  MODULE_NAME + A_GET_ADVERTISEMENT;
export const ADVERTISEMENT_UPDATE_ADVERTISEMENT =
  MODULE_NAME + A_UPDATE_ADVERTISEMENT;
export const ADVERTISEMENT_DELETE_ADVERTISEMENT =
  MODULE_NAME + A_DELETE_ADVERTISEMENT;

/* Define Reset State Name */
export const ADVERTISEMENT_INITIAL_GET_ADVERTISEMENTS_STATE =
  MODULE_NAME + A_INITIAL_GET_ADVERTISEMENTS_STATE;
export const ADVERTISEMENT_INITIAL_CREATE_ADVERTISEMENT_STATE =
  MODULE_NAME + A_INITIAL_CREATE_ADVERTISEMENT_STATE;
export const ADVERTISEMENT_INITIAL_GET_ADVERTISEMENT_STATE =
  MODULE_NAME + A_INITIAL_GET_ADVERTISEMENT_STATE;
export const ADVERTISEMENT_INITIAL_UPDATE_ADVERTISEMENT_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ADVERTISEMENT_STATE;
export const ADVERTISEMENT_INITIAL_DELETE_ADVERTISEMENT_STATE =
  MODULE_NAME + A_INITIAL_DELETE_ADVERTISEMENT_STATE;

const state = {
  advertisements: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  createAdvertisement: {
    code: 0,
    complete: false,
    message: null
  },
  advertisement: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAdvertisement: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteAdvertisement: {
    code: 0,
    complete: false,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_ADVERTISEMENTS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADVERTISEMENT.getAdvertisements(data)
      .then((response) => {
        let result = response;
        commit(M_GET_ADVERTISEMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ADVERTISEMENTS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_ADVERTISEMENT]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADVERTISEMENT.createAdvertisement(data)
      .then((response) => {
        let result = response;
        commit(M_CREATE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ADVERTISEMENT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADVERTISEMENT.getAdvertisement(id)
      .then((response) => {
        let result = response;
        commit(M_GET_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ADVERTISEMENT]({ dispatch, commit }, { data, id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADVERTISEMENT.updateAdvertisement(data, id)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_ADVERTISEMENT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADVERTISEMENT.deleteAdvertisement(id)
      .then((response) => {
        let result = response;
        commit(M_DELETE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_ADVERTISEMENT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_ADVERTISEMENTS_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADVERTISEMENTS_STATE);
  },
  [A_INITIAL_CREATE_ADVERTISEMENT_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_ADVERTISEMENT_STATE);
  },
  [A_INITIAL_GET_ADVERTISEMENT_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADVERTISEMENT_STATE);
  },
  [A_INITIAL_UPDATE_ADVERTISEMENT_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ADVERTISEMENT_STATE);
  },
  [A_INITIAL_DELETE_ADVERTISEMENT_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_ADVERTISEMENT_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_ADVERTISEMENTS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.advertisements = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            action: i18nHelper.getMessage(
              `enumAdvertisementAction.${x.action}`
            ),
            url: x.url,
            actionable: {
              id: x.actionable?.id,
              role: x.actionable?.role,
              name: x.actionable?.name,
              firstName: x.actionable?.first_name,
              lastName: x.actionable?.last_name
            },
            title: x.title,
            thumbnail: {
              id: x.thumbnail?.id,
              mediaPath: x.thumbnail?.media_path
            },
            startDate: dateTimeFormat(x.start_date),
            startDateTimeStamp: new Date(x.start_date).getTime(),
            endDate: dateTimeFormat(x.end_date),
            endDateTimeStamp: new Date(x.end_date).getTime(),
            position: constantsDesc(x.position, 'advertisementPosition'),
            gap: x.gap,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            updatedAt: dateTimeFormat(x.updated_at),
            updated_atTimeStamp: new Date(x.updated_at).getTime(),
          };
        }),
      };
    } else {
      state.advertisements = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_ADVERTISEMENT](state, result) {
    state.createAdvertisement = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_GET_ADVERTISEMENT](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.advertisement = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          action: d.action,
          url: d.url,
          actionable: {
            id: d.actionable?.id,
            role: d.actionable?.role,
            name: d.actionable?.name,
            firstName: d.actionable?.first_name,
            lastName: d.actionable?.last_name,
            title: d.actionable?.title
          },
          title: d.title,
          thumbnail: {
            id: d.thumbnail?.id,
            mediaPath: d.thumbnail?.media_path
          },
          startDate: dateTimeFormat(d.start_date),
          endDate: dateTimeFormat(d.end_date),
          position: d.position,
          gap: d.gap,
          createdAt: dateTimeFormat(d.created_at),
          updatedAt: dateTimeFormat(d.updated_at)
        }
      };
    } else {
      state.advertisement = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_ADVERTISEMENT](state, result) {
    state.updateAdvertisement = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_ADVERTISEMENT](state, result) {
    state.deleteAdvertisement = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_ADVERTISEMENTS_STATE](state) {
    state.advertisements = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_CREATE_ADVERTISEMENT_STATE](state) {
    state.createAdvertisement = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_GET_ADVERTISEMENT_STATE](state) {
    state.advertisement = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ADVERTISEMENT_STATE](state) {
    state.updateAdvertisement = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_DELETE_ADVERTISEMENT_STATE](state) {
    state.deleteAdvertisement = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
