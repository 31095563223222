import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import { CHAT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'chat/';

/* Define Action Name */
const A_GET_CHATS = 'getChats';
const A_GET_CHAT = 'getChat';
const A_GET_CHAT_DETAILS = 'getChatDetails';
const A_GET_CHATS_LIVE = 'getChatList';
const A_SEND_TEXT_MESSAGE = 'sendTextMessage';
const A_SEND_ATTACHMENT = 'sendAttachment';
const A_INITIATE_CHAT = 'sendInitiateChat';
const A_UPDATE_CHAT_FAVOURITE = 'updateChatFavourite';

/* Define Reset State Action Name */
const A_INITIAL_GET_CHATS_STATE = 'initialGetChatsState';
const A_INITIAL_GET_CHAT_STATE = 'initialGetChatState';
const A_INITIAL_GET_CHAT_DETAILS_STATE = 'initialGetChatDetailsState';
const A_INITIAL_GET_CHAT_LIST_STATE = 'initialGetChatListState';
const A_INITIAL_SEND_TEXT_MESSAGE_STATE = 'initialSendTextMessageState';
const A_INITIAL_SEND_ATTACHMENT_STATE = 'initialSendAttachmentState';
const A_INITIAL_INITIATE_CHAT_STATE = 'initialInitiateChatState';
const A_INITIAL_UPDATE_CHAT_FAVOURITE_STATE = 'initialUpdateChatFavouriteState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_CHATS = 'setGetChats';
const M_GET_CHAT = 'setGetChat';
const M_GET_CHAT_DETAILS = 'setGetChatDetails';
const M_GET_CHAT_LIST = 'setGetChatList';
const M_SEND_TEXT_MESSAGE = 'setSendTextMessage';
const M_SEND_ATTACHMENT = 'setSendAttachment';
const M_INITIATE_CHAT = 'setInitiateChat';
const M_UPDATE_CHAT_FAVOURITE = 'setUpdateChatFavourite';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_CHATS_STATE = 'setInitialGetChatsState';
const M_INITIAL_GET_CHAT_STATE = 'setInitialGetChatState';
const M_INITIAL_GET_CHAT_DETAILS_STATE = 'setInitialGetChatDetailsState';
const M_INITIAL_GET_CHAT_LIST_STATE = 'setInitialGetChatListState';
const M_INITIAL_SEND_TEXT_MESSAGE_STATE = 'setInitialSendTextMessageState';
const M_INITIAL_SEND_ATTACHMENT_STATE = 'setInitialSendAttachmentState';
const M_INITIAL_INITIATE_CHAT_STATE = 'setInitialInitiateChatState';
const M_INITIAL_UPDATE_CHAT_FAVOURITE_STATE = 'setInitialUpdateChatFavouriteState';

/* Define Export Name */
export const CHAT_GET_CHATS = MODULE_NAME + A_GET_CHATS;
export const CHAT_GET_CHAT = MODULE_NAME + A_GET_CHAT;
export const CHAT_GET_CHAT_DETAILS = MODULE_NAME + A_GET_CHAT_DETAILS;
export const CHAT_GET_CHAT_LIST = MODULE_NAME + A_GET_CHATS_LIVE;
export const CHAT_SEND_TEXT_MESSAGE = MODULE_NAME + A_SEND_TEXT_MESSAGE;
export const CHAT_SEND_ATTACHMENT = MODULE_NAME + A_SEND_ATTACHMENT;
export const CHAT_INITIATE_CHAT = MODULE_NAME + A_INITIATE_CHAT;
export const CHAT_UPDATE_CHAT_FAVOURITE = MODULE_NAME + A_UPDATE_CHAT_FAVOURITE;

/* Define Reset State Name */
export const CHAT_INITIAL_GET_CHATS_STATE =
  MODULE_NAME + A_INITIAL_GET_CHATS_STATE;
export const CHAT_INITIAL_GET_CHAT_STATE =
  MODULE_NAME + A_INITIAL_GET_CHAT_STATE;
export const CHAT_INITIAL_GET_CHAT_DETAILS_STATE =
  MODULE_NAME + A_INITIAL_GET_CHAT_DETAILS_STATE;
export const CHAT_INITIAL_GET_CHAT_LIST_STATE =
  MODULE_NAME + A_INITIAL_GET_CHAT_LIST_STATE;
export const CHAT_INITIAL_SEND_TEXT_MESSAGE_STATE =
  MODULE_NAME + A_INITIAL_SEND_TEXT_MESSAGE_STATE;
export const CHAT_INITIAL_SEND_ATTACHMENT_STATE =
  MODULE_NAME + A_INITIAL_SEND_ATTACHMENT_STATE;
export const CHAT_INITIAL_INITIATE_CHAT_STATE =
  MODULE_NAME + A_INITIAL_INITIATE_CHAT_STATE;
export const CHAT_INITIAL_UPDATE_CHAT_FAVOURITE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_CHAT_FAVOURITE_STATE;

const state = {
  chats: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  chat: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  chatDetails: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  chatSendTextMessage: {
    code: 0,
    complete: false,
    message: null
  },
  chatSendAttachment: {
    code: 0,
    complete: false,
    message: null
  },
  chatInitiateChat: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  chatUpdateChatFavourite: {
    code: 0,
    complete: false,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_CHATS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CHAT.getChats(data)
      .then((response) => {
        let result = response;
        commit(M_GET_CHATS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CHATS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_CHAT]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    CHAT.getChat(id)
      .then((response) => {
        let result = response;
        commit(M_GET_CHAT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CHAT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIATE_CHAT]({ commit }, { data }) {
    CHAT.initiateChat(data)
      .then((response) => {
        let result = response;
        commit(M_INITIATE_CHAT, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_INITIATE_CHAT, result);
      });
  },
  [A_GET_CHAT_DETAILS]({ commit }, { id }) {
    CHAT.getChatDetails(id)
      .then((response) => {
        let result = response;
        commit(M_GET_CHAT_DETAILS, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CHAT_DETAILS, result);
      });
  },
  [A_GET_CHATS_LIVE]({ commit }, { data }) {
    CHAT.getChatList(data)
      .then((response) => {
        let result = response;
        commit(M_GET_CHAT_LIST, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_CHAT_LIST, result);
      });
  },
  [A_SEND_TEXT_MESSAGE]({ commit }, { data }) {
    CHAT.sendTextMessage(data)
      .then((response) => {
        let result = response;
        commit(M_SEND_TEXT_MESSAGE, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_SEND_TEXT_MESSAGE, result);
      });
  },
  [A_SEND_ATTACHMENT]({ commit }, { data }) {
    CHAT.sendAttachment(data)
      .then((response) => {
        let result = response;
        commit(M_SEND_ATTACHMENT, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_SEND_ATTACHMENT, result);
      });
  },
  [A_UPDATE_CHAT_FAVOURITE]({ commit }, { data }) {
    CHAT.updateChatFavourite(data)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_CHAT_FAVOURITE, result);
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_CHAT_FAVOURITE, result);
      });
  },
  [A_INITIAL_INITIATE_CHAT_STATE]({ commit }) {
    commit(M_INITIAL_INITIATE_CHAT_STATE);
  },
  [A_INITIAL_GET_CHATS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CHATS_STATE);
  },
  [A_INITIAL_GET_CHAT_STATE]({ commit }) {
    commit(M_INITIAL_GET_CHAT_STATE);
  },
  [A_INITIAL_GET_CHAT_DETAILS_STATE]({ commit }) {
    commit(M_INITIAL_GET_CHAT_DETAILS_STATE);
  },
  [A_INITIAL_GET_CHAT_LIST_STATE]({ commit }) {
    commit(M_INITIAL_GET_CHAT_LIST_STATE);
  },
  [A_INITIAL_SEND_ATTACHMENT_STATE]({ commit }) {
    commit(M_INITIAL_SEND_ATTACHMENT_STATE);
  },
  [A_INITIAL_SEND_TEXT_MESSAGE_STATE]({ commit }) {
    commit(M_INITIAL_SEND_TEXT_MESSAGE_STATE);
  },
  [A_INITIAL_UPDATE_CHAT_FAVOURITE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_CHAT_FAVOURITE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_CHATS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.chats = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            castingRole: x.casting_role || '-',
            users: x.users.map((y) => {
              return {
                userId: y.user_id,
                displayName: y.display_name,
                companyName: y.company_name,
                headshotFile: {
                  id: y.headshot_file?.id,
                  mediaPath: y.headshot_file?.media_path
                }
              };
            }),
            isActive: x.is_active,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
          };
        }),
        message: null,
      };
    } else {
      state.chats = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_CHAT](state, result) {
    if (result.code === 200) {
      let chatGroup = result.data.chat_group;

      state.chat = {
        code: result.code,
        complete: true,
        data: {
          chatGroup: {
            id: chatGroup.id,
            name: chatGroup.name,
            castingRole: chatGroup.casting_role || '-',
            users: chatGroup.users.map((x) => {
              return {
                userId: x.user_id,
                adminUserId: x.admin_user_id,
                displayName: x.display_name,
                companyName: x.company_name,
                role: x.role,
                headshotFile: {
                  id: x.headshot_file?.id,
                  mediaPath: x.headshot_file?.media_path
                }
              };
            }),
            isActive: chatGroup.is_active,
            createdAt: dateTimeFormat(chatGroup.created_at)
          },
          chatGroupMessage: result.data.chat_group_message.map((x) => {
            return {
              id: x.id,
              userId: x.user_id,
              adminUserId: x.admin_user_id,
              chatMessageType: x.chat_message_type,
              message: x.message,
              media: {
                mediaType: x.media?.media_type,
                mediaName: x.media?.media_name,
                mediaFile: {
                  id: x.media?.media_file?.id,
                  mediaPath: x.media?.media_file?.media_path
                }
              },
              createdAt: dateTimeFormat(x.created_at),
              isDeleted: x.is_deleted
            };
          })
        },
        message: null
      };
    } else {
      state.chat = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_CHAT_DETAILS](state, result) {
    if (result.code === 200) {
      let chatGroup = result.data.chat_group;

      state.chatDetails = {
        code: result.code,
        complete: true,
        data: {
          chatGroup: {
            id: chatGroup.id,
            name: chatGroup.name,
            castingRole: chatGroup.casting_role || '-',
            users: chatGroup.users.map((x) => {
              return {
                userId: x.user_id,
                adminUserId: x.admin_user_id,
                displayName: x.display_name,
                companyName: x.company_name,
                role: x.role,
                headshotFile: {
                  id: x.headshot_file?.id,
                  mediaPath: x.headshot_file?.media_path
                }
              };
            }),
            isActive: chatGroup.is_active,
            createdAt: dateTimeFormat(chatGroup.created_at)
          },
          chatGroupMessage: result.data.chat_group_message.map((x) => {
            return {
              id: x.id,
              userId: x.user_id,
              adminUserId: x.admin_user_id,
              chatMessageType: x.chat_message_type,
              message: x.message,
              media: {
                mediaType: x.media?.media_type,
                mediaName: x.media?.media_name,
                mediaFile: {
                  id: x.media?.media_file?.id,
                  mediaPath: x.media?.media_file?.media_path
                }
              },
              createdAt: dateTimeFormat(x.created_at),
              isDeleted: x.is_deleted
            };
          })
        },
        message: null
      };
    } else {
      state.chatDetails = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_INITIATE_CHAT](state, result) {
    if (result.code === 200) {
      let chatGroupId = result.data.chat_group_id;

      state.chatInitiateChat = {
        code: result.code,
        complete: true,
        data: {
          chatGroupId: chatGroupId
        },
        message: apiHelper.sortOutMessage(result)
      };
    } else {
      state.chatInitiateChat = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_SEND_TEXT_MESSAGE](state, result) {
    state.chatSendTextMessage = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_SEND_ATTACHMENT](state, result) {
    state.chatSendAttachment = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_UPDATE_CHAT_FAVOURITE](state, result) {
    state.chatUpdateChatFavourite = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_CHATS_STATE](state) {
    state.chats = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_CHAT_STATE](state) {
    state.chat = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CHAT_DETAILS_STATE](state) {
    state.chatDetails = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_CHAT_LIST_STATE](state) {
    state.chats.code = 0;
    state.chats.complete = false;
    state.chats.data = false;
    state.chats.message = false;
  },
  [M_INITIAL_SEND_TEXT_MESSAGE_STATE](state) {
    state.chatSendTextMessage = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_INITIAL_SEND_ATTACHMENT_STATE](state) {
    state.chatSendAttachment = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_INITIAL_INITIATE_CHAT_STATE](state) {
    state.chatInitiateChat = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_INITIAL_UPDATE_CHAT_FAVOURITE_STATE](state) {
    state.chatUpdateChatFavourite = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_GET_CHAT_LIST](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.chats = {
        code: result.code,
        complete: true,
        data: i.map((x) => {
          return {
            userId: x.user_id,
            displayName: x.display_name,
            companyName: x.company_name,
            unreadMessageCount: x.unread_message_count,
            chatGroupId: x.chat_group_id,
            lastMessage: x.last_message,
            isFavourite: x.is_favourite,
            headshotFile: {
              id: x.headshot_file?.id,
              mediaPath: x.headshot_file?.media_path
            },
            role: x.role
          };
        }),
        message: null,
      };
    } else {
      state.chats = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
