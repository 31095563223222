import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import constantsDescription from '@/core/filters/constants-description.filter';
import { AUDIT_LOGS } from '@/core/api/audit-logs.api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'auditLogs/';

/* Define Action Name */
const A_GET_AUDIT_LOGS = 'getAuditLogs';
const A_GET_AUDIT_LOG = 'getAuditLog';

/* Define Reset State Action Name */
const A_INITIAL_GET_AUDIT_LOGS_STATE = 'initialGetAuditLogsState';
const A_INITIAL_GET_AUDIT_LOG_STATE = 'initialGetAuditLogState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_AUDIT_LOGS = 'setGetAuditLogs';
const M_GET_AUDIT_LOG = 'setGetAuditLog';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_AUDIT_LOGS_STATE = 'setInitialGetAuditLogsState';
const M_INITIAL_GET_AUDIT_LOG_STATE = 'setInitialGetAuditLogState';

/* Define Export Name */
export const AUDIT_LOGS_GET_AUDIT_LOGS = MODULE_NAME + A_GET_AUDIT_LOGS;
export const AUDIT_LOGS_GET_AUDIT_LOG = MODULE_NAME + A_GET_AUDIT_LOG;

/* Define Reset State Name */
export const AUDIT_LOGS_INITIAL_GET_AUDIT_LOGS_STATE =
  MODULE_NAME + A_INITIAL_GET_AUDIT_LOGS_STATE;
export const AUDIT_LOGS_INITIAL_GET_AUDIT_LOG_STATE =
  MODULE_NAME + A_INITIAL_GET_AUDIT_LOG_STATE;

const state = {
  auditLogs: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  auditLog: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_AUDIT_LOGS]({ dispatch, commit }, { data }) {
    let result;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    AUDIT_LOGS.getAuditLogs(data)
      .then((response) => {
        result = response;
        commit(M_GET_AUDIT_LOGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_AUDIT_LOGS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_AUDIT_LOG]({ dispatch, commit }, { id }) {
    let result;
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    AUDIT_LOGS.getAuditLog(id)
      .then((response) => {
        result = response;
        commit(M_GET_AUDIT_LOG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_GET_AUDIT_LOG, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_AUDIT_LOGS_STATE]({ commit }) {
    commit(M_INITIAL_GET_AUDIT_LOGS_STATE);
  },
  [A_INITIAL_GET_AUDIT_LOG_STATE]({ commit }) {
    commit(M_INITIAL_GET_AUDIT_LOG_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_AUDIT_LOGS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.auditLogs = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            tableName: constantsDescription('auditLogTableName', x.table_name),
            columnName: x.column_name,
            tableId: x.table_id,
            fromValue: x.from_value,
            toValue: x.to_value,
            adminUserId: x.admin_user_id,
            adminUserName: x.admin_user_name,
            createdAt: dateTimeFormat(x.created_at)
          };
        }),
        message: null,
      };
    } else {
      state.auditLogs = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_AUDIT_LOG](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.auditLog = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          tableName: constantsDescription('auditLogTableName', d.table_name),
          columnName: constantsDescription('auditLogColumnName', d.column_name),
          tableId: d.table_id,
          fromValue: d.from_value,
          toValue: d.to_value,
          adminUserId: d.admin_user_id,
          adminUserName: d.admin_user_name,
          createdAt: dateTimeFormat(d.created_at)
        },
        message: null
      };
    } else {
      state.auditLog = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_INITIAL_GET_AUDIT_LOGS_STATE](state) {
    state.auditLogs = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_AUDIT_LOG_STATE](state) {
    state.auditLog = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
