import { CURRENCY } from '@/core/constants';
import { i18nHelper } from '@/core/utils';

export default function currencyFormat(value) {
  let newValue = null;

  if (value) {
    newValue = `${i18nHelper.getMessage(`currency.${CURRENCY.MYR}`)} ${value}`;
  } else {
    newValue = '-';
  }

  return newValue;
}
