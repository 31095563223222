const ROUTE_NAME = Object.freeze({
  HOME: 'home',
  LOGIN: 'login',
  /* Account Management */
  ACCOUNT_MANAGEMENT: 'account-management',
  ACCOUNT_MANAGEMENT_LIST: 'account-management-list',
  ACCOUNT_MANAGEMENT_NEW: 'account-management-new',
  ACCOUNT_MANAGEMENT_DETAILS: 'account-management-details',
  /* Role Permission */
  ROLE_PERMISSION: 'role-permission',
  ROLE_PERMISSION_LIST: 'role-permission-list',
  ROLE_PERMISSION_NEW: 'role-permission-new',
  ROLE_PERMISSION_DETAILS: 'role-permission-details',
  /* Profile Details */
  PROFILE_DETAILS: 'profile-details',
  PROFILE_DETAILS_LIST: 'profile-details-list',
  PROFILE_DETAILS_DETAILS: 'profile-details-details',
  /* Account Verification */
  ACCOUNT_VERIFICATION: 'account-verification',
  ACCOUNT_VERIFICATION_LIST: 'account-verification-list',
  ACCOUNT_VERIFICATION_DETAILS: 'account-verification-details',
  /* Platform Info */
  PLATFORM_INFO: 'platform-info',
  /* Banner */
  BANNER: 'banner',
  BANNER_LIST: 'banner-list',
  BANNER_NEW: 'banner-new',
  BANNER_DETAILS: 'banner-details',
  /* Post */
  POST: 'post',
  POST_LIST: 'post-list',
  POST_DETAILS: 'post-details',
  /* Package */
  PACKAGE: 'package',
  PACKAGE_LIST: 'package-list',
  EXPIRED_PACKAGE_USERS: 'expired-package-users',
  /* App Update */
  APP_UPDATE: 'app-update',
  APP_UPDATE_LIST: 'app-update-list',
  /* Talent Category */
  TALENT_CATEGORY: 'talent-category',
  TALENT_CATEGORY_PROJECT_ROLE: 'talent-category-project-role',
  TALENT_CATEGORY_PROJECT_ROLE_LIST: 'talent-category-project-role-list',
  TALENT_CATEGORY_PROJECT_TYPE: 'talent-category-project-type',
  TALENT_CATEGORY_PROJECT_TYPE_LIST: 'talent-category-project-type-list',
  TALENT_CATEGORY_SKILL: 'talent-category-skill',
  TALENT_CATEGORY_SKILL_LIST: 'talent-category-skill-list',
  /* Chat */
  CHAT_HISTORY: 'chat-history',
  CHAT_HISTORY_LIST: 'chat-history-list',
  CHAT_HISTORY_DETAILS: 'chat-history-details',
  /* Review */
  REVIEW: 'review',
  REVIEW_LIST: 'review-list',
  REVIEW_DETAILS: 'review-details',
  /* Maintenance Schedule */
  MAINTENANCE: 'maintenance',
  MAINTENANCE_LIST: 'maintenance-list',
  /* Flag */
  FLAG: 'flag',
  FLAG_MEMBER: 'flag-member',
  FLAG_MEMBER_LIST: 'flag-member-list',
  FLAG_MEMBER_DETAILS: 'flag-member-details',
  FLAG_CASTING_POST: 'flag-casting-post',
  FLAG_CASTING_POST_LIST: 'flag-casting-post-list',
  FLAG_CASTING_POST_DETAILS: 'flag-casting-post-details',
  FLAG_COMMUNITY_POST: 'flag-community-post',
  FLAG_COMMUNITY_POST_LIST: 'flag-community-post-list',
  FLAG_COMMUNITY_POST_DETAILS: 'flag-community-post-details',
  FLAG_REVIEW: 'flag-review-post',
  FLAG_REVIEW_LIST: 'flag-review-list',
  FLAG_REVIEW_DETAILS: 'flag-review-details',
  /* Advertisement */
  ADVERTISEMENT: 'advertisement',
  ADVERTISEMENT_LIST: 'advertisement-list',
  ADVERTISEMENT_NEW: 'advertisement-new',
  ADVERTISEMENT_DETAILS: 'advertisement-details',
  /* Community */
  COMMUNITY_POST: 'community-post',
  COMMUNITY_POST_LIST: 'community-post-list',
  COMMUNITY_POST_DETAILS: 'community-post-details',
  /* Payment */
  PAYMENT: 'payment',
  PAYMENT_DASHBOARD: 'payment-dashboard',
  /* Live Chat */
  CHAT: 'chat',
  CHAT_LIVE: 'chat-live',
  /* Error */
  ERROR_403: '403',
  ERROR_404: '404'
});

export { ROUTE_NAME };
