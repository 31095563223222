<template>
  <div class="full-screen">
    <transition name="fade-in-up">
      <router-view />
    </transition>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { ROUTE_NAME } from '@/core/constants';
  import { AUTH_IS_AUTHENTICATED } from '@/core/store/auth.module';

  export default {
    name: 'LayoutBlank',
    computed: {
      ...mapGetters({
        isAuthenticated: [AUTH_IS_AUTHENTICATED]
      })
    },
    methods: {
      backToInitialPage() {
        if (this.isAuthenticated) {
          this.$router.push({ name: ROUTE_NAME.HOME });
        } else {
          this.$router.push({ name: ROUTE_NAME.LOGIN });
        }
      }
    }
  };
</script>

<style lang="scss"></style>
