import { apiService } from '@/core/services';

export const CONSTANTS = {
  getConstants
};

const URL = {
  CONSTANTS: 'constants'
};

function getConstants() {
  let requestOptions = {
    params: {
      locale: 'en'
    }
  };
  return apiService().get(`/${URL.CONSTANTS}`, requestOptions);
}
