import { LOCAL } from '@/core/constants';
import { uiHelper } from '@/core/utils';

export default function constantsDesc(value, key, valueKey, descriptionKey) {
  let description;
  let newArray = [];

  if (key && !['', undefined, null].includes(value)) {
    if (Array.isArray(value)) {
      if (value.length > 0) {
        value.forEach((x) => {
          newArray.push(getDescription(key, x, valueKey, descriptionKey));
        });
        description = newArray.join(', ');
      } else {
        description = '-';
      }
    } else {
      description = getDescription(key, value, valueKey, descriptionKey) || '-';
    }
  } else {
    description = '-';
  }

  return description;
}

function getDescription(key, value, valueKey, descriptionKey) {
  let constantsData = JSON.parse(uiHelper.getLocalStorage(LOCAL.CONSTANTS));
  let result = constantsData[key].find(
    (x) => x[valueKey ? valueKey : 'value'] == value
  );

  return result ? result[descriptionKey ? descriptionKey : 'description'] : null;
}
