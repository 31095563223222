import i18n from '@/core/plugins/i18n.js';
import { I18N_CONSTANTS } from '@/core/constants';

export const i18nService = {
  init,
  setActiveLanguage,
  getActiveLanguage
};

function init() {
  let language = getActiveLanguage();

  if (
    !I18N_CONSTANTS.AVAILABLE_LANGUAGES.find((x) => x.lang == language) ||
    [null, undefined, ''].includes(language)
  ) {
    setActiveLanguage(I18N_CONSTANTS.DEFAULT_LANGUAGE);
  } else {
    setActiveLanguage(language);
  }
}

function setActiveLanguage(lang) {
  /**
   * Keep the active language in the localStorage
   * @param lang
   */
  i18n.locale = lang;
  localStorage.setItem(I18N_CONSTANTS.LOCALE_STORAGE_KEY_NAME, lang);
}

function getActiveLanguage() {
  /**
   * Get the current active language
   * @returns {string | string}
   */

  return localStorage.getItem(I18N_CONSTANTS.LOCALE_STORAGE_KEY_NAME);
}
