import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const NEWS_FEEDS = {
  getNewsFeeds,
  getNewsFeed
};

const URL = {
  ADMIN: 'admin',
  NEWS_FEEDS: 'news-feeds'
};

function getNewsFeeds(data) {
  let requestOptions = {
    params: {
      owner: data.owner,
      created_at_from: apiHelper.dateTimeStart(data.createdDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.createdDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.NEWS_FEEDS}`, requestOptions);
}

function getNewsFeed(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.NEWS_FEEDS}/${id}`);
}
