import { apiService } from '@/core/services';

export const USER_PACKAGE = {
  getTalentPackageList,
  getProductionPackageList,
  updateUserPackage,
  getUserPackageInfo,
  getProductionPackageMembersList
};

const URL = {
  ADMIN: 'admin',
  MEMBER_SUBSCRIPTION: 'member-subscriptions',
  TALENT_PACKAGES: 'talent-packages',
  PRODUCTION_PACKAGES: 'production-packages',
  PRODUCTION_PACKAGES_MEMBERS: 'production-package-members'
};

function getTalentPackageList(data) {
  let requestOptions = {
    params: {
      per_page: data.per_page,
      page: data.current_page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MEMBER_SUBSCRIPTION}/${URL.TALENT_PACKAGES}`,
    requestOptions
  );
}
function getProductionPackageMembersList(data) {
  let requestOptions = {
    params: {
      ...data,
      status: 'expired'
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MEMBER_SUBSCRIPTION}/${URL.PRODUCTION_PACKAGES_MEMBERS}`,
    requestOptions
  );
}
function getProductionPackageList(data) {
  let requestOptions = {
    params: {
      per_page: data.per_page,
      page: data.current_page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.MEMBER_SUBSCRIPTION}/${URL.PRODUCTION_PACKAGES}`,
    requestOptions
  );
}
function getUserPackageInfo(id, modalType) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.MEMBER_SUBSCRIPTION}/${
      modalType === 'talent' ? URL.TALENT_PACKAGES : URL.PRODUCTION_PACKAGES
    }/${id}`
  );
}
function updateUserPackage(id, data, modalType) {
  let requestBody =
    modalType === 'talent'
      ? {
          name: data.name,
          level: data.level,
          casting_per_month: data.casting_per_month,
          price_per_month: data.price_per_month,
          addon_video_price: data.addon_video_price,
          addon_video_count: data.addon_video_count,
          is_active: true,
          package_details: (data.package_details || []).map((i) => {
            const valueArray = Object.entries(i).map(([key]) => {
              return {
                [`${key.substring(0, key.lastIndexOf('_'))}`]: data[key]
              };
            });
            return Object.assign({}, ...valueArray);
          })
        }
      : {
          name: data.name,
          level: data.level,
          role: data.role,
          buy_admin: false,
          package_details: [
            {
              price_per_annum: data.price_per_annum,
              no_of_users: data.no_of_users,
              price_per_admin: data.price_per_admin,
              no_of_free_users: data.no_of_free_users
            }
          ]
        };
  return apiService().put(
    `/${URL.ADMIN}/${URL.MEMBER_SUBSCRIPTION}/${
      modalType === 'talent' ? URL.TALENT_PACKAGES : URL.PRODUCTION_PACKAGES
    }/${id}`,
    requestBody
  );
}
