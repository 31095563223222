import { SKILLS } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'skills/';

/* Define Action Name */
const A_GET_SKILLS = 'getSkills';
const A_GET_SKILL = 'getSkill';
const A_CREATE_SKILL = 'createSkill';
const A_UPDATE_SKILL = 'updateSkill';
const A_DELETE_SKILL = 'deleteSkill';

/* Define Reset State Action Name */
const A_INITIAL_SKILLS_STATE = 'initialSkillsState';
const A_INITIAL_SKILL_STATE = 'initialSkillState';
const A_INITIAL_CREATE_SKILL_STATE = 'initialCreateSkillState';
const A_INITIAL_UPDATE_SKILL_STATE = 'initialUpdateSkillState';
const A_INITIAL_DELETE_SKILL_STATE = 'initialDeleteSkillState';

/* Define Getter Name */

/* Define Mutation Name */
const M_SKILLS = 'setSkills';
const M_SKILL = 'setSkill';
const M_CREATE_SKILL = 'setCreateSkill';
const M_UPDATE_SKILL = 'setUpdateSkill';
const M_DELETE_SKILL = 'setDeleteSkill';

/* Define Reset State Mutation Name */
const M_INITIAL_SKILLS_STATE = 'setInitialSkillsState';
const M_INITIAL_SKILL_STATE = 'setInitialSkillState';
const M_INITIAL_CREATE_SKILL_STATE = 'setInitialCreateSkillState';
const M_INITIAL_UPDATE_SKILL_STATE = 'setInitialUpdateSkillState';
const M_INITIAL_DELETE_SKILL_STATE = 'setInitialDeleteSkillState';

/* Define Export Name */
export const SKILLS_GET_SKILLS =
  MODULE_NAME + A_GET_SKILLS;
export const SKILLS_GET_SKILL = MODULE_NAME + A_GET_SKILL;
export const SKILLS_CREATE_SKILL =
  MODULE_NAME + A_CREATE_SKILL;
export const SKILLS_UPDATE_SKILL =
  MODULE_NAME + A_UPDATE_SKILL;
export const SKILLS_DELETE_SKILL =
  MODULE_NAME + A_DELETE_SKILL;

/* Define Reset State Name */
export const SKILLS_INITIAL_SKILLS_STATE =
  MODULE_NAME + A_INITIAL_SKILLS_STATE;
export const SKILLS_INITIAL_SKILL_STATE =
  MODULE_NAME + A_INITIAL_SKILL_STATE;
export const SKILLS_INITIAL_CREATE_SKILL_STATE =
  MODULE_NAME + A_INITIAL_CREATE_SKILL_STATE;
export const SKILLS_INITIAL_UPDATE_SKILL_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_SKILL_STATE;
export const SKILLS_INITIAL_DELETE_SKILL_STATE =
  MODULE_NAME + A_INITIAL_DELETE_SKILL_STATE;

const state = {
  skills: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  skill: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createSkill: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateSkill: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteSkill: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_SKILLS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SKILLS.getSkills(data)
      .then((response) => {
        result = response;
        commit(M_SKILLS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SKILLS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_SKILL]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SKILLS.getSkill(id)
      .then((response) => {
        result = response;
        commit(M_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_SKILL]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SKILLS.createSkill(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_SKILL]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SKILLS.updateSkill(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_SKILL]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    SKILLS.deleteSkill(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_SKILL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_SKILLS_STATE]({ commit }) {
    commit(M_INITIAL_SKILLS_STATE);
  },
  [A_INITIAL_SKILL_STATE]({ commit }) {
    commit(M_INITIAL_SKILL_STATE);
  },
  [A_INITIAL_CREATE_SKILL_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_SKILL_STATE);
  },
  [A_INITIAL_UPDATE_SKILL_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_SKILL_STATE);
  },
  [A_INITIAL_DELETE_SKILL_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_SKILL_STATE);
  }
};

// Mutations
const mutations = {
  [M_SKILLS](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.skills = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            isDeleted: x.is_deleted
          };
        }),
        message: null,
      };
    } else {
      state.skills = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_SKILL](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.skill = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          isDeleted: d.is_deleted
        },
        message: null
      };
    } else {
      state.skill = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_SKILL](state, result) {
    state.createSkill = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_UPDATE_SKILL](state, result) {
    state.updateSkill = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_SKILL](state, result) {
    state.deleteSkill = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_SKILLS_STATE](state) {
    state.skills = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_SKILL_STATE](state) {
    state.skill = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_SKILL_STATE](state) {
    state.createSkill = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_SKILL_STATE](state) {
    state.updateSkill = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_SKILL_STATE](state) {
    state.deleteSkill = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
