<template>
  <div
    class="subheader py-2 py-lg-4 subheader-solid"
    v-bind:class="subheaderClasses"
    id="kt_subheader"
  >
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
    >
      <div class="d-flex align-items-center flex-wrap mr-1">
        <ul
          class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2"
        >
          <li class="breadcrumb-page-title d-lg-none text-uppercase">
            {{
              subHeader.breadcrumbs[subHeader.breadcrumbs.length - 1]
                ? subHeader.breadcrumbs[subHeader.breadcrumbs.length - 1].title
                : 'Page Title'
            }}
          </li>
          <span
            class="h-20px border-gray-200 border-left d-lg-none mx-4"
          ></span>
          <li class="breadcrumb-item">
            <router-link :to="'/'" class="subheader-breadcrumbs-home">
              <i class="flaticon2-shelter text-muted icon-1x"></i>
            </router-link>
          </li>

          <template v-for="(breadcrumb, i) in subHeader.breadcrumbs">
            <li
              class="breadcrumb-item text-capitalize"
              :key="`${i}-${breadcrumb.id}`"
            >
              <router-link
                v-if="breadcrumb.route"
                :key="i"
                :to="breadcrumb.route"
                class="text-muted"
              >
                {{ breadcrumb.title }}
              </router-link>
              <span
                class="text-dark font-weight-bold"
                :key="i"
                v-if="!breadcrumb.route"
              >
                {{ breadcrumb.title }}
              </span>
            </li>
          </template>
        </ul>
      </div>
      <div class="ml-auto">
        <div class="d-flex justify-content-end">
          <template v-for="(action, i) in subHeader.actions">
            <b-button
              :key="i"
              size="sm"
              :variant="action.variant ? action.variant : 'primary'"
              class="ml-2"
              @click="
                () => {
                  action.function();
                }
              "
            >
              {{ action.text }}
            </b-button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    name: 'KTSubheader',
    props: {
      subHeader: Object
    },
    computed: {
      ...mapGetters(['layoutConfig']),

      /**
       * Check if subheader width is fluid
       */
      widthFluid() {
        return this.layoutConfig('subheader.width') === 'fluid';
      },

      subheaderClasses() {
        const classes = [];
        const style = this.layoutConfig('subheader.style');
        if (style) {
          classes.push(style);

          if (style === 'solid') {
            classes.push('bg-white');
          }

          if (this.layoutConfig('subheader.fixed')) {
            classes.push('border-top');
          }
        }
        return classes.join(' ');
      }
    }
  };
</script>

<style lang="scss"></style>
