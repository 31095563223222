import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const CASTING_JOBS = {
  getCastingJobs,
  getCastingJobsPagination,
  getCastingJob,
  getCastingJobApplications,
  exportCastingJobsToExcel,
  updateCastingJobVisibility,
  updateCastingJobApplicationFee,
  updateCastingJobUnionNo
};

const URL = {
  ADMIN: 'admin',
  CASTING_JOBS: 'casting-jobs',
  APPLICANTS: 'applicants',
  EXPORT: 'export',
  EXCEL: 'excel',
  PAGINAT: 'paginat',
  DISABLED: 'disabled',
  APPLICATION_FEES: 'application-fees',
  UNION_NO: 'union-no'
};

function getCastingJobs(data) {
  let requestOptions = {
    params: {
      title: data.title,
      projectTypeId: data.projectTypeId,
      owner: data.owner,
      status: data.status,
      created_at_from: apiHelper.dateTimeStart(data.createdDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.createdDateRange),
      close_date_from: apiHelper.dateTimeStart(data.closeDateRange),
      close_date_to: apiHelper.dateTimeEnd(data.closeDateRange),
      live_date_from: apiHelper.dateTimeStart(data.liveDateRange),
      live_date_to: apiHelper.dateTimeEnd(data.liveDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.CASTING_JOBS}`, requestOptions);
}

function getCastingJobsPagination(data) {
  let requestOptions = {
    params: {
      title: data.title,
      projectTypeId: data.projectTypeId,
      owner: data.owner,
      status: data.status,
      created_at_from: apiHelper.dateTimeStart(data.createdDateRange),
      created_at_to: apiHelper.dateTimeEnd(data.createdDateRange),
      close_date_from: apiHelper.dateTimeStart(data.closeDateRange),
      close_date_to: apiHelper.dateTimeEnd(data.closeDateRange),
      live_date_from: apiHelper.dateTimeStart(data.liveDateRange),
      live_date_to: apiHelper.dateTimeEnd(data.liveDateRange),
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.CASTING_JOBS}/${URL.PAGINAT}`,
    requestOptions
  );
}

function getCastingJob(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.CASTING_JOBS}/${id}`);
}

function getCastingJobApplications(id) {
  return apiService().get(
    `/${URL.ADMIN}/${URL.CASTING_JOBS}/${id}/${URL.APPLICANTS}`
  );
}
function exportCastingJobsToExcel(data) {
  let requestOptions = {
    params: {
      title: data.title,
      projectTypeId: data.projectTypeId,
      owner: data.owner,
      status: data.status,
      createdAtFrom: apiHelper.dateTimeStart(data.createdDateRange),
      createdAtTo: apiHelper.dateTimeEnd(data.createdDateRange),
      closeDateFrom: apiHelper.dateTimeStart(data.closeDateRange),
      closeDateTo: apiHelper.dateTimeEnd(data.closeDateRange),
      liveDateFrom: apiHelper.dateTimeStart(data.liveDateRange),
      liveDateTo: apiHelper.dateTimeEnd(data.liveDateRange)
    }
  };

  return apiService()(
    {
      url: `/${URL.ADMIN}/${URL.CASTING_JOBS}/${URL.EXPORT}/${URL.EXCEL}`,
      responseType: 'blob'
    },
    requestOptions
  );
}

function updateCastingJobVisibility(id, { visibility }) {
  let requestBody = {
    disabled: visibility ? 0 : 1
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CASTING_JOBS}/${id}/${URL.DISABLED}`,
    requestBody
  );
}

function updateCastingJobApplicationFee(id, data) {
  let requestBody = data;
  return apiService().put(
    `/${URL.ADMIN}/${URL.CASTING_JOBS}/${id}/${URL.APPLICATION_FEES}`,
    requestBody
  );
}

function updateCastingJobUnionNo(id, { unionNo }) {
  let requestBody = {
    enable: !!unionNo
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.CASTING_JOBS}/${id}/${URL.UNION_NO}`,
    requestBody
  );
}
