import { apiService } from '@/core/services';

export const SKILLS = {
  getSkills,
  getSkill,
  createSkill,
  updateSkill,
  deleteSkill
};

const URL = {
  ADMIN: 'admin',
  SKILLS: 'skills',
  DELETE: 'delete'
};

function getSkills(data) {
  let requestOptions = {
    params: {
      name: data.name,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.SKILLS}`, requestOptions);
}

function getSkill(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.SKILLS}/${id}`);
}

function createSkill(data) {
  let requestBody = {
    name: data.name,
    project_type_ids: data.projectTypeIds
  };

  return apiService().post(`/${URL.ADMIN}/${URL.SKILLS}`, requestBody);
}

function updateSkill(id, data) {
  let requestBody = {
    name: data.name,
    project_type_ids: data.projectTypeIds
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.SKILLS}/${id}`,
    requestBody
  );
}

function deleteSkill(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.SKILLS}/${id}/${URL.DELETE}`
  );
}
