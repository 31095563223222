import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';
import { NEWS_FEEDS } from '@/core/api';
import { apiHelper, uiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'newsFeeds/';

/* Define Action Name */
const A_GET_NEWS_FEEDS = 'getNewsFeeds';
const A_GET_NEWS_FEED = 'getNewsFeed';

/* Define Reset State Action Name */
const A_INITIAL_GET_NEWS_FEEDS_STATE = 'initialGetNewsFeedsState';
const A_INITIAL_GET_NEWS_FEED_STATE = 'initialGetNewsFeedState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_NEWS_FEEDS = 'setGetNewsFeeds';
const M_GET_NEWS_FEED = 'setGetNewsFeed';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_NEWS_FEEDS_STATE = 'setInitialGetNewsFeedsState';
const M_INITIAL_GET_NEWS_FEED_STATE = 'setInitialGetNewsFeedState';

/* Define Export Name */
export const NEWS_FEEDS_GET_NEWS_FEEDS = MODULE_NAME + A_GET_NEWS_FEEDS;
export const NEWS_FEEDS_GET_NEWS_FEED = MODULE_NAME + A_GET_NEWS_FEED;

/* Define Reset State Name */
export const NEWS_FEEDS_INITIAL_GET_NEWS_FEEDS_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_FEEDS_STATE;
export const NEWS_FEEDS_INITIAL_GET_NEWS_FEED_STATE =
  MODULE_NAME + A_INITIAL_GET_NEWS_FEED_STATE;

const state = {
  newsFeeds: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  newsFeed: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_NEWS_FEEDS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    NEWS_FEEDS.getNewsFeeds(data)
      .then((response) => {
        let result = response;
        commit(M_GET_NEWS_FEEDS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_NEWS_FEEDS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_NEWS_FEED]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    NEWS_FEEDS.getNewsFeed(id)
      .then((response) => {
        let result = response;
        commit(M_GET_NEWS_FEED, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_NEWS_FEED, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_NEWS_FEEDS_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_FEEDS_STATE);
  },
  [A_INITIAL_GET_NEWS_FEED_STATE]({ commit }) {
    commit(M_INITIAL_GET_NEWS_FEED_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_NEWS_FEEDS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;

      state.newsFeeds = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            content: x.content,
            user: {
              id: x.user?.id,
              name: x.user?.name,
              logoImage: {
                id: x.user?.logo_image?.id,
                mediaPath: x.user?.logo_image?.media_path,
                mediaType: uiHelper.getFileExtension(
                  x.user?.logo_image?.media_path
                )
              }
            },
            media: x.media
              ? x.media.map((y) => {
                  return {
                    id: y.id,
                    mediaPath: y.media_path,
                    mimeType: y.mime_type,
                    mediaType: uiHelper.getFileExtension(y.media_path)
                  };
                })
              : null,
            like: x.like,
            liked: x.liked,
            comments: x.comments,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
            owner: x.user?.name
          };
        }),
        message: null,
      };
    } else {
      state.newsFeeds = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_GET_NEWS_FEED](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.newsFeed = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          content: d.content,
          user: {
            id: d.user?.id,
            name: d.user?.name,
            logoImage: {
              id: d.user?.logo_image?.id,
              mediaPath: d.user?.logo_image?.media_path,
              mediaType: uiHelper.getFileExtension(
                d.user?.logo_image?.media_path
              )
            }
          },
          media: d.media
            ? d.media.map((y) => {
                return {
                  id: y.id,
                  mediaPath: y.media_path,
                  mimeType: y.mime_type,
                  mediaType: uiHelper.getFileExtension(y.media_path)
                };
              })
            : null,
          like: d.like,
          liked: d.liked,
          comments: d.comments,
          createdAt: dateTimeFormat(d.created_at)
        },
        message: null
      };
    } else {
      state.newsFeed = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_INITIAL_GET_NEWS_FEEDS_STATE](state) {
    state.newsFeeds = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_NEWS_FEED_STATE](state) {
    state.newsFeed = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
