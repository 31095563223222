<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
    z-index="1200"
    bg-color="black"
    opacity="0.5"
    fixed
    no-wrap
  >
    <template #overlay>
      <b-card no-body class="w-180px h-50px p-3">
        <div class="d-flex justify-content-center align-items-center">
          <span>{{ $t('label.pleaseWait') + '...' }}</span>
          <b-spinner class="ml-3" variant="primary"></b-spinner>
        </div>
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
  export default {
    name: 'AppLoading',
    data: () => ({}),
    computed: {
      isLoading() {
        return this.$store.state.loading.isLoading;
      }
    }
  };
</script>

<style lang="scss"></style>
