const FLAG_TYPE = {
  FAKE_DETAILS: 1,
  IMPERSONATION: 2,
  FALSE_ADVERTISEMENT: 3,
  VERBAL_ABUSE: 4,
  NUDITY: 5,
  PROFANITY: 6,
  INAPPROPRIATE_PRODUCTS_ADVERTISEMENT: 7,
  OTHER: 8
};

export { FLAG_TYPE };
