import { PROJECT_ROLES } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'projectRoles/';

/* Define Action Name */
const A_GET_PROJECT_ROLES = 'getProjectRoles';
const A_GET_PROJECT_ROLE = 'getProjectRole';
const A_CREATE_PROJECT_ROLE = 'createProjectRole';
const A_UPDATE_PROJECT_ROLE = 'updateProjectRole';
const A_DELETE_PROJECT_ROLE = 'deleteProjectRole';

/* Define Reset State Action Name */
const A_INITIAL_PROJECT_ROLES_STATE = 'initialProjectRolesState';
const A_INITIAL_PROJECT_ROLE_STATE = 'initialProjectRoleState';
const A_INITIAL_CREATE_PROJECT_ROLE_STATE = 'initialCreateProjectRoleState';
const A_INITIAL_UPDATE_PROJECT_ROLE_STATE = 'initialUpdateProjectRoleState';
const A_INITIAL_DELETE_PROJECT_ROLE_STATE = 'initialDeleteProjectRoleState';

/* Define Getter Name */

/* Define Mutation Name */
const M_PROJECT_ROLES = 'setProjectRoles';
const M_PROJECT_ROLE = 'setProjectRole';
const M_CREATE_PROJECT_ROLE = 'setCreateProjectRole';
const M_UPDATE_PROJECT_ROLE = 'setUpdateProjectRole';
const M_DELETE_PROJECT_ROLE = 'setDeleteProjectRole';

/* Define Reset State Mutation Name */
const M_INITIAL_PROJECT_ROLES_STATE = 'setInitialProjectRolesState';
const M_INITIAL_PROJECT_ROLE_STATE = 'setInitialProjectRoleState';
const M_INITIAL_CREATE_PROJECT_ROLE_STATE = 'setInitialCreateProjectRoleState';
const M_INITIAL_UPDATE_PROJECT_ROLE_STATE = 'setInitialUpdateProjectRoleState';
const M_INITIAL_DELETE_PROJECT_ROLE_STATE = 'setInitialDeleteProjectRoleState';

/* Define Export Name */
export const PROJECT_ROLES_GET_PROJECT_ROLES =
  MODULE_NAME + A_GET_PROJECT_ROLES;
export const PROJECT_ROLE_GET_PROJECT_ROLE = MODULE_NAME + A_GET_PROJECT_ROLE;
export const PROJECT_ROLE_CREATE_PROJECT_ROLE =
  MODULE_NAME + A_CREATE_PROJECT_ROLE;
export const PROJECT_ROLE_UPDATE_PROJECT_ROLE =
  MODULE_NAME + A_UPDATE_PROJECT_ROLE;
export const PROJECT_ROLE_DELETE_PROJECT_ROLE =
  MODULE_NAME + A_DELETE_PROJECT_ROLE;

/* Define Reset State Name */
export const PROJECT_ROLES_INITIAL_PROJECT_ROLES_STATE =
  MODULE_NAME + A_INITIAL_PROJECT_ROLES_STATE;
export const PROJECT_ROLE_INITIAL_PROJECT_ROLE_STATE =
  MODULE_NAME + A_INITIAL_PROJECT_ROLE_STATE;
export const PROJECT_ROLE_INITIAL_CREATE_PROJECT_ROLE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_PROJECT_ROLE_STATE;
export const PROJECT_ROLE_INITIAL_UPDATE_PROJECT_ROLE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PROJECT_ROLE_STATE;
export const PROJECT_ROLE_INITIAL_DELETE_PROJECT_ROLE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_PROJECT_ROLE_STATE;

const state = {
  projectRoles: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  projectRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createProjectRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateProjectRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteProjectRole: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PROJECT_ROLES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_ROLES.getProjectRoles(data)
      .then((response) => {
        result = response;
        commit(M_PROJECT_ROLES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PROJECT_ROLES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PROJECT_ROLE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_ROLES.getProjectRole(id)
      .then((response) => {
        result = response;
        commit(M_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_PROJECT_ROLE]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_ROLES.createProjectRole(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PROJECT_ROLE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_ROLES.updateProjectRole(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_PROJECT_ROLE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_ROLES.deleteProjectRole(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_PROJECT_ROLE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_PROJECT_ROLES_STATE]({ commit }) {
    commit(M_INITIAL_PROJECT_ROLES_STATE);
  },
  [A_INITIAL_PROJECT_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_PROJECT_ROLE_STATE);
  },
  [A_INITIAL_CREATE_PROJECT_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_PROJECT_ROLE_STATE);
  },
  [A_INITIAL_UPDATE_PROJECT_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_PROJECT_ROLE_STATE);
  },
  [A_INITIAL_DELETE_PROJECT_ROLE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_PROJECT_ROLE_STATE);
  }
};

// Mutations
const mutations = {
  [M_PROJECT_ROLES](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.projectRoles = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            isDeleted: x.is_deleted
          };
        }),
        message: null,
      };
    } else {
      state.projectRoles = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_PROJECT_ROLE](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.projectRole = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          projectTypeIds: d.project_type_ids,
          isDeleted: d.is_deleted
        },
        message: null
      };
    } else {
      state.projectRole = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_PROJECT_ROLE](state, result) {
    state.createProjectRole = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_UPDATE_PROJECT_ROLE](state, result) {
    state.updateProjectRole = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_PROJECT_ROLE](state, result) {
    state.deleteProjectRole = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_PROJECT_ROLES_STATE](state) {
    state.projectRoles = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_PROJECT_ROLE_STATE](state) {
    state.projectRole = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_PROJECT_ROLE_STATE](state) {
    state.createProjectRole = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_PROJECT_ROLE_STATE](state) {
    state.updateProjectRole = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_PROJECT_ROLE_STATE](state) {
    state.deleteProjectRole = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
