import { helpers } from 'vuelidate/lib/validators';
import { GLOBAL } from '@/core/constants';

const REGEX = Object.freeze({
  decimalTwo: /^[1-9]\d*(\.\d{1,2})?$/,
  appVersion: /^(\d+\.)?(\d+\.)?(\d+\.)?(\*|\d+)?$/,
  urlOptionalProtocol:
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  hexColorFormat: /^#([0-9a-f]{6})$/
});

const price = helpers.regex('price', REGEX.decimalTwo);

const bannerImageType = (value) =>
  !helpers.req(value) ||
  GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.includes(value.type);

const bannerVideoType = (value) =>
  !helpers.req(value) ||
  GLOBAL.AVAILABLE_BANNER_VIDEO_TYPE.includes(value.type);

const fileSize = (param) => (value) =>
  !helpers.req(value) || value.size < param * 1000000;

const platformInfoApplicationType = (value) =>
  !helpers.req(value) ||
  GLOBAL.AVAILABLE_PLATFORM_INFO_APPLICATION_TYPE.includes(value.type);

const startDateTime = (param) => (value) =>
  !helpers.req(value) ||
  !helpers.req(param) ||
  new Date(value).getTime() < new Date(param).getTime();

const endDateTime = (param) => (value) =>
  !helpers.req(value) ||
  !helpers.req(param) ||
  new Date(value).getTime() > new Date(param).getTime();

const urlOptionalProtocol = helpers.regex(
  'urlOptionalProtocol',
  REGEX.urlOptionalProtocol
);

const startDate =
  (param, isAllowedEndDateEmpty = false) =>
  (value) =>
    !isAllowedEndDateEmpty
      ? !helpers.req(value) ||
        new Date(value).getTime() < new Date(param).getTime()
      : true;

const endDate =
  (param, isAllowedEndDateEmpty = false) =>
  (value) =>
    value || !isAllowedEndDateEmpty
      ? !helpers.req(value) ||
        new Date(value).getTime() > new Date(param).getTime()
      : true;

const startTime =
  (param, isAllowedEndTimeEmpty = false) =>
  (value) =>
    !isAllowedEndTimeEmpty
      ? !helpers.req(value) ||
        new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        ).getTime() <
          new Date(
            new Date().toISOString().substr(0, 10) + ' ' + param
          ).getTime()
      : true;

const endTime =
  (param, isAllowedEndDateEmpty = false) =>
  (value) =>
    value || !isAllowedEndDateEmpty
      ? !helpers.req(value) ||
        new Date(
          new Date().toISOString().substr(0, 10) + ' ' + value
        ).getTime() >
          new Date(
            new Date().toISOString().substr(0, 10) + ' ' + param
          ).getTime()
      : true;

const bannerImageDimension = (paramHeight, paramWidth) => (value) => {
  const validateImage = (file, height, width) => {
    return new Promise((resolve) => {
      var reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = function (e) {
        var image = new Image();

        image.src = e.target.result;

        image.onload = () =>
          resolve({
            valid:
              image.height >= Number(height) &&
              image.width >= Number(width) &&
              image.height == image.width
          });
      };
    });
  };

  if (value && GLOBAL.AVAILABLE_BANNER_IMAGE_TYPE.includes(value.type)) {
    let promiseValidateImage = validateImage(
      value,
      paramHeight,
      paramWidth
    ).then((value) => {
      return value.valid;
    });
    return promiseValidateImage;
  } else {
    return true;
  }
};

const hexColor = helpers.regex('hexColorFormat', REGEX.hexColorFormat);

const advertisementThumbnailImageType = (value) =>
  !helpers.req(value) ||
  GLOBAL.AVAILABLE_ADVERTISEMENT_THUMBNAIL_TYPE.includes(value.type);

const appVersioning = helpers.regex('appVersioning', REGEX.appVersion);

export {
  price,
  bannerImageType,
  bannerVideoType,
  fileSize,
  platformInfoApplicationType,
  startDateTime,
  endDateTime,
  urlOptionalProtocol,
  startDate,
  endDate,
  startTime,
  endTime,
  bannerImageDimension,
  hexColor,
  advertisementThumbnailImageType,
  appVersioning
};
