import { apiService } from '@/core/services';

export const REVIEWS = {
  getReviews,
  getReviewById,
  updateReview
};

const URL = {
  ADMIN: 'admin',
  REVIEW: 'review',
};

function getReviews(data) {
  let requestOptions = {
    params: {
      rating: data.rating,
      status: data.status,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.REVIEW}`, requestOptions);
}

function getReviewById(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.REVIEW}/${id}`);
}

function updateReview(id, data) {
  let requestBody = {
    status: data.status,
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.REVIEW}/${id}`,
    requestBody
  );
}
