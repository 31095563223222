import { API } from '@/core/constants';

export const apiHelper = {
  sortOutMessage,
  removeParamsWithoutValue,
  getArrayToStringValue,
  getObjectValue,
  dateTimeStart,
  dateTimeEnd
};

function sortOutMessage(data) {
  let originalMessage = null;
  let message = [];
  let newMessages = [];

  if (typeof data === 'string') {
    originalMessage = data;
  } else {
    if (data.msg || data.error_message || data.error) {
      originalMessage =
        data.code === 200
          ? data.msg
          : data.error_message
          ? data.error_message
          : data.error;
    } else {
      originalMessage = data ? data.toString() : 'No Message';
    }
  }

  if (typeof originalMessage === 'string') {
    newMessages.push(originalMessage);
  } else {
    message = Object.values(originalMessage);
    message.forEach((v) => {
      newMessages.push(v[0]);
    });
  }

  return newMessages;
}

function removeParamsWithoutValue(obj) {
  for (var keyName in obj) {
    if (
      obj[keyName] === null ||
      obj[keyName] === undefined ||
      obj[keyName] === ''
    ) {
      delete obj[keyName];
    }
  }
  return obj;
}

function getArrayToStringValue(array) {
  let stringData = '';
  let newArray = [];

  if (array) {
    if (array.length >= 1) {
      array.forEach((x) => {
        newArray.push(x.value);
      });
      stringData = newArray.toString();
    }
  }

  return stringData;
}

function getObjectValue(data) {
  let value = null;

  if (!['', null, undefined].includes(data)) {
    value = data.value;
  }

  return value;
}

function dateTimeStart(dateRange, hasTime = true) {
  let newDate = '';

  if (!['', null, undefined].includes(dateRange)) {
    let range = dateRange.split(',');

    newDate = range[0] + (hasTime ? API.DATETIME_FROM_START_TIME : '');
  }

  return newDate;
}

function dateTimeEnd(dateRange, hasTime = true) {
  let newDate = '';

  if (!['', null, undefined].includes(dateRange)) {
    let range = dateRange.split(',');

    newDate = range[1] + (hasTime ? API.DATETIME_TO_END_TIME : '');
  }

  return newDate;
}
