import { permissionHelper } from '@/core/utils';
import { ROUTE_NAME, MODULE } from '@/core/constants';
import { ASIDE_MENU_CATEGORY } from '@/core/constants/enums';

export const accessRightsService = {
  checkUserAccessRights,
  authorizedNavigationList
};

const navigationList = [
  {
    path: '/home',
    routeName: ROUTE_NAME.HOME,
    name: 'home',
    isActive: true,
    icon: 'flaticon-home-2',
    category: ASIDE_MENU_CATEGORY.MAIN,
    authorizeModules: [],
    children: []
  },
  {
    path: '/account-management',
    routeName: ROUTE_NAME.ACCOUNT_MANAGEMENT,
    name: 'accountManagement',
    isActive: false,
    icon: 'flaticon2-user',
    category: ASIDE_MENU_CATEGORY.ADMIN_USER,
    authorizeModules: [MODULE.ADMIN_USER],
    children: []
  },
  {
    path: '/role-permission',
    routeName: ROUTE_NAME.ROLE_PERMISSION,
    name: 'rolePermission',
    isActive: false,
    icon: 'flaticon2-shield',
    category: ASIDE_MENU_CATEGORY.ADMIN_USER,
    authorizeModules: [MODULE.ROLE],
    children: []
  },
  {
    path: '/profile-details',
    routeName: ROUTE_NAME.PROFILE_DETAILS,
    name: 'profileDetails',
    isActive: false,
    icon: 'flaticon2-avatar',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.USER],
    children: []
  },
  {
    path: '/expired-package-user',
    routeName: ROUTE_NAME.EXPIRED_PACKAGE_USERS,
    name: 'expiredUserPackage',
    isActive: false,
    icon: 'flaticon2-avatar',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.EXPIRED_USER_PACKAGE],
    children: []
  },
  {
    path: '/account-verification',
    routeName: ROUTE_NAME.ACCOUNT_VERIFICATION,
    name: 'accountVerification',
    isActive: false,
    icon: 'flaticon2-sheet',
    notification: 'accountVerification',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.ACCOUNT_VERIFICATION],
    children: []
  },
  {
    path: '/banner',
    routeName: ROUTE_NAME.BANNER,
    name: 'banner',
    isActive: false,
    icon: 'fas fa-images',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.BANNER],
    children: []
  },
  {
    path: '/advertisement',
    routeName: ROUTE_NAME.ADVERTISEMENT,
    name: 'advertisement',
    isActive: false,
    icon: 'fas fa-bullhorn',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.ADVERTISEMENT],
    children: []
  },
  {
    path: '/platform-info',
    routeName: ROUTE_NAME.PLATFORM_INFO,
    name: 'platformInfo',
    isActive: false,
    icon: 'fas fa-info-circle',
    category: ASIDE_MENU_CATEGORY.CONTENT_MANAGEMENT,
    authorizeModules: [MODULE.PLATFORM_INFO],
    children: []
  },
  {
    path: '/flag/member',
    routeName: ROUTE_NAME.FLAG_MEMBER,
    name: 'member',
    isActive: false,
    icon: 'fas fa-flag',
    category: ASIDE_MENU_CATEGORY.FLAG,
    authorizeModules: [MODULE.FLAG],
    children: []
  },
  {
    path: '/flag/casting-post',
    routeName: ROUTE_NAME.FLAG_CASTING_POST,
    name: 'castingPost',
    isActive: false,
    icon: 'fas fa-flag',
    category: ASIDE_MENU_CATEGORY.FLAG,
    authorizeModules: [MODULE.FLAG],
    children: []
  },

  {
    path: '/flag/community-post',
    routeName: ROUTE_NAME.FLAG_COMMUNITY_POST,
    name: 'communityPost',
    isActive: false,
    icon: 'fas fa-flag',
    category: ASIDE_MENU_CATEGORY.FLAG,
    authorizeModules: [MODULE.FLAG],
    children: []
  },
  {
    path: '/flag/review',
    routeName: ROUTE_NAME.FLAG_REVIEW,
    name: 'review',
    isActive: false,
    icon: 'fas fa-flag',
    category: ASIDE_MENU_CATEGORY.FLAG,
    authorizeModules: [MODULE.FLAG],
    children: []
  },
  {
    path: '/post',
    routeName: ROUTE_NAME.POST,
    name: 'post',
    isActive: false,
    icon: 'flaticon-interface-2',
    category: ASIDE_MENU_CATEGORY.CASTING,
    authorizeModules: [MODULE.CASTING_JOB],
    children: []
  },
  {
    path: '/package',
    routeName: ROUTE_NAME.PACKAGE,
    name: 'talent-packages',
    isActive: false,
    icon: 'fas fa-chart-bar',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.PAYMENT],
    children: []
  },
  {
    path: '/app-update',
    routeName: ROUTE_NAME.APP_UPDATE,
    name: 'appUpdate',
    isActive: false,
    icon: 'fas fa-mobile-alt',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.APP_INFO],
    children: []
  },
  {
    path: '/talent-category',
    routeName: ROUTE_NAME.TALENT_CATEGORY,
    name: 'talentCategory',
    isActive: false,
    icon: 'fas fa-cubes',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.PROJECT_ROLE],
    children: [
      {
        path: 'project-role',
        routeName: ROUTE_NAME.TALENT_CATEGORY_PROJECT_ROLE_LIST,
        name: 'projectRole',
        isActive: false,
        authorizeModules: [MODULE.PROJECT_ROLE],
        children: []
      },
      {
        path: 'project-type',
        routeName: ROUTE_NAME.TALENT_CATEGORY_PROJECT_TYPE_LIST,
        name: 'projectType',
        isActive: false,
        authorizeModules: [MODULE.PROJECT_TYPE],
        children: []
      },
      {
        path: 'skill',
        routeName: ROUTE_NAME.TALENT_CATEGORY_SKILL_LIST,
        name: 'skill',
        isActive: false,
        authorizeModules: [MODULE.SKILL],
        children: []
      }
    ]
  },
  {
    path: '/chat-history',
    routeName: ROUTE_NAME.CHAT_HISTORY,
    name: 'chatHistory',
    isActive: false,
    icon: 'flaticon2-sms',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.CHAT],
    children: []
  },
  {
    path: '/maintenance',
    routeName: ROUTE_NAME.MAINTENANCE,
    name: 'maintenance',
    isActive: false,
    icon: 'fas fa-tools',
    category: ASIDE_MENU_CATEGORY.SETTINGS,
    authorizeModules: [MODULE.MAINTENANCE_SCHEDULE],
    children: []
  },
  {
    path: '/community-post',
    routeName: ROUTE_NAME.COMMUNITY_POST,
    name: 'communityPost',
    isActive: false,
    icon: 'far fa-newspaper',
    category: ASIDE_MENU_CATEGORY.COMMUNITY,
    authorizeModules: [MODULE.NEWS_FEED],
    children: []
  },
  {
    path: '/payment',
    routeName: ROUTE_NAME.PAYMENT,
    name: 'payment',
    isActive: false,
    icon: 'fas fa-chart-bar',
    category: ASIDE_MENU_CATEGORY.FINANCE,
    authorizeModules: [MODULE.PAYMENT],
    children: []
  },
  {
    path: '/chat',
    routeName: ROUTE_NAME.CHAT,
    name: 'chat',
    isActive: false,
    notification: 'chat',
    icon: 'flaticon2-sms',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.ADMIN_CHAT],
    children: []
  },
  {
    path: '/review',
    routeName: ROUTE_NAME.REVIEW,
    name: 'review',
    isActive: false,
    notification: 'review',
    icon: 'flaticon-star',
    category: ASIDE_MENU_CATEGORY.MEMBER,
    authorizeModules: [MODULE.REVIEW],
    children: []
  },
];

function checkUserAccessRights(moduleName, action) {
  let showAction = false;
  let permissions = permissionHelper.getUserPermissions();
  if (moduleName != '' && action != null && permissions != null) {
    permissions.forEach((x) => {
      if (x.name == moduleName) {
        action.forEach((y) => {
          if (x.actions.find((z) => z.value == y)) {
            showAction = true;
          }
        });
      }
    });
  }

  return showAction;
}

function authorizedNavigationList() {
  let permissions = permissionHelper.getUserPermissions().map((x) => {
    return x.name;
  });
  let newList = [];

  navigationList.forEach((x) => {
    if (x.name == 'home') newList.push(x);

    x.authorizeModules.forEach((y) => {
      if (permissions.includes(y)) {
        if (newList.findIndex((z) => z.routeName == x.routeName) == -1) {
          newList.push(x);
        }
      }
    });
  });

  newList.forEach((x) => {
    let newChildren = [];
    x.children.forEach((y) => {
      y.authorizeModules.forEach((z) => {
        if (permissions.includes(z)) {
          newChildren.push(y);
        }
      });
    });
    x.children = newChildren;
  });

  return newList;
}
