import { dateTimeFormat, dateFormat } from '@/core/filters/dateTimeFormat.filter';
import { REVIEWS } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'reviews/';

/* Define Action Name */
const A_GET_REVIEWS = 'getReviews';
const A_GET_REVIEW = 'getReview';
const A_UPDATE_REVIEW = 'updateReview';

/* Define Reset State Action Name */
const A_INITIAL_REVIEWS_STATE = 'initialReviewsState';
const A_INITIAL_REVIEW_STATE = 'initialReviewState';
const A_INITIAL_UPDATE_REVIEW_STATE = 'initialUpdateReviewState';

/* Define Getter Name */

/* Define Mutation Name */
const M_REVIEWS = 'setReviews';
const M_REVIEW = 'setReview';
const M_UPDATE_REVIEW = 'setUpdateReview';

/* Define Reset State Mutation Name */
const M_INITIAL_REVIEWS_STATE = 'setInitialReviewsState';
const M_INITIAL_REVIEW_STATE = 'setInitialReviewState';
const M_INITIAL_UPDATE_REVIEW_STATE = 'setInitialUpdateReviewState';

/* Define Export Name */
export const REVIEWS_GET_REVIEWS =
  MODULE_NAME + A_GET_REVIEWS;
export const REVIEWS_GET_REVIEW = MODULE_NAME + A_GET_REVIEW;
export const REVIEWS_UPDATE_REVIEW =
  MODULE_NAME + A_UPDATE_REVIEW;

/* Define Reset State Name */
export const REVIEWS_INITIAL_REVIEWS_STATE =
  MODULE_NAME + A_INITIAL_REVIEWS_STATE;
export const REVIEWS_INITIAL_REVIEW_STATE =
  MODULE_NAME + A_INITIAL_REVIEW_STATE;
export const REVIEWS_INITIAL_UPDATE_REVIEW_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_REVIEW_STATE;

const state = {
  reviews: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  review: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateReview: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_REVIEWS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    REVIEWS.getReviews(data)
      .then((response) => {
        result = response;
        commit(M_REVIEWS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REVIEWS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_REVIEW]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    REVIEWS.getReviewById(id)
      .then((response) => {
        result = response;
        commit(M_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_REVIEW]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    REVIEWS.updateReview(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_REVIEW, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_REVIEWS_STATE]({ commit }) {
    commit(M_INITIAL_REVIEWS_STATE);
  },
  [A_INITIAL_REVIEW_STATE]({ commit }) {
    commit(M_INITIAL_REVIEW_STATE);
  },
  [A_INITIAL_UPDATE_REVIEW_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_REVIEW_STATE);
  },
};

// Mutations
const mutations = {
  [M_REVIEWS](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.reviews = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            comment: x.comment,
            reviewer: x.reviewer_profile?.display_name || x.reviewer_profile?.fname || '',
            rating: x.rating,
            status: x.status,
            title: x.title,
            createdAt: dateTimeFormat(x.created_at),
            createdAtTimeStamp: new Date(x.created_at).getTime(),
          };
        }),
        message: null,
      };
    } else {
      state.reviews = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_REVIEW](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.review = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          comment: d.comment,
          reviewer: {
            id: d.reviewer_profile?.id,
            name: d.reviewer_profile.display_name || d.reviewer_profile.fname,
          },
          reviewee: {
            id: d.reviewee_profile?.id,
            name: d.reviewee_profile.display_name || d.reviewee_profile.fname,
          },
          jobProductionStart: dateFormat(d.job_post_details.production_start),
          jobProductionEnd: dateFormat(d.job_post_details.production_end),
          jobTitle: d.job_post_details.title,
          rating: d.rating,
          status: d.status,
          createdAt: dateTimeFormat(d.created_at)
        },
        message: null
      };
    } else {
      state.review = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_UPDATE_REVIEW](state, result) {
    state.updateReview = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_REVIEWS_STATE](state) {
    state.reviews = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_REVIEW_STATE](state) {
    state.review = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_REVIEW_STATE](state) {
    state.updateReview = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
