<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">{{ $t('label.notification') }}</span>
        <span
          v-if="totalNotification"
          class="btn btn-text btn-danger btn-sm font-weight-bold btn-font-md ml-2 cursor-default"
        >
          {{ $t('label.totalNew', [totalNotification]) }}
        </span>
      </h4>

      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            {{ $t('label.alerts') }}
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active>
        <perfect-scrollbar
          v-if="isAuthorizedAlerts.length >= 1"
          class="scroll position-relative max-h-300px"
        >
          <template v-for="(item, i) in isAuthorizedAlerts">
            <router-link
              v-bind:key="i"
              :to="{ name: item.route, query: item.query }"
            >
              <!--begin::Item-->
              <div
                class="d-flex align-items-center px-8 py-4"
                :class="`bg-hover-light-${item.color}`"
              >
                <!--begin::Symbol-->
                <div
                  class="symbol symbol-40 mr-5"
                  v-bind:class="`symbol-light-${item.color}`"
                >
                  <span class="symbol-label">
                    <span
                      class="svg-icon svg-icon-lg"
                      v-bind:class="`svg-icon-${item.color}`"
                    >
                      {{ notificationData[item.key] }}
                    </span>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Text-->
                <div class="d-flex flex-column font-weight-bold">
                  <span class="text-dark mb-1 font-size-lg">
                    {{ $t(`label.${item.title}`) }}
                  </span>
                  <span class="text-muted">
                    {{ $t(`label.${item.desc}`) }}
                  </span>
                </div>
                <!--end::Text-->
              </div>
              <!--end::Item-->
            </router-link>
          </template>
        </perfect-scrollbar>
        <div
          v-else
          class="d-flex flex-center text-center text-muted min-h-200px"
        >
          {{ $t('label.allCaughtUp') }} <br />
          {{ $t('label.noNewNotifications') }}
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
  import commonMixin from '@/core/mixins/common.mixin';

  export default {
    name: 'KTDropdownNotification',
    mixins: [commonMixin],
    props: {
      isAuthorizedAlerts: {
        type: Array,
        default: () => []
      }
    },
    data: () => ({
      tabIndex: 0
    }),
    computed: {
      backgroundImage() {
        return '/media/misc/bg-1.jpg';
      },
      notificationData() {
        return this.$store.state.lookup.notification.data;
      },
      totalNotification() {
        let count = 0;
        this.isAuthorizedAlerts.forEach((x) => {
          count += this.notificationData[x.key];
        });
        return count;
      }
    },
    methods: {
      setActiveTab(event) {
        const tab = event.target.closest('[role="tablist"]');
        const links = tab.querySelectorAll('.nav-link');
        // remove active tab links
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove('active');
        }

        // set clicked tab index to bootstrap tab
        this.tabIndex = parseInt(event.target.getAttribute('data-tab'));

        // set current active tab
        event.target.classList.add('active');
      }
    }
  };
</script>

<style lang="scss">
  /* hide default vue-bootstrap tab links */
  .hide-tabs > div:not(.tab-content) {
    display: none;
  }
</style>
