import moment from 'moment';

function dateTimeFormat(date) {
  if (!['', null, undefined].includes(date)) {
    return moment(date).format('DD MMM YYYY h:mm:ss A');
  } else {
    return '-';
  }
}

function dateFormat(date) {
  if (!['', null, undefined].includes(date)) {
    return moment(date).format('DD MMM YYYY');
  } else {
    return '-';
  }
}

export {
  dateTimeFormat,
  dateFormat
};