const PERMISSION_CONSTANTS = Object.freeze({
  USER_PERMISSIONS: 'userPermissions'
});

const MODULE = Object.freeze({
  ADMIN_USER: 'admin-user',
  ACCOUNT_VERIFICATION: 'account-verification',
  EXPIRED_USER_PACKAGE: 'expired-user-package',
  ROLE: 'role',
  USER: 'user',
  PLATFORM_INFO: 'platform-info',
  BANNER: 'banner',
  FLAG: 'flag',
  CASTING_JOB: 'casting-job',
  APP_INFO: 'app-info',
  PACKAGE: 'package',
  PROJECT_ROLE: 'project-role',
  PROJECT_TYPE: 'project-type',
  PRODUCTION_PACKAGES: 'production-packages',
  TALENT_PACKAGES: 'talent-packages',
  CHAT: 'chat',
  ADMIN_CHAT: 'admin-chat',
  REVIEW: 'review',
  MAINTENANCE_SCHEDULE: 'maintenance-schedule',
  ADVERTISEMENT: 'advertisement',
  AUDIT_LOG: 'audit-log',
  NEWS_FEED: 'news-feed',
  PAYMENT: 'payment',
  SKILL: 'skill'
});

const ACTION = Object.freeze({
  /* Admin Users */
  ADMIN_USER_CREATE: 'admin-user.create',
  ADMIN_USER_READ: 'admin-user.read',
  ADMIN_USER_UPDATE: 'admin-user.update',
  ADMIN_USER_DELETE: 'admin-user.delete',
  /* Account Verification */
  ACCOUNT_VERIFICATION_READ: 'account-verification.read',
  ACCOUNT_VERIFICATION_UPDATE: 'account-verification.update',
  /* Expired User Package */
  EXPIRED_USER_PACKAGE_READ: 'expired-user-package.read',
  /* Roles */
  ROLE_CREATE: 'role.create',
  ROLE_READ: 'role.read',
  ROLE_UPDATE: 'role.update',
  ROLE_DELETE: 'role.delete',
  /* User */
  USER_CREATE: 'user.create',
  USER_READ: 'user.read',
  USER_UPDATE: 'user.update',
  USER_DELETE: 'user.delete',
  /* Platform Info */
  PLATFORM_INFO_READ: 'platform-info.read',
  PLATFORM_INFO_UPDATE: 'platform-info.update',
  /* Banner */
  BANNER_CREATE: 'banner.create',
  BANNER_READ: 'banner.read',
  BANNER_UPDATE: 'banner.update',
  BANNER_DELETE: 'banner.delete',
  /* Flag */
  FLAG_READ: 'flag.read',
  FLAG_UPDATE: 'flag.update',
  /* Casting Job */
  CASTING_JOB_CREATE: 'casting-job.create',
  CASTING_JOB_READ: 'casting-job.read',
  CASTING_JOB_UPDATE: 'casting-job.update',
  CASTING_JOB_DELETE: 'casting-job.delete',
  /* App Info */
  APP_INFO_CREATE: 'app-info.create',
  APP_INFO_READ: 'app-info.read',
  APP_INFO_UPDATE: 'app-info.update',
  /* Project Role */
  PROJECT_ROLE_CREATE: 'project-role.create',
  PROJECT_ROLE_READ: 'project-role.read',
  PROJECT_ROLE_UPDATE: 'project-role.update',
  PROJECT_ROLE_DELETE: 'project-role.delete',
  /* Project Type */
  PROJECT_TYPE_CREATE: 'project-type.create',
  PROJECT_TYPE_READ: 'project-type.read',
  PROJECT_TYPE_UPDATE: 'project-type.update',
  PROJECT_TYPE_DELETE: 'project-type.delete',
  /* Skill */
  SKILL_CREATE: 'skill.create',
  SKILL_READ: 'skill.read',
  SKILL_UPDATE: 'skill.update',
  SKILL_DELETE: 'skill.delete',
  /* Chat History */
  CHAT_READ: 'chat.read',
  CHAT_CREATE: 'chat.create',
  CHAT_UPDATE: 'chat.update',
  CHAT_DELETE: 'chat.delete',
  /* Admin Chat */
  ADMIN_CHAT_READ: 'admin-chat.read',
  ADMIN_CHAT_UPDATE: 'admin-chat.update',
  /* Review */
  REVIEW_READ: 'review.read',
  REVIEW_UPDATE: 'review.update',
  /* Maintenance Schedule */
  MAINTENANCE_SCHEDULE_CREATE: 'maintenance-schedule.create',
  MAINTENANCE_SCHEDULE_READ: 'maintenance-schedule.read',
  MAINTENANCE_SCHEDULE_UPDATE: 'maintenance-schedule.update',
  MAINTENANCE_SCHEDULE_DELETE: 'maintenance-schedule.delete',
  /* Advertisement */
  ADVERTISEMENT_CREATE: 'advertisement.create',
  ADVERTISEMENT_READ: 'advertisement.read',
  ADVERTISEMENT_UPDATE: 'advertisement.update',
  ADVERTISEMENT_DELETE: 'advertisement.delete',
  /* Production Packages */
  PRODUCTION_PACKAGES_READ: 'production-packages.read',
  PRODUCTION_PACKAGES_UPDATE: 'production-packages.update',
  /* Talent Packages */
  TALENT_PACKAGES_READ: 'talent.read',
  TALENT_PACKAGES_UPDATE: 'talent.update',
  /* Audit Log */
  AUDIT_LOG_READ: 'audit-log.read',
  /* News feed */
  NEWS_FEED_READ: 'news-feed.read',
  /* Payment */
  PAYMENT_READ: 'payment.read'
});

export { PERMISSION_CONSTANTS, MODULE, ACTION };
