import constantsDesc from '@/core/filters/constants-desc.filter';
import { PROJECT_TYPES } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'projectTypes/';

/* Define Action Name */
const A_GET_PROJECT_TYPES = 'getProjectTypes';
const A_GET_PROJECT_TYPE = 'getProjectType';
const A_CREATE_PROJECT_TYPE = 'createProjectType';
const A_UPDATE_PROJECT_TYPE = 'updateProjectType';
const A_DELETE_PROJECT_TYPE = 'deleteProjectType';

/* Define Reset State Action Name */
const A_INITIAL_PROJECT_TYPES_STATE = 'initialProjectTypesState';
const A_INITIAL_PROJECT_TYPE_STATE = 'initialProjectTypeState';
const A_INITIAL_CREATE_PROJECT_TYPE_STATE = 'initialCreateProjectTypeState';
const A_INITIAL_UPDATE_PROJECT_TYPE_STATE = 'initialUpdateProjectTypeState';
const A_INITIAL_DELETE_PROJECT_TYPE_STATE = 'initialDeleteProjectTypeState';

/* Define Getter Name */

/* Define Mutation Name */
const M_PROJECT_TYPES = 'setProjectTypes';
const M_PROJECT_TYPE = 'setProjectType';
const M_CREATE_PROJECT_TYPE = 'setCreateProjectType';
const M_UPDATE_PROJECT_TYPE = 'setUpdateProjectType';
const M_DELETE_PROJECT_TYPE = 'setDeleteProjectType';

/* Define Reset State Mutation Name */
const M_INITIAL_PROJECT_TYPES_STATE = 'setInitialProjectTypesState';
const M_INITIAL_PROJECT_TYPE_STATE = 'setInitialProjectTypeState';
const M_INITIAL_CREATE_PROJECT_TYPE_STATE = 'setInitialCreateProjectTypeState';
const M_INITIAL_UPDATE_PROJECT_TYPE_STATE = 'setInitialUpdateProjectTypeState';
const M_INITIAL_DELETE_PROJECT_TYPE_STATE = 'setInitialDeleteProjectTypeState';

/* Define Export Name */
export const PROJECT_TYPES_GET_PROJECT_TYPES =
  MODULE_NAME + A_GET_PROJECT_TYPES;
export const PROJECT_TYPE_GET_PROJECT_TYPE = MODULE_NAME + A_GET_PROJECT_TYPE;
export const PROJECT_TYPE_CREATE_PROJECT_TYPE =
  MODULE_NAME + A_CREATE_PROJECT_TYPE;
export const PROJECT_TYPE_UPDATE_PROJECT_TYPE =
  MODULE_NAME + A_UPDATE_PROJECT_TYPE;
export const PROJECT_TYPE_DELETE_PROJECT_TYPE =
  MODULE_NAME + A_DELETE_PROJECT_TYPE;

/* Define Reset State Name */
export const PROJECT_TYPES_INITIAL_PROJECT_TYPES_STATE =
  MODULE_NAME + A_INITIAL_PROJECT_TYPES_STATE;
export const PROJECT_TYPE_INITIAL_PROJECT_TYPE_STATE =
  MODULE_NAME + A_INITIAL_PROJECT_TYPE_STATE;
export const PROJECT_TYPE_INITIAL_CREATE_PROJECT_TYPE_STATE =
  MODULE_NAME + A_INITIAL_CREATE_PROJECT_TYPE_STATE;
export const PROJECT_TYPE_INITIAL_UPDATE_PROJECT_TYPE_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_PROJECT_TYPE_STATE;
export const PROJECT_TYPE_INITIAL_DELETE_PROJECT_TYPE_STATE =
  MODULE_NAME + A_INITIAL_DELETE_PROJECT_TYPE_STATE;

const state = {
  projectTypes: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  projectType: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  createProjectType: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateProjectType: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteProjectType: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_PROJECT_TYPES]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_TYPES.getProjectTypes(data)
      .then((response) => {
        result = response;
        commit(M_PROJECT_TYPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PROJECT_TYPES, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_PROJECT_TYPE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_TYPES.getProjectType(id)
      .then((response) => {
        result = response;
        commit(M_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_PROJECT_TYPE]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_TYPES.createProjectType(data)
      .then((response) => {
        result = response;
        commit(M_CREATE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_CREATE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_PROJECT_TYPE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_TYPES.updateProjectType(id, data)
      .then((response) => {
        result = response;
        commit(M_UPDATE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_UPDATE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_PROJECT_TYPE]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    let result = null;
    PROJECT_TYPES.deleteProjectType(id)
      .then((response) => {
        result = response;
        commit(M_DELETE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        result = error;
        commit(M_DELETE_PROJECT_TYPE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_PROJECT_TYPES_STATE]({ commit }) {
    commit(M_INITIAL_PROJECT_TYPES_STATE);
  },
  [A_INITIAL_PROJECT_TYPE_STATE]({ commit }) {
    commit(M_INITIAL_PROJECT_TYPE_STATE);
  },
  [A_INITIAL_CREATE_PROJECT_TYPE_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_PROJECT_TYPE_STATE);
  },
  [A_INITIAL_UPDATE_PROJECT_TYPE_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_PROJECT_TYPE_STATE);
  },
  [A_INITIAL_DELETE_PROJECT_TYPE_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_PROJECT_TYPE_STATE);
  }
};

// Mutations
const mutations = {
  [M_PROJECT_TYPES](state, result) {
    if (result.code === 200) {
      let d = result.data.items;

      state.projectTypes = {
        code: result.code,
        complete: true,
        data: d.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            name: x.name,
            datasetId: constantsDesc(x.dataset_id, 'projectTypeDataSet'),
            isDeleted: x.is_deleted
          };
        }),
        message: null,
      };
    } else {
      state.projectTypes = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result),
      };
    }
  },
  [M_PROJECT_TYPE](state, result) {
    if (result.code === 200) {
      let d = result.data;

      state.projectType = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          datasetId: constantsDesc(d.dataset_id, 'projectTypeDataSet'),
          isDeleted: d.is_deleted
        },
        message: null
      };
    } else {
      state.projectType = {
        code: result.code,
        complete: true,
        data: null,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_PROJECT_TYPE](state, result) {
    state.createProjectType = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_UPDATE_PROJECT_TYPE](state, result) {
    state.updateProjectType = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_PROJECT_TYPE](state, result) {
    state.deleteProjectType = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_PROJECT_TYPES_STATE](state) {
    state.projectTypes = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_PROJECT_TYPE_STATE](state) {
    state.projectType = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_CREATE_PROJECT_TYPE_STATE](state) {
    state.createProjectType = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_UPDATE_PROJECT_TYPE_STATE](state) {
    state.updateProjectType = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_DELETE_PROJECT_TYPE_STATE](state) {
    state.deleteProjectType = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
