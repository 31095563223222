<template>
  <b-modal
    v-model="show"
    :title="title"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    header-class="text-capitalize"
  >
    <template #default>
      <template v-for="(i, index) in messages">
        <p :key="index" class="my-4">{{ i }}</p>
      </template>
    </template>

    <template #modal-footer>
      <template v-for="(i, index) in buttons">
        <b-button :key="index" :variant="i.variant" @click="i.action">
          {{ i.text }}
        </b-button>
      </template>
    </template>
  </b-modal>
</template>

<script>
  import { uiHelper } from '@/core/utils';

  export default {
    name: 'AppModalInfo',
    props: {
      show: {
        type: Boolean,
        required: true
      },
      title: {
        type: String,
        required: false
      },
      messages: {
        type: Array,
        required: false
      },
      buttons: {
        type: Array,
        default: () => {
          return [
            {
              text: 'Ok',
              variant: 'primary',
              action: () => {}
            }
          ];
        },
        required: false
      }
    },
    watch: {
      show() {
        this.show
          ? uiHelper.freezeBodyOverflowY()
          : uiHelper.unFreezeBodyOverflowY();
      }
    }
  };
</script>

<style lang="scss"></style>
