import { apiService } from '@/core/services';

export const FLAG = {
  getFlags,
  getFlag,
  updateFlag,
  exportFlagToExcel
};
// api/admin/flag/export/excel
const URL = {
  ADMIN: 'admin',
  FLAG: 'flag',
  EXPORT: 'export',
  EXCEL: 'excel'
};

function getFlags(data) {
  let requestOptions = {
    params: {
      status: data.status,
      content: data.content,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.FLAG}`, requestOptions);
}

function getFlag(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.FLAG}/${id}`);
}

function updateFlag(data, id) {
  let requestBody = {
    status: data.status
  };

  return apiService().put(`/${URL.ADMIN}/${URL.FLAG}/${id}`, requestBody);
}

function exportFlagToExcel(data) {
  let requestOptions = {
    params: {
      status: data.status,
      content: data.content,
      createdAtFrom: data.createdAtFrom,
      createdAtTo: data.createdAtTo
    }
  };

  return apiService()({
    url: `/${URL.ADMIN}/${URL.FLAG}/${URL.EXPORT}/${URL.EXCEL}`,
    responseType: 'blob'
  }, requestOptions);
}
