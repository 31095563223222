import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth.module';
import htmlClass from './htmlclass.module';
import config from './config.module';
import breadcrumbs from './breadcrumbs.module';
import loading from './loading.module';
import constants from './constants.module';
import adminUser from './admin-user.module';
import role from './role.module';
import lookup from './lookup.module';
import user from './user.module';
import platformInfo from './platform-info.module';
import banner from './banner.module';
import flag from './flag.module';
import castingJobs from './casting-jobs.module';
import appInfo from './app-info.module';
import projectRoles from './project-roles.module';
import projectTypes from './project-types.module';
import skills from './skills.module';
import chat from './chat.module';
import maintenanceSchedule from './maintenance-schedule.module';
import advertisement from './advertisement.module';
import auditLogs from './audit-logs.module';
import newsFeeds from './news-feeds.module';
import payment from './payment.module';
import userPackage from './package.module';
import reviews from './reviews.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    htmlClass,
    config,
    breadcrumbs,
    loading,
    constants,
    adminUser,
    role,
    lookup,
    user,
    platformInfo,
    banner,
    flag,
    castingJobs,
    appInfo,
    projectRoles,
    projectTypes,
    skills,
    chat,
    maintenanceSchedule,
    advertisement,
    auditLogs,
    newsFeeds,
    payment,
    userPackage,
    reviews
  }
});
