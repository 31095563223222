import { apiService } from '@/core/services';
import { apiHelper } from '@/core/utils';

export const PAYMENT = {
  getReport,
  getTransactionHistory,
  exportTransactionHistoryToExcel
};

const URL = {
  ADMIN: 'admin',
  PAYMENT: 'payment',
  REPORT: 'report',
  TRANSACTION: 'transaction',
  EXPORT: 'export',
  EXCEL: 'excel'
};

function getReport(data) {
  var requestOptions = {
    params: {
      from: apiHelper.dateTimeStart(data.monthDateRange, false),
      to: apiHelper.dateTimeEnd(data.monthDateRange, false)
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PAYMENT}/${URL.REPORT}`,
    requestOptions
  );
}

function getTransactionHistory(data) {
  var requestOptions = {
    params: {
      referenceId: data.referenceId,
      userName: data.userName,
      paidAtFrom: apiHelper.dateTimeStart(data.paidDateRange),
      paidAtTo: apiHelper.dateTimeEnd(data.paidDateRange),
      per_page: data.perPage,
      page: data.page,
      type: data.type
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PAYMENT}/${URL.TRANSACTION}`,
    requestOptions
  );
}

function exportTransactionHistoryToExcel(data) {
  var requestOptions = {
    params: {
      paidAtFrom: apiHelper.dateTimeStart(data.paidDateRange),
      paidAtTo: apiHelper.dateTimeEnd(data.paidDateRange)
    }
  };
  return apiService().get(
    `/${URL.ADMIN}/${URL.PAYMENT}/${URL.TRANSACTION}/${URL.EXPORT}/${URL.EXCEL}`,
    requestOptions
  );
}
