import { SET_BREADCRUMB } from '@/core/store/breadcrumbs.module';
import { accessRightsService } from '@/core/services';
import { i18nHelper } from '@/core/utils';

const common = {
  methods: {
    actionUpdateRecordCompleteWithoutSuccessModal(
      response,
      title,
      initialStateAction,
      successAction,
      errorAction
    ) {
      if (response.code == 200) {
        successAction();
      } else {
        this.openErrorLayoutAppModalInfo(title, response, errorAction);
      }

      this.actionInitialState(initialStateAction);
    },
    actionCreateRecordComplete(
      response,
      title,
      initialStateAction,
      successAction,
      errorAction
    ) {
      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, successAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response, errorAction);
      }

      this.actionInitialState(initialStateAction);
    },
    actionReadRecordComplete(
      response,
      title,
      initialStateAction,
      successAction
    ) {
      if (response.code == 200) {
        successAction(response);
      } else {
        if (response.message[0] == 'Network Error') {
          console.log(response.message);
        } else {
          this.openErrorLayoutAppModalInfo(title, response);
        }
      }

      this.actionInitialState(initialStateAction);
    },
    actionUpdateRecordComplete(
      response,
      title,
      initialStateAction,
      successAction,
      errorAction
    ) {
      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, successAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response, errorAction);
      }

      this.actionInitialState(initialStateAction);
    },
    actionDeleteRecordComplete(
      response,
      title,
      initialStateAction,
      successAction,
      errorAction
    ) {
      if (response.code == 200) {
        this.openSuccessLayoutAppModalInfo(title, response, successAction);
      } else {
        this.openErrorLayoutAppModalInfo(title, response, errorAction);
      }

      this.actionInitialState(initialStateAction);
    },
    actionInitialState(initialStateAction) {
      this.$store.dispatch(initialStateAction);
    },
    mapArrayToObjectData(array) {
      let newData = {};

      array.forEach((x) => {
        newData[x.key] = x.value;
      });

      return newData;
    },
    dynamicGetValueByKey(key) {
      return this[key];
    },
    openSuccessLayoutAppModalInfo(title, response, addOnOkAction) {
      let messages = response.message;
      let buttons = [
        {
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            addOnOkAction();
            this.closeLayoutAppModalInfo();
          }
        }
      ];

      this.openLayoutAppModalInfo(title, messages, buttons);
    },
    openErrorLayoutAppModalInfo(title, response, addOnOkAction) {
      let code = response.code;
      let messages = response.message;
      let buttons = [
        {
          text: i18nHelper.getMessage('label.ok'),
          variant: 'primary',
          action: () => {
            addOnOkAction ? addOnOkAction() : null;
            this.closeLayoutAppModalInfo();
          }
        }
      ];

      this.openLayoutAppModalInfo(title, messages, buttons, code);
    },
    validationObjCheck(key, name) {
      this.$v[key][name].$touch();

      if (this.$v[key][name].$anyError) {
        return false;
      }

      return true;
    },
    validationCheck(keyName) {
      this.$v[keyName].$touch();
      if (this.$v[keyName].$anyError) {
        return false;
      }

      return true;
    },
    validateObjState(key, name) {
      const { $dirty, $error } = this.$v[key][name] || {};
      return $dirty ? !$error : null;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name] || {};
      return $dirty ? !$error : null;
    },
    checkAccessRight(moduleName, action) {
      return accessRightsService.checkUserAccessRights(moduleName, action);
    },
    initBreadCrumb(data) {
      this.$store.dispatch(SET_BREADCRUMB, data);
    },
    openLayoutAppModalInfo(title, messages, buttons, code) {
      this.$root.$children[0].$refs.layout.openInfoModal(
        title,
        messages,
        buttons,
        code
      );
    },
    closeLayoutAppModalInfo() {
      this.$root.$children[0].$refs.layout.closeInfoModal();
    }
  }
};

export default common;
