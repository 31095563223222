import constantsDesc from '@/core/filters/constants-desc.filter';
import { PAYMENT } from '@/core/api';
import { apiHelper } from '@/core/utils';
import { PERIOD_ENUM } from '@/core/constants/enums';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';

/* Define Module Name */
const MODULE_NAME = 'payment/';

/* Define Action Name */
const A_GET_REPORT = 'getReport';
const A_GET_TRANSACTION_HISTORY = 'getTransactionHistory';
const A_EXPORT_TRANSACTION_HISTORY_TO_EXCEL = 'exportTransactionHistoryToExcel';

/* Define Reset State Action Name */
const A_INITIAL_GET_REPORT_STATE = 'initialGetReportState';
const A_INITIAL_GET_TRANSACTION_HISTORY_STATE =
  'initialGetTransactionHistoryState';
const A_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE =
  'initialExportTransactionHistoryToExcelState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_REPORT = 'setGetReports';
const M_GET_TRANSACTION_HISTORY = 'setGetTransactionHistory';
const M_EXPORT_TRANSACTION_HISTORY_TO_EXCEL =
  'setExportTransactionHistoryToExcel';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_REPORT_STATE = 'setInitialGetReportState';
const M_INITIAL_GET_TRANSACTION_HISTORY_STATE =
  'setInitialGetTransactionHistoryState';
const M_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE =
  'setInitialExportTransactionHistoryToExcelState';

/* Define Export Name */
export const PAYMENT_GET_REPORT = MODULE_NAME + A_GET_REPORT;
export const PAYMENT_GET_TRANSACTION_HISTORY =
  MODULE_NAME + A_GET_TRANSACTION_HISTORY;
export const PAYMENT_EXPORT_TRANSACTION_HISTORY_TO_EXCEL =
  MODULE_NAME + A_EXPORT_TRANSACTION_HISTORY_TO_EXCEL;

/* Define Export Reset State Name */
export const PAYMENT_INITIAL_GET_REPORT_STATE =
  MODULE_NAME + A_INITIAL_GET_REPORT_STATE;
export const PAYMENT_INITIAL_GET_TRANSACTION_HISTORY_STATE =
  MODULE_NAME + A_INITIAL_GET_TRANSACTION_HISTORY_STATE;
export const PAYMENT_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE =
  MODULE_NAME + A_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE;

/* Define Export Getter Name */

const state = {
  report: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  transactionHistory: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  exportTransactionHistoryToExcel: {
    code: 0,
    complete: false,
    data: null,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  [A_GET_REPORT]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PAYMENT.getReport(data)
      .then((response) => {
        let result = response;
        commit(M_GET_REPORT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_REPORT, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_TRANSACTION_HISTORY]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PAYMENT.getTransactionHistory(data)
      .then((response) => {
        let result = response;
        commit(M_GET_TRANSACTION_HISTORY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_TRANSACTION_HISTORY, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_EXPORT_TRANSACTION_HISTORY_TO_EXCEL]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    PAYMENT.exportTransactionHistoryToExcel(data)
      .then((response) => {
        let result = response;
        commit(M_EXPORT_TRANSACTION_HISTORY_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_EXPORT_TRANSACTION_HISTORY_TO_EXCEL, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_REPORT_STATE]({ commit }) {
    commit(M_INITIAL_GET_REPORT_STATE);
  },
  [A_INITIAL_GET_TRANSACTION_HISTORY_STATE]({ commit }) {
    commit(M_INITIAL_GET_TRANSACTION_HISTORY_STATE);
  },
  [A_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE]({ commit }) {
    commit(M_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_REPORT](state, result) {
    if (result.code == 200) {
      let d = result.data;

      state.report = {
        code: result.code,
        complete: true,
        data: {
          ageRange: d.age_range.map((x) => {
            return {
              ageRange: x.age_range,
              count: x.count
            };
          }),
          monthSales: d.month_sales.map((x) => {
            return {
              month: x.month,
              sales: x.sales
            };
          }),
          subscription_month_sales: d.subscription_month_sales
        }
      };
    } else {
      state.report = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.error_message)
      };
    }
  },
  [M_GET_TRANSACTION_HISTORY](state, result) {
    if (result.code == 200) {
      let i = result.data.items;

      state.transactionHistory = {
        code: result.code,
        complete: true,
        data: i.map((x, index) => {
          return {
            no: index + 1,
            id: x.id,
            referenceId: x.reference_id,
            paidAt: x.paid_at,
            type: constantsDesc(x.type, 'paymentType'),
            paymentMethod: x.payment_method || '-',
            amount: x.amount,
            subscriptionPeriod: PERIOD_ENUM[x.subscription_period],
            user: {
              id: x.user?.id,
              name: x.user?.name
            },
            userName: x.user?.name
          };
        }),
      };
    } else {
      state.transactionHistory = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result.error_message),
      };
    }
  },
  [M_EXPORT_TRANSACTION_HISTORY_TO_EXCEL](state, result) {
    state.exportTransactionHistoryToExcel = {
      code: result.code,
      complete: true,
      data: null,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_REPORT_STATE](state) {
    state.report = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  },
  [M_INITIAL_GET_TRANSACTION_HISTORY_STATE](state) {
    state.transactionHistory = {
      code: 0,
      complete: false,
      data: [],
      message: null,
    };
  },
  [M_INITIAL_EXPORT_TRANSACTION_HISTORY_TO_EXCEL_STATE](state) {
    state.exportTransactionHistoryToExcel = {
      code: 0,
      complete: false,
      data: null,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
