import { ADMIN_USER } from '@/core/api';
import { apiHelper } from '@/core/utils';
import {
  LOADING_OPEN_LOADING,
  LOADING_CLOSE_LOADING
} from '@/core/store/loading.module';
import { dateTimeFormat } from '@/core/filters/dateTimeFormat.filter';

/* Define Module Name */
const MODULE_NAME = 'adminUser/';

/* Define Action Name */
const A_GET_ADMIN_USERS = 'getAdminUsers';
const A_GET_ADMIN_USER = 'getAdminUser';
const A_CREATE_ADMIN_USER = 'createAdminUser';
const A_UPDATE_ADMIN_USER = 'updateAdminUser';
const A_DELETE_ADMIN_USER = 'deleteAdminUser';
const A_SET_ACTIVE_INACTIVE = 'setActiveInactive';

/* Define Reset State Action Name */
const A_INITIAL_GET_ADMIN_USERS_STATE = 'initialGetAdminUsersState';
const A_INITIAL_GET_ADMIN_USER_STATE = 'initialGetAdminUserState';
const A_INITIAL_CREATE_ADMIN_USER_STATE = 'initialCreateAdminUserState';
const A_INITIAL_UPDATE_ADMIN_USER_STATE = 'initialUpdateAdminUserState';
const A_INITIAL_DELETE_ADMIN_USER_STATE = 'initialDeleteAdminUserState';
const A_INITIAL_SET_ACTIVE_INACTIVE_STATE = 'initialSetActiveInactiveState';

/* Define Getter Name */

/* Define Mutation Name */
const M_GET_ADMIN_USERS = 'setGetAdminUsers';
const M_GET_ADMIN_USER = 'setGetAdminUser';
const M_CREATE_ADMIN_USER = 'setCreateAdminUser';
const M_UPDATE_ADMIN_USER = 'setUpdateAdminUser';
const M_DELETE_ADMIN_USER = 'setDeleteAdminUser';
const M_SET_ACTIVE_INACTIVE = 'setSetActiveInactive';

/* Define Reset State Mutation Name */
const M_INITIAL_GET_ADMIN_USERS_STATE = 'setInitialAdminUsersState';
const M_INITIAL_GET_ADMIN_USER_STATE = 'setInitialAdminUserState';
const M_INITIAL_CREATE_ADMIN_USER_STATE = 'setInitialCreateAdminUserState';
const M_INITIAL_UPDATE_ADMIN_USER_STATE = 'setInitialUpdateAdminUserState';
const M_INITIAL_DELETE_ADMIN_USER_STATE = 'setInitialDeleteAdminUserState';
const M_INITIAL_SET_ACTIVE_INACTIVE_STATE = 'setInitialSetActiveInactiveState';

/* Define Export Name */
export const ADMIN_USER_GET_ADMIN_USERS = MODULE_NAME + A_GET_ADMIN_USERS;
export const ADMIN_USER_GET_ADMIN_USER = MODULE_NAME + A_GET_ADMIN_USER;
export const ADMIN_USER_CREATE_ADMIN_USER = MODULE_NAME + A_CREATE_ADMIN_USER;
export const ADMIN_USER_UPDATE_ADMIN_USER = MODULE_NAME + A_UPDATE_ADMIN_USER;
export const ADMIN_USER_DELETE_ADMIN_USER = MODULE_NAME + A_DELETE_ADMIN_USER;
export const ADMIN_USER_SET_ACTIVE_INACTIVE =
  MODULE_NAME + A_SET_ACTIVE_INACTIVE;

/* Define Reset State Name */
export const ADMIN_USER_INITIAL_GET_ADMIN_USERS_STATE =
  MODULE_NAME + A_INITIAL_GET_ADMIN_USERS_STATE;
export const ADMIN_USER_INITIAL_GET_ADMIN_USER_STATE =
  MODULE_NAME + A_INITIAL_GET_ADMIN_USER_STATE;
export const ADMIN_USER_INITIAL_CREATE_ADMIN_USER_STATE =
  MODULE_NAME + A_INITIAL_CREATE_ADMIN_USER_STATE;
export const ADMIN_USER_INITIAL_UPDATE_ADMIN_USER_STATE =
  MODULE_NAME + A_INITIAL_UPDATE_ADMIN_USER_STATE;
export const ADMIN_USER_INITIAL_DELETE_ADMIN_USER_STATE =
  MODULE_NAME + A_INITIAL_DELETE_ADMIN_USER_STATE;
export const ADMIN_USER_INITIAL_SET_ACTIVE_INACTIVE_STATE =
  MODULE_NAME + A_INITIAL_SET_ACTIVE_INACTIVE_STATE;

const state = {
  adminUsers: {
    code: 0,
    complete: false,
    data: null,
    message: null,
  },
  adminUser: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  newAdminUser: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  updateAdminUser: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  deleteAdminUser: {
    code: 0,
    complete: false,
    data: null,
    message: null
  },
  setActiveInactive: {
    code: 0,
    complete: false,
    message: null
  }
};

//Getters
const getters = {};

// Actions
const actions = {
  async [A_GET_ADMIN_USERS]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    await ADMIN_USER.getAdminUsers(data)
      .then((response) => {
        let result = response;
        commit(M_GET_ADMIN_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ADMIN_USERS, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_GET_ADMIN_USER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADMIN_USER.getAdminUser(id)
      .then((response) => {
        let result = response;
        commit(M_GET_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_GET_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_CREATE_ADMIN_USER]({ dispatch, commit }, { data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADMIN_USER.createAdminUser(data)
      .then((response) => {
        let result = response;
        commit(M_CREATE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_CREATE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_UPDATE_ADMIN_USER]({ dispatch, commit }, { data, isUpdatePassword }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADMIN_USER.updateAdminUser(data, isUpdatePassword)
      .then((response) => {
        let result = response;
        commit(M_UPDATE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_UPDATE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_DELETE_ADMIN_USER]({ dispatch, commit }, { id }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADMIN_USER.deleteAdminUser(id)
      .then((response) => {
        let result = response;
        commit(M_DELETE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_DELETE_ADMIN_USER, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_SET_ACTIVE_INACTIVE]({ dispatch, commit }, { id, data }) {
    dispatch(`${LOADING_OPEN_LOADING}`, null, { root: true });
    ADMIN_USER.setActiveInactive(id, data)
      .then((response) => {
        let result = response;
        commit(M_SET_ACTIVE_INACTIVE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      })
      .catch((error) => {
        let result = error;
        commit(M_SET_ACTIVE_INACTIVE, result);
        dispatch(`${LOADING_CLOSE_LOADING}`, null, { root: true });
      });
  },
  [A_INITIAL_GET_ADMIN_USERS_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADMIN_USERS_STATE);
  },
  [A_INITIAL_GET_ADMIN_USER_STATE]({ commit }) {
    commit(M_INITIAL_GET_ADMIN_USER_STATE);
  },
  [A_INITIAL_CREATE_ADMIN_USER_STATE]({ commit }) {
    commit(M_INITIAL_CREATE_ADMIN_USER_STATE);
  },
  [A_INITIAL_UPDATE_ADMIN_USER_STATE]({ commit }) {
    commit(M_INITIAL_UPDATE_ADMIN_USER_STATE);
  },
  [A_INITIAL_DELETE_ADMIN_USER_STATE]({ commit }) {
    commit(M_INITIAL_DELETE_ADMIN_USER_STATE);
  },
  [A_INITIAL_SET_ACTIVE_INACTIVE_STATE]({ commit }) {
    commit(M_INITIAL_SET_ACTIVE_INACTIVE_STATE);
  }
};

// Mutations
const mutations = {
  [M_GET_ADMIN_USERS](state, result) {
    if (result.code === 200) {
      let i = result.data.items;
      let adminUsers = [];

      //Hide account superadmin
      i = i.filter((x) => x.username != 'superadmin');

      i.forEach((x, index) => {
        adminUsers.push({
          no: index + 1,
          id: x.id,
          username: x.username,
          name: x.name,
          role: x.roles[0].name,
          roles: x.roles[0],
          isActive: x.is_active,
          email: x.email,
          createdAt: dateTimeFormat(x.created_at),
          createdAtTimeStamp: new Date(x.created_at).getTime(),
          updatedAt: dateTimeFormat(x.updated_at),
          updatedAtTimeStamp: new Date(x.updated_at).getTime()
        });
      });

      state.adminUsers = {
        code: result.code,
        complete: true,
        data: adminUsers,
      };
    } else {
      state.adminUsers = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_GET_ADMIN_USER](state, result) {
    if (result.code == 200) {
      let d = result.data;
      let roles = [];

      d.roles.forEach((x) => {
        roles.push({
          id: x.id,
          name: x.name,
          createdAt: x.created_at,
          updatedAt: x.updated_at
        });
      });

      state.adminUser = {
        code: result.code,
        complete: true,
        data: {
          id: d.id,
          name: d.name,
          username: d.username,
          email: d.email,
          createdAt: d.created_at,
          updatedAt: d.updated_at,
          roles: roles,
          role: roles[0].name,
          roleId: roles[0].id,
          isActive: d.is_active
        }
      };
    } else {
      state.adminUser = {
        code: result.code,
        complete: true,
        message: apiHelper.sortOutMessage(result)
      };
    }
  },
  [M_CREATE_ADMIN_USER](state, result) {
    state.newAdminUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_UPDATE_ADMIN_USER](state, result) {
    state.updateAdminUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_DELETE_ADMIN_USER](state, result) {
    state.deleteAdminUser = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_SET_ACTIVE_INACTIVE](state, result) {
    state.setActiveInactive = {
      code: result.code,
      complete: true,
      message: apiHelper.sortOutMessage(result)
    };
  },
  [M_INITIAL_GET_ADMIN_USERS_STATE](state) {
    state.adminUsers = {
      code: 0,
      complete: false,
      data: null,
      message: null,
    };
  },
  [M_INITIAL_GET_ADMIN_USER_STATE](state) {
    state.adminUser = {
      code: 0,
      complete: false,
      data: null
    };
  },
  [M_INITIAL_CREATE_ADMIN_USER_STATE](state) {
    state.newAdminUser = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_UPDATE_ADMIN_USER_STATE](state) {
    state.updateAdminUser = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_DELETE_ADMIN_USER_STATE](state) {
    state.deleteAdminUser = {
      code: 0,
      complete: false,
      message: null
    };
  },
  [M_INITIAL_SET_ACTIVE_INACTIVE_STATE](state) {
    state.setActiveInactive = {
      code: 0,
      complete: false,
      message: null
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
