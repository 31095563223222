import { apiService } from '@/core/services';

export const PROJECT_ROLES = {
  getProjectRoles,
  getProjectRole,
  createProjectRole,
  updateProjectRole,
  deleteProjectRole
};

const URL = {
  ADMIN: 'admin',
  PROJECT_ROLES: 'project-roles',
  DELETE: 'delete'
};

function getProjectRoles(data) {
  let requestOptions = {
    params: {
      name: data.name,
      per_page: data.perPage,
      page: data.page
    }
  };
  return apiService().get(`/${URL.ADMIN}/${URL.PROJECT_ROLES}`, requestOptions);
}

function getProjectRole(id) {
  return apiService().get(`/${URL.ADMIN}/${URL.PROJECT_ROLES}/${id}`);
}

function createProjectRole(data) {
  let requestBody = {
    name: data.name,
    project_type_ids: data.projectTypeIds
  };

  return apiService().post(`/${URL.ADMIN}/${URL.PROJECT_ROLES}`, requestBody);
}

function updateProjectRole(id, data) {
  let requestBody = {
    name: data.name,
    project_type_ids: data.projectTypeIds
  };
  return apiService().put(
    `/${URL.ADMIN}/${URL.PROJECT_ROLES}/${id}`,
    requestBody
  );
}

function deleteProjectRole(id) {
  return apiService().put(
    `/${URL.ADMIN}/${URL.PROJECT_ROLES}/${id}/${URL.DELETE}`
  );
}
